import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'newsletter',
  initialState: { loading: false },
  reducers: {
    
    // VISITOR REDUCER FUNCTION

    // VISITOR FETCH LIST
    fetchNewsletterVisitorListStart: stateLoadingStart,
    fetchNewsletterVisitorListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `visitor_list`),
    fetchNewsletterVisitorListFailure: stateError,

    // VISITOR FETCH ITEM
    fetchNewsletterVisitorItemStart: stateLoadingStart,
    fetchNewsletterVisitorItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `visitor_item`),
    fetchNewsletterVisitorItemFailure: stateError,

    // VISITOR CREATE ITEM
    createNewsletterVisitorItemStart: stateLoadingStart,
    createNewsletterVisitorItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `visitor_item`),
    createNewsletterVisitorItemFailure: stateError,

    // VISITOR UPDATE ITEM
    updateNewsletterVisitorItemStart: stateLoadingStart,
    updateNewsletterVisitorItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `visitor_item`),
    updateNewsletterVisitorItemFailure: stateError,

    // VISITOR DELETE ITEM
    deleteNewsletterVisitorItemStart: stateLoadingStart,
    deleteNewsletterVisitorItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_visitor`),
    deleteNewsletterVisitorItemFailure: stateError,


    // MAIL REDUCER FUNCTION

    // MAIL FETCH LIST
    fetchNewsletterMailListStart: stateLoadingStart,
    fetchNewsletterMailListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `mail_list`),
    fetchNewsletterMailListFailure: stateError,

    // MAIL FETCH ITEM
    fetchNewsletterMailItemStart: stateLoadingStart,
    fetchNewsletterMailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `mail_item`),
    fetchNewsletterMailItemFailure: stateError,

    // MAIL CREATE ITEM
    createNewsletterMailItemStart: stateLoadingStart,
    createNewsletterMailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `mail_item`),
    createNewsletterMailItemFailure: stateError,

    // MAIL UPDATE ITEM
    updateNewsletterMailItemStart: stateLoadingStart,
    updateNewsletterMailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `mail_item`),
    updateNewsletterMailItemFailure: stateError,

    // MAIL DELETE ITEM
    deleteNewsletterMailItemStart: stateLoadingStart,
    deleteNewsletterMailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_mail`),
    deleteNewsletterMailItemFailure: stateError,


  },
});

export const {
  fetchNewsletterVisitorListStart, fetchNewsletterMailListStart,
  fetchNewsletterVisitorListSuccess, fetchNewsletterMailListSuccess,
  fetchNewsletterVisitorListFailure, fetchNewsletterMailListFailure,
  fetchNewsletterVisitorItemStart, fetchNewsletterMailItemStart,
  fetchNewsletterVisitorItemSuccess, fetchNewsletterMailItemSuccess,
  fetchNewsletterVisitorItemFailure, fetchNewsletterMailItemFailure,
  createNewsletterVisitorItemStart, createNewsletterMailItemStart,
  createNewsletterVisitorItemSuccess, createNewsletterMailItemSuccess,
  createNewsletterVisitorItemFailure, createNewsletterMailItemFailure,
  updateNewsletterVisitorItemStart, updateNewsletterMailItemStart,
  updateNewsletterVisitorItemSuccess, updateNewsletterMailItemSuccess,
  updateNewsletterVisitorItemFailure, updateNewsletterMailItemFailure,
  deleteNewsletterVisitorItemStart, deleteNewsletterMailItemStart,
  deleteNewsletterVisitorItemSuccess, deleteNewsletterMailItemSuccess,
  deleteNewsletterVisitorItemFailure, deleteNewsletterMailItemFailure
} = slice.actions;

export default slice.reducer;
