import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'game',
  initialState: { loading: false },
  reducers: {
    
    // RESOURCE REDUCER FUNCTION

    // RESOURCE FETCH LIST
    fetchGameResourceListStart: stateLoadingStart,
    fetchGameResourceListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_list`),
    fetchGameResourceListFailure: stateError,

    // RESOURCE FETCH ITEM
    fetchGameResourceItemStart: stateLoadingStart,
    fetchGameResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    fetchGameResourceItemFailure: stateError,

    // RESOURCE CREATE ITEM
    createGameResourceItemStart: stateLoadingStart,
    createGameResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    createGameResourceItemFailure: stateError,

    // RESOURCE UPDATE ITEM
    updateGameResourceItemStart: stateLoadingStart,
    updateGameResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    updateGameResourceItemFailure: stateError,

    // RESOURCE DELETE ITEM
    deleteGameResourceItemStart: stateLoadingStart,
    deleteGameResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_resource`),
    deleteGameResourceItemFailure: stateError,


  },
});

export const {
  fetchGameResourceListStart,
  fetchGameResourceListSuccess,
  fetchGameResourceListFailure,
  fetchGameResourceItemStart,
  fetchGameResourceItemSuccess,
  fetchGameResourceItemFailure,
  createGameResourceItemStart,
  createGameResourceItemSuccess,
  createGameResourceItemFailure,
  updateGameResourceItemStart,
  updateGameResourceItemSuccess,
  updateGameResourceItemFailure,
  deleteGameResourceItemStart,
  deleteGameResourceItemSuccess,
  deleteGameResourceItemFailure
} = slice.actions;

export default slice.reducer;
