import {useEffect, Suspense, lazy} from 'react'
import { Loader } from 'semantic-ui-react'
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import './App.css';

const About = lazy(() => import('./pages/mainsite/about'))
const Contact = lazy(() => import('./pages/mainsite/contact'))
const LandingPage = lazy(() => import('./pages/mainsite/landingpage'))
const ForEducators = lazy(() => import('./pages/mainsite/for-educators'))
const ForCompanies = lazy(() => import('./pages/mainsite/for-companies'))
const ForInstitutions = lazy(() => import('./pages/mainsite/for-institutions'))

const Notification = lazy(() => import('./pages/common/notification'))
const Profile = lazy(() => import('./pages/common/profile'))
const PageNotFound = lazy(() => import('./pages/common/notfound'))
const Support = lazy(() => import('./pages/common/support'))

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  return (
      <Router>
        <Suspense fallback={<Loader active inline style={{margin: 10}}/>}>
          <ScrollToTop />
          <Routes>
             <Route exact path='/' element={<LandingPage/>}></Route>
             <Route exact path='/contact' element={<Contact/>}></Route>
             <Route exact path='/about' element={<About/>}></Route>
             <Route exact path='/notification' element={<Notification/>}></Route>
             <Route exact path='/profile' element={<Profile switch_profile_allowed={true} show_external_link={true}/>}></Route>
             <Route exact path='/for-educators' element={<ForEducators/>}></Route>
             <Route exact path='/for-companies' element={<ForCompanies/>}></Route>
             <Route exact path='/for-institutions' element={<ForInstitutions/>}></Route>
             <Route exact path='/support' element={<Support/>}></Route>
             <Route exact path='*' element={<LandingPage/>}></Route>
             {/*<Route exact path='*' element={<PageNotFound/>}></Route>*/}
          </Routes>
        </Suspense>
      </Router>
  );
}

export default App;
