import axios from '../utils/axios';

import {fetchNoteNoteListStart, fetchNoteNoteListSuccess, fetchNoteNoteListFailure} from '../features/note'
import {fetchNoteNoteItemStart, fetchNoteNoteItemSuccess, fetchNoteNoteItemFailure} from '../features/note'
import {createNoteNoteItemStart, createNoteNoteItemSuccess, createNoteNoteItemFailure} from '../features/note'
import {updateNoteNoteItemStart, updateNoteNoteItemSuccess, updateNoteNoteItemFailure} from '../features/note'
import {deleteNoteNoteItemStart, deleteNoteNoteItemSuccess, deleteNoteNoteItemFailure} from '../features/note'


// NOTE ACTIONS
export const fetchNoteNoteList = async (dispatch, info, key) => {
    try{
        dispatch(fetchNoteNoteListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/note/note/`, { params: info.params, headers: info.headers });
        dispatch(fetchNoteNoteListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNoteNoteListFailure({key, error: error.message}));
    }
}
export const fetchNoteNoteItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchNoteNoteItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/note/note/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchNoteNoteItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNoteNoteItemFailure({key, error: error.message}));
    }
}
export const createNoteNoteItem = async (dispatch, info, key) => {
    try{
        dispatch(createNoteNoteItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/note/note/`, info.payload, { headers: info.headers });
        dispatch(createNoteNoteItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createNoteNoteItemFailure({key, error: error.message}));
    }
}
export const updateNoteNoteItem = async (dispatch, info, key) => {
    try{
        dispatch(updateNoteNoteItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/note/note/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateNoteNoteItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateNoteNoteItemFailure({key, error: error.message}));
    }
}
export const deleteNoteNoteItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteNoteNoteItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/note/note/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteNoteNoteItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteNoteNoteItemFailure({key, error: error.message}));
    }
}




