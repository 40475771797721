import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'organization',
  initialState: { loading: false },
  reducers: {
    
    // ORGANIZATION REDUCER FUNCTION

    // ORGANIZATION FETCH LIST
    fetchOrganizationOrganizationListStart: stateLoadingStart,
    fetchOrganizationOrganizationListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `organization_list`),
    fetchOrganizationOrganizationListFailure: stateError,

    // ORGANIZATION FETCH ITEM
    fetchOrganizationOrganizationItemStart: stateLoadingStart,
    fetchOrganizationOrganizationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `organization_item`),
    fetchOrganizationOrganizationItemFailure: stateError,

    // ORGANIZATION CREATE ITEM
    createOrganizationOrganizationItemStart: stateLoadingStart,
    createOrganizationOrganizationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `organization_item`),
    createOrganizationOrganizationItemFailure: stateError,

    // ORGANIZATION UPDATE ITEM
    updateOrganizationOrganizationItemStart: stateLoadingStart,
    updateOrganizationOrganizationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `organization_item`),
    updateOrganizationOrganizationItemFailure: stateError,

    // ORGANIZATION DELETE ITEM
    deleteOrganizationOrganizationItemStart: stateLoadingStart,
    deleteOrganizationOrganizationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_organization`),
    deleteOrganizationOrganizationItemFailure: stateError,


  },
});

export const {
  fetchOrganizationOrganizationListStart,
  fetchOrganizationOrganizationListSuccess,
  fetchOrganizationOrganizationListFailure,
  fetchOrganizationOrganizationItemStart,
  fetchOrganizationOrganizationItemSuccess,
  fetchOrganizationOrganizationItemFailure,
  createOrganizationOrganizationItemStart,
  createOrganizationOrganizationItemSuccess,
  createOrganizationOrganizationItemFailure,
  updateOrganizationOrganizationItemStart,
  updateOrganizationOrganizationItemSuccess,
  updateOrganizationOrganizationItemFailure,
  deleteOrganizationOrganizationItemStart,
  deleteOrganizationOrganizationItemSuccess,
  deleteOrganizationOrganizationItemFailure
} = slice.actions;

export default slice.reducer;
