import axios from '../utils/axios';

import {fetchCommunityFollowListStart, fetchCommunityFollowListSuccess, fetchCommunityFollowListFailure} from '../features/community'
import {fetchCommunityFollowItemStart, fetchCommunityFollowItemSuccess, fetchCommunityFollowItemFailure} from '../features/community'
import {createCommunityFollowItemStart, createCommunityFollowItemSuccess, createCommunityFollowItemFailure} from '../features/community'
import {updateCommunityFollowItemStart, updateCommunityFollowItemSuccess, updateCommunityFollowItemFailure} from '../features/community'
import {deleteCommunityFollowItemStart, deleteCommunityFollowItemSuccess, deleteCommunityFollowItemFailure} from '../features/community'

import {fetchCommunityGeneralpostListStart, fetchCommunityGeneralpostListSuccess, fetchCommunityGeneralpostListFailure} from '../features/community'
import {fetchCommunityGeneralpostItemStart, fetchCommunityGeneralpostItemSuccess, fetchCommunityGeneralpostItemFailure} from '../features/community'
import {createCommunityGeneralpostItemStart, createCommunityGeneralpostItemSuccess, createCommunityGeneralpostItemFailure} from '../features/community'
import {updateCommunityGeneralpostItemStart, updateCommunityGeneralpostItemSuccess, updateCommunityGeneralpostItemFailure} from '../features/community'
import {deleteCommunityGeneralpostItemStart, deleteCommunityGeneralpostItemSuccess, deleteCommunityGeneralpostItemFailure} from '../features/community'

import {fetchCommunityPostListStart, fetchCommunityPostListSuccess, fetchCommunityPostListFailure} from '../features/community'
import {fetchCommunityPostItemStart, fetchCommunityPostItemSuccess, fetchCommunityPostItemFailure} from '../features/community'
import {createCommunityPostItemStart, createCommunityPostItemSuccess, createCommunityPostItemFailure} from '../features/community'
import {updateCommunityPostItemStart, updateCommunityPostItemSuccess, updateCommunityPostItemFailure} from '../features/community'
import {deleteCommunityPostItemStart, deleteCommunityPostItemSuccess, deleteCommunityPostItemFailure} from '../features/community'

import {fetchCommunityCommentListStart, fetchCommunityCommentListSuccess, fetchCommunityCommentListFailure} from '../features/community'
import {fetchCommunityCommentItemStart, fetchCommunityCommentItemSuccess, fetchCommunityCommentItemFailure} from '../features/community'
import {createCommunityCommentItemStart, createCommunityCommentItemSuccess, createCommunityCommentItemFailure} from '../features/community'
import {updateCommunityCommentItemStart, updateCommunityCommentItemSuccess, updateCommunityCommentItemFailure} from '../features/community'
import {deleteCommunityCommentItemStart, deleteCommunityCommentItemSuccess, deleteCommunityCommentItemFailure} from '../features/community'

import {fetchCommunityThreadListStart, fetchCommunityThreadListSuccess, fetchCommunityThreadListFailure} from '../features/community'
import {fetchCommunityThreadItemStart, fetchCommunityThreadItemSuccess, fetchCommunityThreadItemFailure} from '../features/community'
import {createCommunityThreadItemStart, createCommunityThreadItemSuccess, createCommunityThreadItemFailure} from '../features/community'
import {updateCommunityThreadItemStart, updateCommunityThreadItemSuccess, updateCommunityThreadItemFailure} from '../features/community'
import {deleteCommunityThreadItemStart, deleteCommunityThreadItemSuccess, deleteCommunityThreadItemFailure} from '../features/community'

import {fetchCommunityLikeListStart, fetchCommunityLikeListSuccess, fetchCommunityLikeListFailure} from '../features/community'
import {fetchCommunityLikeItemStart, fetchCommunityLikeItemSuccess, fetchCommunityLikeItemFailure} from '../features/community'
import {createCommunityLikeItemStart, createCommunityLikeItemSuccess, createCommunityLikeItemFailure} from '../features/community'
import {updateCommunityLikeItemStart, updateCommunityLikeItemSuccess, updateCommunityLikeItemFailure} from '../features/community'
import {deleteCommunityLikeItemStart, deleteCommunityLikeItemSuccess, deleteCommunityLikeItemFailure} from '../features/community'


// FOLLOW ACTIONS
export const fetchCommunityFollowList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityFollowListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/follow/`, { params: info.params, headers: info.headers });
        dispatch(fetchCommunityFollowListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityFollowListFailure({key, error: error.message}));
    }
}
export const fetchCommunityFollowItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityFollowItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/follow/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCommunityFollowItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityFollowItemFailure({key, error: error.message}));
    }
}
export const createCommunityFollowItem = async (dispatch, info, key) => {
    try{
        dispatch(createCommunityFollowItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/community/follow/`, info.payload, { headers: info.headers });
        dispatch(createCommunityFollowItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCommunityFollowItemFailure({key, error: error.message}));
    }
}
export const updateCommunityFollowItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCommunityFollowItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/community/follow/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCommunityFollowItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCommunityFollowItemFailure({key, error: error.message}));
    }
}
export const deleteCommunityFollowItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCommunityFollowItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/community/follow/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCommunityFollowItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCommunityFollowItemFailure({key, error: error.message}));
    }
}


// GENERALPOST ACTIONS
export const fetchCommunityGeneralpostList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityGeneralpostListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/generalpost/`, { params: info.params, headers: info.headers });
        dispatch(fetchCommunityGeneralpostListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityGeneralpostListFailure({key, error: error.message}));
    }
}
export const fetchCommunityGeneralpostItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityGeneralpostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/generalpost/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCommunityGeneralpostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityGeneralpostItemFailure({key, error: error.message}));
    }
}
export const createCommunityGeneralpostItem = async (dispatch, info, key) => {
    try{
        dispatch(createCommunityGeneralpostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/community/generalpost/`, info.payload, { headers: info.headers });
        dispatch(createCommunityGeneralpostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCommunityGeneralpostItemFailure({key, error: error.message}));
    }
}
export const updateCommunityGeneralpostItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCommunityGeneralpostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/community/generalpost/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCommunityGeneralpostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCommunityGeneralpostItemFailure({key, error: error.message}));
    }
}
export const deleteCommunityGeneralpostItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCommunityGeneralpostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/community/generalpost/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCommunityGeneralpostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCommunityGeneralpostItemFailure({key, error: error.message}));
    }
}


// POST ACTIONS
export const fetchCommunityPostList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityPostListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/post/`, { params: info.params, headers: info.headers });
        dispatch(fetchCommunityPostListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityPostListFailure({key, error: error.message}));
    }
}
export const fetchCommunityPostItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/post/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCommunityPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityPostItemFailure({key, error: error.message}));
    }
}
export const createCommunityPostItem = async (dispatch, info, key) => {
    try{
        dispatch(createCommunityPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/community/post/`, info.payload, { headers: info.headers });
        dispatch(createCommunityPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCommunityPostItemFailure({key, error: error.message}));
    }
}
export const updateCommunityPostItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCommunityPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/community/post/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCommunityPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCommunityPostItemFailure({key, error: error.message}));
    }
}
export const deleteCommunityPostItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCommunityPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/community/post/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCommunityPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCommunityPostItemFailure({key, error: error.message}));
    }
}


// COMMENT ACTIONS
export const fetchCommunityCommentList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityCommentListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/comment/`, { params: info.params, headers: info.headers });
        dispatch(fetchCommunityCommentListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityCommentListFailure({key, error: error.message}));
    }
}
export const fetchCommunityCommentItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityCommentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/comment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCommunityCommentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityCommentItemFailure({key, error: error.message}));
    }
}
export const createCommunityCommentItem = async (dispatch, info, key) => {
    try{
        dispatch(createCommunityCommentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/community/comment/`, info.payload, { headers: info.headers });
        dispatch(createCommunityCommentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCommunityCommentItemFailure({key, error: error.message}));
    }
}
export const updateCommunityCommentItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCommunityCommentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/community/comment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCommunityCommentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCommunityCommentItemFailure({key, error: error.message}));
    }
}
export const deleteCommunityCommentItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCommunityCommentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/community/comment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCommunityCommentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCommunityCommentItemFailure({key, error: error.message}));
    }
}


// THREAD ACTIONS
export const fetchCommunityThreadList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityThreadListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/thread/`, { params: info.params, headers: info.headers });
        dispatch(fetchCommunityThreadListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityThreadListFailure({key, error: error.message}));
    }
}
export const fetchCommunityThreadItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityThreadItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/thread/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCommunityThreadItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityThreadItemFailure({key, error: error.message}));
    }
}
export const createCommunityThreadItem = async (dispatch, info, key) => {
    try{
        dispatch(createCommunityThreadItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/community/thread/`, info.payload, { headers: info.headers });
        dispatch(createCommunityThreadItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCommunityThreadItemFailure({key, error: error.message}));
    }
}
export const updateCommunityThreadItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCommunityThreadItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/community/thread/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCommunityThreadItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCommunityThreadItemFailure({key, error: error.message}));
    }
}
export const deleteCommunityThreadItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCommunityThreadItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/community/thread/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCommunityThreadItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCommunityThreadItemFailure({key, error: error.message}));
    }
}


// LIKE ACTIONS
export const fetchCommunityLikeList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityLikeListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/like/`, { params: info.params, headers: info.headers });
        dispatch(fetchCommunityLikeListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityLikeListFailure({key, error: error.message}));
    }
}
export const fetchCommunityLikeItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCommunityLikeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/community/like/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCommunityLikeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCommunityLikeItemFailure({key, error: error.message}));
    }
}
export const createCommunityLikeItem = async (dispatch, info, key) => {
    try{
        dispatch(createCommunityLikeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/community/like/`, info.payload, { headers: info.headers });
        dispatch(createCommunityLikeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCommunityLikeItemFailure({key, error: error.message}));
    }
}
export const updateCommunityLikeItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCommunityLikeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/community/like/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCommunityLikeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCommunityLikeItemFailure({key, error: error.message}));
    }
}
export const deleteCommunityLikeItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCommunityLikeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/community/like/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCommunityLikeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCommunityLikeItemFailure({key, error: error.message}));
    }
}




