import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'forum',
  initialState: { loading: false },
  reducers: {
    
    // POST REDUCER FUNCTION

    // POST FETCH LIST
    fetchForumPostListStart: stateLoadingStart,
    fetchForumPostListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_list`),
    fetchForumPostListFailure: stateError,

    // POST FETCH ITEM
    fetchForumPostItemStart: stateLoadingStart,
    fetchForumPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_item`),
    fetchForumPostItemFailure: stateError,

    // POST CREATE ITEM
    createForumPostItemStart: stateLoadingStart,
    createForumPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_item`),
    createForumPostItemFailure: stateError,

    // POST UPDATE ITEM
    updateForumPostItemStart: stateLoadingStart,
    updateForumPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_item`),
    updateForumPostItemFailure: stateError,

    // POST DELETE ITEM
    deleteForumPostItemStart: stateLoadingStart,
    deleteForumPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_post`),
    deleteForumPostItemFailure: stateError,


  },
});

export const {
  fetchForumPostListStart,
  fetchForumPostListSuccess,
  fetchForumPostListFailure,
  fetchForumPostItemStart,
  fetchForumPostItemSuccess,
  fetchForumPostItemFailure,
  createForumPostItemStart,
  createForumPostItemSuccess,
  createForumPostItemFailure,
  updateForumPostItemStart,
  updateForumPostItemSuccess,
  updateForumPostItemFailure,
  deleteForumPostItemStart,
  deleteForumPostItemSuccess,
  deleteForumPostItemFailure
} = slice.actions;

export default slice.reducer;
