import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'coursematerial',
  initialState: { loading: false },
  reducers: {
    
    // TOPIC REDUCER FUNCTION

    // TOPIC FETCH LIST
    fetchCoursematerialTopicListStart: stateLoadingStart,
    fetchCoursematerialTopicListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `topic_list`),
    fetchCoursematerialTopicListFailure: stateError,

    // TOPIC FETCH ITEM
    fetchCoursematerialTopicItemStart: stateLoadingStart,
    fetchCoursematerialTopicItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `topic_item`),
    fetchCoursematerialTopicItemFailure: stateError,

    // TOPIC CREATE ITEM
    createCoursematerialTopicItemStart: stateLoadingStart,
    createCoursematerialTopicItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `topic_item`),
    createCoursematerialTopicItemFailure: stateError,

    // TOPIC UPDATE ITEM
    updateCoursematerialTopicItemStart: stateLoadingStart,
    updateCoursematerialTopicItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `topic_item`),
    updateCoursematerialTopicItemFailure: stateError,

    // TOPIC DELETE ITEM
    deleteCoursematerialTopicItemStart: stateLoadingStart,
    deleteCoursematerialTopicItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_topic`),
    deleteCoursematerialTopicItemFailure: stateError,


    // SUBJECT REDUCER FUNCTION

    // SUBJECT FETCH LIST
    fetchCoursematerialSubjectListStart: stateLoadingStart,
    fetchCoursematerialSubjectListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `subject_list`),
    fetchCoursematerialSubjectListFailure: stateError,

    // SUBJECT FETCH ITEM
    fetchCoursematerialSubjectItemStart: stateLoadingStart,
    fetchCoursematerialSubjectItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `subject_item`),
    fetchCoursematerialSubjectItemFailure: stateError,

    // SUBJECT CREATE ITEM
    createCoursematerialSubjectItemStart: stateLoadingStart,
    createCoursematerialSubjectItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `subject_item`),
    createCoursematerialSubjectItemFailure: stateError,

    // SUBJECT UPDATE ITEM
    updateCoursematerialSubjectItemStart: stateLoadingStart,
    updateCoursematerialSubjectItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `subject_item`),
    updateCoursematerialSubjectItemFailure: stateError,

    // SUBJECT DELETE ITEM
    deleteCoursematerialSubjectItemStart: stateLoadingStart,
    deleteCoursematerialSubjectItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_subject`),
    deleteCoursematerialSubjectItemFailure: stateError,


    // COURSE REDUCER FUNCTION

    // COURSE FETCH LIST
    fetchCoursematerialCourseListStart: stateLoadingStart,
    fetchCoursematerialCourseListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `course_list`),
    fetchCoursematerialCourseListFailure: stateError,

    // COURSE FETCH ITEM
    fetchCoursematerialCourseItemStart: stateLoadingStart,
    fetchCoursematerialCourseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `course_item`),
    fetchCoursematerialCourseItemFailure: stateError,

    // COURSE CREATE ITEM
    createCoursematerialCourseItemStart: stateLoadingStart,
    createCoursematerialCourseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `course_item`),
    createCoursematerialCourseItemFailure: stateError,

    // COURSE UPDATE ITEM
    updateCoursematerialCourseItemStart: stateLoadingStart,
    updateCoursematerialCourseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `course_item`),
    updateCoursematerialCourseItemFailure: stateError,

    // COURSE DELETE ITEM
    deleteCoursematerialCourseItemStart: stateLoadingStart,
    deleteCoursematerialCourseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_course`),
    deleteCoursematerialCourseItemFailure: stateError,


    // COURSEINTRO REDUCER FUNCTION

    // COURSEINTRO FETCH LIST
    fetchCoursematerialCourseintroListStart: stateLoadingStart,
    fetchCoursematerialCourseintroListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courseintro_list`),
    fetchCoursematerialCourseintroListFailure: stateError,

    // COURSEINTRO FETCH ITEM
    fetchCoursematerialCourseintroItemStart: stateLoadingStart,
    fetchCoursematerialCourseintroItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courseintro_item`),
    fetchCoursematerialCourseintroItemFailure: stateError,

    // COURSEINTRO CREATE ITEM
    createCoursematerialCourseintroItemStart: stateLoadingStart,
    createCoursematerialCourseintroItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courseintro_item`),
    createCoursematerialCourseintroItemFailure: stateError,

    // COURSEINTRO UPDATE ITEM
    updateCoursematerialCourseintroItemStart: stateLoadingStart,
    updateCoursematerialCourseintroItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courseintro_item`),
    updateCoursematerialCourseintroItemFailure: stateError,

    // COURSEINTRO DELETE ITEM
    deleteCoursematerialCourseintroItemStart: stateLoadingStart,
    deleteCoursematerialCourseintroItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_courseintro`),
    deleteCoursematerialCourseintroItemFailure: stateError,


    // COURSELABEL REDUCER FUNCTION

    // COURSELABEL FETCH LIST
    fetchCoursematerialCourselabelListStart: stateLoadingStart,
    fetchCoursematerialCourselabelListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courselabel_list`),
    fetchCoursematerialCourselabelListFailure: stateError,

    // COURSELABEL FETCH ITEM
    fetchCoursematerialCourselabelItemStart: stateLoadingStart,
    fetchCoursematerialCourselabelItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courselabel_item`),
    fetchCoursematerialCourselabelItemFailure: stateError,

    // COURSELABEL CREATE ITEM
    createCoursematerialCourselabelItemStart: stateLoadingStart,
    createCoursematerialCourselabelItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courselabel_item`),
    createCoursematerialCourselabelItemFailure: stateError,

    // COURSELABEL UPDATE ITEM
    updateCoursematerialCourselabelItemStart: stateLoadingStart,
    updateCoursematerialCourselabelItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `courselabel_item`),
    updateCoursematerialCourselabelItemFailure: stateError,

    // COURSELABEL DELETE ITEM
    deleteCoursematerialCourselabelItemStart: stateLoadingStart,
    deleteCoursematerialCourselabelItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_courselabel`),
    deleteCoursematerialCourselabelItemFailure: stateError,


    // MODULE REDUCER FUNCTION

    // MODULE FETCH LIST
    fetchCoursematerialModuleListStart: stateLoadingStart,
    fetchCoursematerialModuleListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `module_list`),
    fetchCoursematerialModuleListFailure: stateError,

    // MODULE FETCH ITEM
    fetchCoursematerialModuleItemStart: stateLoadingStart,
    fetchCoursematerialModuleItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `module_item`),
    fetchCoursematerialModuleItemFailure: stateError,

    // MODULE CREATE ITEM
    createCoursematerialModuleItemStart: stateLoadingStart,
    createCoursematerialModuleItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `module_item`),
    createCoursematerialModuleItemFailure: stateError,

    // MODULE UPDATE ITEM
    updateCoursematerialModuleItemStart: stateLoadingStart,
    updateCoursematerialModuleItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `module_item`),
    updateCoursematerialModuleItemFailure: stateError,

    // MODULE DELETE ITEM
    deleteCoursematerialModuleItemStart: stateLoadingStart,
    deleteCoursematerialModuleItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_module`),
    deleteCoursematerialModuleItemFailure: stateError,


    // RESOURCE REDUCER FUNCTION

    // RESOURCE FETCH LIST
    fetchCoursematerialResourceListStart: stateLoadingStart,
    fetchCoursematerialResourceListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_list`),
    fetchCoursematerialResourceListFailure: stateError,

    // RESOURCE FETCH ITEM
    fetchCoursematerialResourceItemStart: stateLoadingStart,
    fetchCoursematerialResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    fetchCoursematerialResourceItemFailure: stateError,

    // RESOURCE CREATE ITEM
    createCoursematerialResourceItemStart: stateLoadingStart,
    createCoursematerialResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    createCoursematerialResourceItemFailure: stateError,

    // RESOURCE UPDATE ITEM
    updateCoursematerialResourceItemStart: stateLoadingStart,
    updateCoursematerialResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    updateCoursematerialResourceItemFailure: stateError,

    // RESOURCE DELETE ITEM
    deleteCoursematerialResourceItemStart: stateLoadingStart,
    deleteCoursematerialResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_resource`),
    deleteCoursematerialResourceItemFailure: stateError,


    // LESSON REDUCER FUNCTION

    // LESSON FETCH LIST
    fetchCoursematerialLessonListStart: stateLoadingStart,
    fetchCoursematerialLessonListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `lesson_list`),
    fetchCoursematerialLessonListFailure: stateError,

    // LESSON FETCH ITEM
    fetchCoursematerialLessonItemStart: stateLoadingStart,
    fetchCoursematerialLessonItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `lesson_item`),
    fetchCoursematerialLessonItemFailure: stateError,

    // LESSON CREATE ITEM
    createCoursematerialLessonItemStart: stateLoadingStart,
    createCoursematerialLessonItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `lesson_item`),
    createCoursematerialLessonItemFailure: stateError,

    // LESSON UPDATE ITEM
    updateCoursematerialLessonItemStart: stateLoadingStart,
    updateCoursematerialLessonItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `lesson_item`),
    updateCoursematerialLessonItemFailure: stateError,

    // LESSON DELETE ITEM
    deleteCoursematerialLessonItemStart: stateLoadingStart,
    deleteCoursematerialLessonItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_lesson`),
    deleteCoursematerialLessonItemFailure: stateError,


    // ASSIGNMENT REDUCER FUNCTION

    // ASSIGNMENT FETCH LIST
    fetchCoursematerialAssignmentListStart: stateLoadingStart,
    fetchCoursematerialAssignmentListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `assignment_list`),
    fetchCoursematerialAssignmentListFailure: stateError,

    // ASSIGNMENT FETCH ITEM
    fetchCoursematerialAssignmentItemStart: stateLoadingStart,
    fetchCoursematerialAssignmentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `assignment_item`),
    fetchCoursematerialAssignmentItemFailure: stateError,

    // ASSIGNMENT CREATE ITEM
    createCoursematerialAssignmentItemStart: stateLoadingStart,
    createCoursematerialAssignmentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `assignment_item`),
    createCoursematerialAssignmentItemFailure: stateError,

    // ASSIGNMENT UPDATE ITEM
    updateCoursematerialAssignmentItemStart: stateLoadingStart,
    updateCoursematerialAssignmentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `assignment_item`),
    updateCoursematerialAssignmentItemFailure: stateError,

    // ASSIGNMENT DELETE ITEM
    deleteCoursematerialAssignmentItemStart: stateLoadingStart,
    deleteCoursematerialAssignmentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_assignment`),
    deleteCoursematerialAssignmentItemFailure: stateError,


    // SUBMISSION REDUCER FUNCTION

    // SUBMISSION FETCH LIST
    fetchCoursematerialSubmissionListStart: stateLoadingStart,
    fetchCoursematerialSubmissionListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `submission_list`),
    fetchCoursematerialSubmissionListFailure: stateError,

    // SUBMISSION FETCH ITEM
    fetchCoursematerialSubmissionItemStart: stateLoadingStart,
    fetchCoursematerialSubmissionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `submission_item`),
    fetchCoursematerialSubmissionItemFailure: stateError,

    // SUBMISSION CREATE ITEM
    createCoursematerialSubmissionItemStart: stateLoadingStart,
    createCoursematerialSubmissionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `submission_item`),
    createCoursematerialSubmissionItemFailure: stateError,

    // SUBMISSION UPDATE ITEM
    updateCoursematerialSubmissionItemStart: stateLoadingStart,
    updateCoursematerialSubmissionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `submission_item`),
    updateCoursematerialSubmissionItemFailure: stateError,

    // SUBMISSION DELETE ITEM
    deleteCoursematerialSubmissionItemStart: stateLoadingStart,
    deleteCoursematerialSubmissionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_submission`),
    deleteCoursematerialSubmissionItemFailure: stateError,


  },
});

export const {
  fetchCoursematerialTopicListStart, fetchCoursematerialSubjectListStart, fetchCoursematerialCourseListStart, fetchCoursematerialCourseintroListStart, fetchCoursematerialCourselabelListStart, fetchCoursematerialModuleListStart, fetchCoursematerialResourceListStart, fetchCoursematerialLessonListStart, fetchCoursematerialAssignmentListStart, fetchCoursematerialSubmissionListStart,
  fetchCoursematerialTopicListSuccess, fetchCoursematerialSubjectListSuccess, fetchCoursematerialCourseListSuccess, fetchCoursematerialCourseintroListSuccess, fetchCoursematerialCourselabelListSuccess, fetchCoursematerialModuleListSuccess, fetchCoursematerialResourceListSuccess, fetchCoursematerialLessonListSuccess, fetchCoursematerialAssignmentListSuccess, fetchCoursematerialSubmissionListSuccess,
  fetchCoursematerialTopicListFailure, fetchCoursematerialSubjectListFailure, fetchCoursematerialCourseListFailure, fetchCoursematerialCourseintroListFailure, fetchCoursematerialCourselabelListFailure, fetchCoursematerialModuleListFailure, fetchCoursematerialResourceListFailure, fetchCoursematerialLessonListFailure, fetchCoursematerialAssignmentListFailure, fetchCoursematerialSubmissionListFailure,
  fetchCoursematerialTopicItemStart, fetchCoursematerialSubjectItemStart, fetchCoursematerialCourseItemStart, fetchCoursematerialCourseintroItemStart, fetchCoursematerialCourselabelItemStart, fetchCoursematerialModuleItemStart, fetchCoursematerialResourceItemStart, fetchCoursematerialLessonItemStart, fetchCoursematerialAssignmentItemStart, fetchCoursematerialSubmissionItemStart,
  fetchCoursematerialTopicItemSuccess, fetchCoursematerialSubjectItemSuccess, fetchCoursematerialCourseItemSuccess, fetchCoursematerialCourseintroItemSuccess, fetchCoursematerialCourselabelItemSuccess, fetchCoursematerialModuleItemSuccess, fetchCoursematerialResourceItemSuccess, fetchCoursematerialLessonItemSuccess, fetchCoursematerialAssignmentItemSuccess, fetchCoursematerialSubmissionItemSuccess,
  fetchCoursematerialTopicItemFailure, fetchCoursematerialSubjectItemFailure, fetchCoursematerialCourseItemFailure, fetchCoursematerialCourseintroItemFailure, fetchCoursematerialCourselabelItemFailure, fetchCoursematerialModuleItemFailure, fetchCoursematerialResourceItemFailure, fetchCoursematerialLessonItemFailure, fetchCoursematerialAssignmentItemFailure, fetchCoursematerialSubmissionItemFailure,
  createCoursematerialTopicItemStart, createCoursematerialSubjectItemStart, createCoursematerialCourseItemStart, createCoursematerialCourseintroItemStart, createCoursematerialCourselabelItemStart, createCoursematerialModuleItemStart, createCoursematerialResourceItemStart, createCoursematerialLessonItemStart, createCoursematerialAssignmentItemStart, createCoursematerialSubmissionItemStart,
  createCoursematerialTopicItemSuccess, createCoursematerialSubjectItemSuccess, createCoursematerialCourseItemSuccess, createCoursematerialCourseintroItemSuccess, createCoursematerialCourselabelItemSuccess, createCoursematerialModuleItemSuccess, createCoursematerialResourceItemSuccess, createCoursematerialLessonItemSuccess, createCoursematerialAssignmentItemSuccess, createCoursematerialSubmissionItemSuccess,
  createCoursematerialTopicItemFailure, createCoursematerialSubjectItemFailure, createCoursematerialCourseItemFailure, createCoursematerialCourseintroItemFailure, createCoursematerialCourselabelItemFailure, createCoursematerialModuleItemFailure, createCoursematerialResourceItemFailure, createCoursematerialLessonItemFailure, createCoursematerialAssignmentItemFailure, createCoursematerialSubmissionItemFailure,
  updateCoursematerialTopicItemStart, updateCoursematerialSubjectItemStart, updateCoursematerialCourseItemStart, updateCoursematerialCourseintroItemStart, updateCoursematerialCourselabelItemStart, updateCoursematerialModuleItemStart, updateCoursematerialResourceItemStart, updateCoursematerialLessonItemStart, updateCoursematerialAssignmentItemStart, updateCoursematerialSubmissionItemStart,
  updateCoursematerialTopicItemSuccess, updateCoursematerialSubjectItemSuccess, updateCoursematerialCourseItemSuccess, updateCoursematerialCourseintroItemSuccess, updateCoursematerialCourselabelItemSuccess, updateCoursematerialModuleItemSuccess, updateCoursematerialResourceItemSuccess, updateCoursematerialLessonItemSuccess, updateCoursematerialAssignmentItemSuccess, updateCoursematerialSubmissionItemSuccess,
  updateCoursematerialTopicItemFailure, updateCoursematerialSubjectItemFailure, updateCoursematerialCourseItemFailure, updateCoursematerialCourseintroItemFailure, updateCoursematerialCourselabelItemFailure, updateCoursematerialModuleItemFailure, updateCoursematerialResourceItemFailure, updateCoursematerialLessonItemFailure, updateCoursematerialAssignmentItemFailure, updateCoursematerialSubmissionItemFailure,
  deleteCoursematerialTopicItemStart, deleteCoursematerialSubjectItemStart, deleteCoursematerialCourseItemStart, deleteCoursematerialCourseintroItemStart, deleteCoursematerialCourselabelItemStart, deleteCoursematerialModuleItemStart, deleteCoursematerialResourceItemStart, deleteCoursematerialLessonItemStart, deleteCoursematerialAssignmentItemStart, deleteCoursematerialSubmissionItemStart,
  deleteCoursematerialTopicItemSuccess, deleteCoursematerialSubjectItemSuccess, deleteCoursematerialCourseItemSuccess, deleteCoursematerialCourseintroItemSuccess, deleteCoursematerialCourselabelItemSuccess, deleteCoursematerialModuleItemSuccess, deleteCoursematerialResourceItemSuccess, deleteCoursematerialLessonItemSuccess, deleteCoursematerialAssignmentItemSuccess, deleteCoursematerialSubmissionItemSuccess,
  deleteCoursematerialTopicItemFailure, deleteCoursematerialSubjectItemFailure, deleteCoursematerialCourseItemFailure, deleteCoursematerialCourseintroItemFailure, deleteCoursematerialCourselabelItemFailure, deleteCoursematerialModuleItemFailure, deleteCoursematerialResourceItemFailure, deleteCoursematerialLessonItemFailure, deleteCoursematerialAssignmentItemFailure, deleteCoursematerialSubmissionItemFailure
} = slice.actions;

export default slice.reducer;
