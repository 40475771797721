import axios from '../utils/axios';

import {fetchOpenresourceResourceListStart, fetchOpenresourceResourceListSuccess, fetchOpenresourceResourceListFailure} from '../features/openresource'
import {fetchOpenresourceResourceItemStart, fetchOpenresourceResourceItemSuccess, fetchOpenresourceResourceItemFailure} from '../features/openresource'
import {createOpenresourceResourceItemStart, createOpenresourceResourceItemSuccess, createOpenresourceResourceItemFailure} from '../features/openresource'
import {updateOpenresourceResourceItemStart, updateOpenresourceResourceItemSuccess, updateOpenresourceResourceItemFailure} from '../features/openresource'
import {deleteOpenresourceResourceItemStart, deleteOpenresourceResourceItemSuccess, deleteOpenresourceResourceItemFailure} from '../features/openresource'


// RESOURCE ACTIONS
export const fetchOpenresourceResourceList = async (dispatch, info, key) => {
    try{
        dispatch(fetchOpenresourceResourceListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/openresource/resource/`, { params: info.params, headers: info.headers });
        dispatch(fetchOpenresourceResourceListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchOpenresourceResourceListFailure({key, error: error.message}));
    }
}
export const fetchOpenresourceResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchOpenresourceResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/openresource/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchOpenresourceResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchOpenresourceResourceItemFailure({key, error: error.message}));
    }
}
export const createOpenresourceResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(createOpenresourceResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/openresource/resource/`, info.payload, { headers: info.headers });
        dispatch(createOpenresourceResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createOpenresourceResourceItemFailure({key, error: error.message}));
    }
}
export const updateOpenresourceResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(updateOpenresourceResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/openresource/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateOpenresourceResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateOpenresourceResourceItemFailure({key, error: error.message}));
    }
}
export const deleteOpenresourceResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteOpenresourceResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/openresource/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteOpenresourceResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteOpenresourceResourceItemFailure({key, error: error.message}));
    }
}




