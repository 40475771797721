import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'meet',
  initialState: { loading: false },
  reducers: {
    
    // CONFERENCE REDUCER FUNCTION

    // CONFERENCE FETCH LIST
    fetchMeetConferenceListStart: stateLoadingStart,
    fetchMeetConferenceListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `conference_list`),
    fetchMeetConferenceListFailure: stateError,

    // CONFERENCE FETCH ITEM
    fetchMeetConferenceItemStart: stateLoadingStart,
    fetchMeetConferenceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `conference_item`),
    fetchMeetConferenceItemFailure: stateError,

    // CONFERENCE CREATE ITEM
    createMeetConferenceItemStart: stateLoadingStart,
    createMeetConferenceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `conference_item`),
    createMeetConferenceItemFailure: stateError,

    // CONFERENCE UPDATE ITEM
    updateMeetConferenceItemStart: stateLoadingStart,
    updateMeetConferenceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `conference_item`),
    updateMeetConferenceItemFailure: stateError,

    // CONFERENCE DELETE ITEM
    deleteMeetConferenceItemStart: stateLoadingStart,
    deleteMeetConferenceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_conference`),
    deleteMeetConferenceItemFailure: stateError,


    // ROOM REDUCER FUNCTION

    // ROOM FETCH LIST
    fetchMeetRoomListStart: stateLoadingStart,
    fetchMeetRoomListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_list`),
    fetchMeetRoomListFailure: stateError,

    // ROOM FETCH ITEM
    fetchMeetRoomItemStart: stateLoadingStart,
    fetchMeetRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_item`),
    fetchMeetRoomItemFailure: stateError,

    // ROOM CREATE ITEM
    createMeetRoomItemStart: stateLoadingStart,
    createMeetRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_item`),
    createMeetRoomItemFailure: stateError,

    // ROOM UPDATE ITEM
    updateMeetRoomItemStart: stateLoadingStart,
    updateMeetRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_item`),
    updateMeetRoomItemFailure: stateError,

    // ROOM DELETE ITEM
    deleteMeetRoomItemStart: stateLoadingStart,
    deleteMeetRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_room`),
    deleteMeetRoomItemFailure: stateError,


    // CONFERENCEUSER REDUCER FUNCTION

    // CONFERENCEUSER FETCH LIST
    fetchMeetConferenceuserListStart: stateLoadingStart,
    fetchMeetConferenceuserListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `conferenceuser_list`),
    fetchMeetConferenceuserListFailure: stateError,

    // CONFERENCEUSER FETCH ITEM
    fetchMeetConferenceuserItemStart: stateLoadingStart,
    fetchMeetConferenceuserItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `conferenceuser_item`),
    fetchMeetConferenceuserItemFailure: stateError,

    // CONFERENCEUSER CREATE ITEM
    createMeetConferenceuserItemStart: stateLoadingStart,
    createMeetConferenceuserItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `conferenceuser_item`),
    createMeetConferenceuserItemFailure: stateError,

    // CONFERENCEUSER UPDATE ITEM
    updateMeetConferenceuserItemStart: stateLoadingStart,
    updateMeetConferenceuserItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `conferenceuser_item`),
    updateMeetConferenceuserItemFailure: stateError,

    // CONFERENCEUSER DELETE ITEM
    deleteMeetConferenceuserItemStart: stateLoadingStart,
    deleteMeetConferenceuserItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_conferenceuser`),
    deleteMeetConferenceuserItemFailure: stateError,


  },
});

export const {
  fetchMeetConferenceListStart, fetchMeetRoomListStart, fetchMeetConferenceuserListStart,
  fetchMeetConferenceListSuccess, fetchMeetRoomListSuccess, fetchMeetConferenceuserListSuccess,
  fetchMeetConferenceListFailure, fetchMeetRoomListFailure, fetchMeetConferenceuserListFailure,
  fetchMeetConferenceItemStart, fetchMeetRoomItemStart, fetchMeetConferenceuserItemStart,
  fetchMeetConferenceItemSuccess, fetchMeetRoomItemSuccess, fetchMeetConferenceuserItemSuccess,
  fetchMeetConferenceItemFailure, fetchMeetRoomItemFailure, fetchMeetConferenceuserItemFailure,
  createMeetConferenceItemStart, createMeetRoomItemStart, createMeetConferenceuserItemStart,
  createMeetConferenceItemSuccess, createMeetRoomItemSuccess, createMeetConferenceuserItemSuccess,
  createMeetConferenceItemFailure, createMeetRoomItemFailure, createMeetConferenceuserItemFailure,
  updateMeetConferenceItemStart, updateMeetRoomItemStart, updateMeetConferenceuserItemStart,
  updateMeetConferenceItemSuccess, updateMeetRoomItemSuccess, updateMeetConferenceuserItemSuccess,
  updateMeetConferenceItemFailure, updateMeetRoomItemFailure, updateMeetConferenceuserItemFailure,
  deleteMeetConferenceItemStart, deleteMeetRoomItemStart, deleteMeetConferenceuserItemStart,
  deleteMeetConferenceItemSuccess, deleteMeetRoomItemSuccess, deleteMeetConferenceuserItemSuccess,
  deleteMeetConferenceItemFailure, deleteMeetRoomItemFailure, deleteMeetConferenceuserItemFailure
} = slice.actions;

export default slice.reducer;
