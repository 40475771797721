import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'openresource',
  initialState: { loading: false },
  reducers: {
    
    // RESOURCE REDUCER FUNCTION

    // RESOURCE FETCH LIST
    fetchOpenresourceResourceListStart: stateLoadingStart,
    fetchOpenresourceResourceListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_list`),
    fetchOpenresourceResourceListFailure: stateError,

    // RESOURCE FETCH ITEM
    fetchOpenresourceResourceItemStart: stateLoadingStart,
    fetchOpenresourceResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    fetchOpenresourceResourceItemFailure: stateError,

    // RESOURCE CREATE ITEM
    createOpenresourceResourceItemStart: stateLoadingStart,
    createOpenresourceResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    createOpenresourceResourceItemFailure: stateError,

    // RESOURCE UPDATE ITEM
    updateOpenresourceResourceItemStart: stateLoadingStart,
    updateOpenresourceResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    updateOpenresourceResourceItemFailure: stateError,

    // RESOURCE DELETE ITEM
    deleteOpenresourceResourceItemStart: stateLoadingStart,
    deleteOpenresourceResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_resource`),
    deleteOpenresourceResourceItemFailure: stateError,


  },
});

export const {
  fetchOpenresourceResourceListStart,
  fetchOpenresourceResourceListSuccess,
  fetchOpenresourceResourceListFailure,
  fetchOpenresourceResourceItemStart,
  fetchOpenresourceResourceItemSuccess,
  fetchOpenresourceResourceItemFailure,
  createOpenresourceResourceItemStart,
  createOpenresourceResourceItemSuccess,
  createOpenresourceResourceItemFailure,
  updateOpenresourceResourceItemStart,
  updateOpenresourceResourceItemSuccess,
  updateOpenresourceResourceItemFailure,
  deleteOpenresourceResourceItemStart,
  deleteOpenresourceResourceItemSuccess,
  deleteOpenresourceResourceItemFailure
} = slice.actions;

export default slice.reducer;
