import axios from '../utils/axios';

import {fetchSeriesChannelListStart, fetchSeriesChannelListSuccess, fetchSeriesChannelListFailure} from '../features/series'
import {fetchSeriesChannelItemStart, fetchSeriesChannelItemSuccess, fetchSeriesChannelItemFailure} from '../features/series'
import {createSeriesChannelItemStart, createSeriesChannelItemSuccess, createSeriesChannelItemFailure} from '../features/series'
import {updateSeriesChannelItemStart, updateSeriesChannelItemSuccess, updateSeriesChannelItemFailure} from '../features/series'
import {deleteSeriesChannelItemStart, deleteSeriesChannelItemSuccess, deleteSeriesChannelItemFailure} from '../features/series'

import {fetchSeriesContentListStart, fetchSeriesContentListSuccess, fetchSeriesContentListFailure} from '../features/series'
import {fetchSeriesContentItemStart, fetchSeriesContentItemSuccess, fetchSeriesContentItemFailure} from '../features/series'
import {createSeriesContentItemStart, createSeriesContentItemSuccess, createSeriesContentItemFailure} from '../features/series'
import {updateSeriesContentItemStart, updateSeriesContentItemSuccess, updateSeriesContentItemFailure} from '../features/series'
import {deleteSeriesContentItemStart, deleteSeriesContentItemSuccess, deleteSeriesContentItemFailure} from '../features/series'


// CHANNEL ACTIONS
export const fetchSeriesChannelList = async (dispatch, info, key) => {
    try{
        dispatch(fetchSeriesChannelListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/series/channel/`, { params: info.params, headers: info.headers });
        dispatch(fetchSeriesChannelListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchSeriesChannelListFailure({key, error: error.message}));
    }
}
export const fetchSeriesChannelItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchSeriesChannelItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/series/channel/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchSeriesChannelItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchSeriesChannelItemFailure({key, error: error.message}));
    }
}
export const createSeriesChannelItem = async (dispatch, info, key) => {
    try{
        dispatch(createSeriesChannelItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/series/channel/`, info.payload, { headers: info.headers });
        dispatch(createSeriesChannelItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createSeriesChannelItemFailure({key, error: error.message}));
    }
}
export const updateSeriesChannelItem = async (dispatch, info, key) => {
    try{
        dispatch(updateSeriesChannelItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/series/channel/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateSeriesChannelItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateSeriesChannelItemFailure({key, error: error.message}));
    }
}
export const deleteSeriesChannelItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteSeriesChannelItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/series/channel/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteSeriesChannelItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteSeriesChannelItemFailure({key, error: error.message}));
    }
}


// CONTENT ACTIONS
export const fetchSeriesContentList = async (dispatch, info, key) => {
    try{
        dispatch(fetchSeriesContentListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/series/content/`, { params: info.params, headers: info.headers });
        dispatch(fetchSeriesContentListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchSeriesContentListFailure({key, error: error.message}));
    }
}
export const fetchSeriesContentItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchSeriesContentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/series/content/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchSeriesContentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchSeriesContentItemFailure({key, error: error.message}));
    }
}
export const createSeriesContentItem = async (dispatch, info, key) => {
    try{
        dispatch(createSeriesContentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/series/content/`, info.payload, { headers: info.headers });
        dispatch(createSeriesContentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createSeriesContentItemFailure({key, error: error.message}));
    }
}
export const updateSeriesContentItem = async (dispatch, info, key) => {
    try{
        dispatch(updateSeriesContentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/series/content/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateSeriesContentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateSeriesContentItemFailure({key, error: error.message}));
    }
}
export const deleteSeriesContentItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteSeriesContentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/series/content/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteSeriesContentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteSeriesContentItemFailure({key, error: error.message}));
    }
}




