import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'access',
  initialState: { loading: false },
  reducers: {
    
    // ACCESSCOURSEMATERIAL REDUCER FUNCTION

    // ACCESSCOURSEMATERIAL FETCH LIST
    fetchAccessAccesscoursematerialListStart: stateLoadingStart,
    fetchAccessAccesscoursematerialListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accesscoursematerial_list`),
    fetchAccessAccesscoursematerialListFailure: stateError,

    // ACCESSCOURSEMATERIAL FETCH ITEM
    fetchAccessAccesscoursematerialItemStart: stateLoadingStart,
    fetchAccessAccesscoursematerialItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accesscoursematerial_item`),
    fetchAccessAccesscoursematerialItemFailure: stateError,

    // ACCESSCOURSEMATERIAL CREATE ITEM
    createAccessAccesscoursematerialItemStart: stateLoadingStart,
    createAccessAccesscoursematerialItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accesscoursematerial_item`),
    createAccessAccesscoursematerialItemFailure: stateError,

    // ACCESSCOURSEMATERIAL UPDATE ITEM
    updateAccessAccesscoursematerialItemStart: stateLoadingStart,
    updateAccessAccesscoursematerialItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accesscoursematerial_item`),
    updateAccessAccesscoursematerialItemFailure: stateError,

    // ACCESSCOURSEMATERIAL DELETE ITEM
    deleteAccessAccesscoursematerialItemStart: stateLoadingStart,
    deleteAccessAccesscoursematerialItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_accesscoursematerial`),
    deleteAccessAccesscoursematerialItemFailure: stateError,


    // ACCESSNEWSLETTER REDUCER FUNCTION

    // ACCESSNEWSLETTER FETCH LIST
    fetchAccessAccessnewsletterListStart: stateLoadingStart,
    fetchAccessAccessnewsletterListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessnewsletter_list`),
    fetchAccessAccessnewsletterListFailure: stateError,

    // ACCESSNEWSLETTER FETCH ITEM
    fetchAccessAccessnewsletterItemStart: stateLoadingStart,
    fetchAccessAccessnewsletterItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessnewsletter_item`),
    fetchAccessAccessnewsletterItemFailure: stateError,

    // ACCESSNEWSLETTER CREATE ITEM
    createAccessAccessnewsletterItemStart: stateLoadingStart,
    createAccessAccessnewsletterItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessnewsletter_item`),
    createAccessAccessnewsletterItemFailure: stateError,

    // ACCESSNEWSLETTER UPDATE ITEM
    updateAccessAccessnewsletterItemStart: stateLoadingStart,
    updateAccessAccessnewsletterItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessnewsletter_item`),
    updateAccessAccessnewsletterItemFailure: stateError,

    // ACCESSNEWSLETTER DELETE ITEM
    deleteAccessAccessnewsletterItemStart: stateLoadingStart,
    deleteAccessAccessnewsletterItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_accessnewsletter`),
    deleteAccessAccessnewsletterItemFailure: stateError,


    // ACCESSPOSTORBLOG REDUCER FUNCTION

    // ACCESSPOSTORBLOG FETCH LIST
    fetchAccessAccesspostorblogListStart: stateLoadingStart,
    fetchAccessAccesspostorblogListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accesspostorblog_list`),
    fetchAccessAccesspostorblogListFailure: stateError,

    // ACCESSPOSTORBLOG FETCH ITEM
    fetchAccessAccesspostorblogItemStart: stateLoadingStart,
    fetchAccessAccesspostorblogItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accesspostorblog_item`),
    fetchAccessAccesspostorblogItemFailure: stateError,

    // ACCESSPOSTORBLOG CREATE ITEM
    createAccessAccesspostorblogItemStart: stateLoadingStart,
    createAccessAccesspostorblogItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accesspostorblog_item`),
    createAccessAccesspostorblogItemFailure: stateError,

    // ACCESSPOSTORBLOG UPDATE ITEM
    updateAccessAccesspostorblogItemStart: stateLoadingStart,
    updateAccessAccesspostorblogItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accesspostorblog_item`),
    updateAccessAccesspostorblogItemFailure: stateError,

    // ACCESSPOSTORBLOG DELETE ITEM
    deleteAccessAccesspostorblogItemStart: stateLoadingStart,
    deleteAccessAccesspostorblogItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_accesspostorblog`),
    deleteAccessAccesspostorblogItemFailure: stateError,


    // ACCESSQUIZ REDUCER FUNCTION

    // ACCESSQUIZ FETCH LIST
    fetchAccessAccessquizListStart: stateLoadingStart,
    fetchAccessAccessquizListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessquiz_list`),
    fetchAccessAccessquizListFailure: stateError,

    // ACCESSQUIZ FETCH ITEM
    fetchAccessAccessquizItemStart: stateLoadingStart,
    fetchAccessAccessquizItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessquiz_item`),
    fetchAccessAccessquizItemFailure: stateError,

    // ACCESSQUIZ CREATE ITEM
    createAccessAccessquizItemStart: stateLoadingStart,
    createAccessAccessquizItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessquiz_item`),
    createAccessAccessquizItemFailure: stateError,

    // ACCESSQUIZ UPDATE ITEM
    updateAccessAccessquizItemStart: stateLoadingStart,
    updateAccessAccessquizItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessquiz_item`),
    updateAccessAccessquizItemFailure: stateError,

    // ACCESSQUIZ DELETE ITEM
    deleteAccessAccessquizItemStart: stateLoadingStart,
    deleteAccessAccessquizItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_accessquiz`),
    deleteAccessAccessquizItemFailure: stateError,


    // ACCESSSERIES REDUCER FUNCTION

    // ACCESSSERIES FETCH LIST
    fetchAccessAccessseriesListStart: stateLoadingStart,
    fetchAccessAccessseriesListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessseries_list`),
    fetchAccessAccessseriesListFailure: stateError,

    // ACCESSSERIES FETCH ITEM
    fetchAccessAccessseriesItemStart: stateLoadingStart,
    fetchAccessAccessseriesItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessseries_item`),
    fetchAccessAccessseriesItemFailure: stateError,

    // ACCESSSERIES CREATE ITEM
    createAccessAccessseriesItemStart: stateLoadingStart,
    createAccessAccessseriesItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessseries_item`),
    createAccessAccessseriesItemFailure: stateError,

    // ACCESSSERIES UPDATE ITEM
    updateAccessAccessseriesItemStart: stateLoadingStart,
    updateAccessAccessseriesItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `accessseries_item`),
    updateAccessAccessseriesItemFailure: stateError,

    // ACCESSSERIES DELETE ITEM
    deleteAccessAccessseriesItemStart: stateLoadingStart,
    deleteAccessAccessseriesItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_accessseries`),
    deleteAccessAccessseriesItemFailure: stateError,


  },
});

export const {
  fetchAccessAccesscoursematerialListStart, fetchAccessAccessnewsletterListStart, fetchAccessAccesspostorblogListStart, fetchAccessAccessquizListStart, fetchAccessAccessseriesListStart,
  fetchAccessAccesscoursematerialListSuccess, fetchAccessAccessnewsletterListSuccess, fetchAccessAccesspostorblogListSuccess, fetchAccessAccessquizListSuccess, fetchAccessAccessseriesListSuccess,
  fetchAccessAccesscoursematerialListFailure, fetchAccessAccessnewsletterListFailure, fetchAccessAccesspostorblogListFailure, fetchAccessAccessquizListFailure, fetchAccessAccessseriesListFailure,
  fetchAccessAccesscoursematerialItemStart, fetchAccessAccessnewsletterItemStart, fetchAccessAccesspostorblogItemStart, fetchAccessAccessquizItemStart, fetchAccessAccessseriesItemStart,
  fetchAccessAccesscoursematerialItemSuccess, fetchAccessAccessnewsletterItemSuccess, fetchAccessAccesspostorblogItemSuccess, fetchAccessAccessquizItemSuccess, fetchAccessAccessseriesItemSuccess,
  fetchAccessAccesscoursematerialItemFailure, fetchAccessAccessnewsletterItemFailure, fetchAccessAccesspostorblogItemFailure, fetchAccessAccessquizItemFailure, fetchAccessAccessseriesItemFailure,
  createAccessAccesscoursematerialItemStart, createAccessAccessnewsletterItemStart, createAccessAccesspostorblogItemStart, createAccessAccessquizItemStart, createAccessAccessseriesItemStart,
  createAccessAccesscoursematerialItemSuccess, createAccessAccessnewsletterItemSuccess, createAccessAccesspostorblogItemSuccess, createAccessAccessquizItemSuccess, createAccessAccessseriesItemSuccess,
  createAccessAccesscoursematerialItemFailure, createAccessAccessnewsletterItemFailure, createAccessAccesspostorblogItemFailure, createAccessAccessquizItemFailure, createAccessAccessseriesItemFailure,
  updateAccessAccesscoursematerialItemStart, updateAccessAccessnewsletterItemStart, updateAccessAccesspostorblogItemStart, updateAccessAccessquizItemStart, updateAccessAccessseriesItemStart,
  updateAccessAccesscoursematerialItemSuccess, updateAccessAccessnewsletterItemSuccess, updateAccessAccesspostorblogItemSuccess, updateAccessAccessquizItemSuccess, updateAccessAccessseriesItemSuccess,
  updateAccessAccesscoursematerialItemFailure, updateAccessAccessnewsletterItemFailure, updateAccessAccesspostorblogItemFailure, updateAccessAccessquizItemFailure, updateAccessAccessseriesItemFailure,
  deleteAccessAccesscoursematerialItemStart, deleteAccessAccessnewsletterItemStart, deleteAccessAccesspostorblogItemStart, deleteAccessAccessquizItemStart, deleteAccessAccessseriesItemStart,
  deleteAccessAccesscoursematerialItemSuccess, deleteAccessAccessnewsletterItemSuccess, deleteAccessAccesspostorblogItemSuccess, deleteAccessAccessquizItemSuccess, deleteAccessAccessseriesItemSuccess,
  deleteAccessAccesscoursematerialItemFailure, deleteAccessAccessnewsletterItemFailure, deleteAccessAccesspostorblogItemFailure, deleteAccessAccessquizItemFailure, deleteAccessAccessseriesItemFailure
} = slice.actions;

export default slice.reducer;
