import React from 'react';
import { Provider } from 'react-redux';
import action from './utils/networkActions'
import store from './app/store';
import './App.css';

function App({children, baseURL}) {
  localStorage.setItem("FABU", baseURL)
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}

export const utils = { action }

export default App;
