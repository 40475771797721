import axios from '../utils/axios';

import {fetchCoursematerialTopicListStart, fetchCoursematerialTopicListSuccess, fetchCoursematerialTopicListFailure} from '../features/coursematerial'
import {fetchCoursematerialTopicItemStart, fetchCoursematerialTopicItemSuccess, fetchCoursematerialTopicItemFailure} from '../features/coursematerial'
import {createCoursematerialTopicItemStart, createCoursematerialTopicItemSuccess, createCoursematerialTopicItemFailure} from '../features/coursematerial'
import {updateCoursematerialTopicItemStart, updateCoursematerialTopicItemSuccess, updateCoursematerialTopicItemFailure} from '../features/coursematerial'
import {deleteCoursematerialTopicItemStart, deleteCoursematerialTopicItemSuccess, deleteCoursematerialTopicItemFailure} from '../features/coursematerial'

import {fetchCoursematerialSubjectListStart, fetchCoursematerialSubjectListSuccess, fetchCoursematerialSubjectListFailure} from '../features/coursematerial'
import {fetchCoursematerialSubjectItemStart, fetchCoursematerialSubjectItemSuccess, fetchCoursematerialSubjectItemFailure} from '../features/coursematerial'
import {createCoursematerialSubjectItemStart, createCoursematerialSubjectItemSuccess, createCoursematerialSubjectItemFailure} from '../features/coursematerial'
import {updateCoursematerialSubjectItemStart, updateCoursematerialSubjectItemSuccess, updateCoursematerialSubjectItemFailure} from '../features/coursematerial'
import {deleteCoursematerialSubjectItemStart, deleteCoursematerialSubjectItemSuccess, deleteCoursematerialSubjectItemFailure} from '../features/coursematerial'

import {fetchCoursematerialCourseListStart, fetchCoursematerialCourseListSuccess, fetchCoursematerialCourseListFailure} from '../features/coursematerial'
import {fetchCoursematerialCourseItemStart, fetchCoursematerialCourseItemSuccess, fetchCoursematerialCourseItemFailure} from '../features/coursematerial'
import {createCoursematerialCourseItemStart, createCoursematerialCourseItemSuccess, createCoursematerialCourseItemFailure} from '../features/coursematerial'
import {updateCoursematerialCourseItemStart, updateCoursematerialCourseItemSuccess, updateCoursematerialCourseItemFailure} from '../features/coursematerial'
import {deleteCoursematerialCourseItemStart, deleteCoursematerialCourseItemSuccess, deleteCoursematerialCourseItemFailure} from '../features/coursematerial'

import {fetchCoursematerialCourseintroListStart, fetchCoursematerialCourseintroListSuccess, fetchCoursematerialCourseintroListFailure} from '../features/coursematerial'
import {fetchCoursematerialCourseintroItemStart, fetchCoursematerialCourseintroItemSuccess, fetchCoursematerialCourseintroItemFailure} from '../features/coursematerial'
import {createCoursematerialCourseintroItemStart, createCoursematerialCourseintroItemSuccess, createCoursematerialCourseintroItemFailure} from '../features/coursematerial'
import {updateCoursematerialCourseintroItemStart, updateCoursematerialCourseintroItemSuccess, updateCoursematerialCourseintroItemFailure} from '../features/coursematerial'
import {deleteCoursematerialCourseintroItemStart, deleteCoursematerialCourseintroItemSuccess, deleteCoursematerialCourseintroItemFailure} from '../features/coursematerial'

import {fetchCoursematerialCourselabelListStart, fetchCoursematerialCourselabelListSuccess, fetchCoursematerialCourselabelListFailure} from '../features/coursematerial'
import {fetchCoursematerialCourselabelItemStart, fetchCoursematerialCourselabelItemSuccess, fetchCoursematerialCourselabelItemFailure} from '../features/coursematerial'
import {createCoursematerialCourselabelItemStart, createCoursematerialCourselabelItemSuccess, createCoursematerialCourselabelItemFailure} from '../features/coursematerial'
import {updateCoursematerialCourselabelItemStart, updateCoursematerialCourselabelItemSuccess, updateCoursematerialCourselabelItemFailure} from '../features/coursematerial'
import {deleteCoursematerialCourselabelItemStart, deleteCoursematerialCourselabelItemSuccess, deleteCoursematerialCourselabelItemFailure} from '../features/coursematerial'

import {fetchCoursematerialModuleListStart, fetchCoursematerialModuleListSuccess, fetchCoursematerialModuleListFailure} from '../features/coursematerial'
import {fetchCoursematerialModuleItemStart, fetchCoursematerialModuleItemSuccess, fetchCoursematerialModuleItemFailure} from '../features/coursematerial'
import {createCoursematerialModuleItemStart, createCoursematerialModuleItemSuccess, createCoursematerialModuleItemFailure} from '../features/coursematerial'
import {updateCoursematerialModuleItemStart, updateCoursematerialModuleItemSuccess, updateCoursematerialModuleItemFailure} from '../features/coursematerial'
import {deleteCoursematerialModuleItemStart, deleteCoursematerialModuleItemSuccess, deleteCoursematerialModuleItemFailure} from '../features/coursematerial'

import {fetchCoursematerialResourceListStart, fetchCoursematerialResourceListSuccess, fetchCoursematerialResourceListFailure} from '../features/coursematerial'
import {fetchCoursematerialResourceItemStart, fetchCoursematerialResourceItemSuccess, fetchCoursematerialResourceItemFailure} from '../features/coursematerial'
import {createCoursematerialResourceItemStart, createCoursematerialResourceItemSuccess, createCoursematerialResourceItemFailure} from '../features/coursematerial'
import {updateCoursematerialResourceItemStart, updateCoursematerialResourceItemSuccess, updateCoursematerialResourceItemFailure} from '../features/coursematerial'
import {deleteCoursematerialResourceItemStart, deleteCoursematerialResourceItemSuccess, deleteCoursematerialResourceItemFailure} from '../features/coursematerial'

import {fetchCoursematerialLessonListStart, fetchCoursematerialLessonListSuccess, fetchCoursematerialLessonListFailure} from '../features/coursematerial'
import {fetchCoursematerialLessonItemStart, fetchCoursematerialLessonItemSuccess, fetchCoursematerialLessonItemFailure} from '../features/coursematerial'
import {createCoursematerialLessonItemStart, createCoursematerialLessonItemSuccess, createCoursematerialLessonItemFailure} from '../features/coursematerial'
import {updateCoursematerialLessonItemStart, updateCoursematerialLessonItemSuccess, updateCoursematerialLessonItemFailure} from '../features/coursematerial'
import {deleteCoursematerialLessonItemStart, deleteCoursematerialLessonItemSuccess, deleteCoursematerialLessonItemFailure} from '../features/coursematerial'

import {fetchCoursematerialAssignmentListStart, fetchCoursematerialAssignmentListSuccess, fetchCoursematerialAssignmentListFailure} from '../features/coursematerial'
import {fetchCoursematerialAssignmentItemStart, fetchCoursematerialAssignmentItemSuccess, fetchCoursematerialAssignmentItemFailure} from '../features/coursematerial'
import {createCoursematerialAssignmentItemStart, createCoursematerialAssignmentItemSuccess, createCoursematerialAssignmentItemFailure} from '../features/coursematerial'
import {updateCoursematerialAssignmentItemStart, updateCoursematerialAssignmentItemSuccess, updateCoursematerialAssignmentItemFailure} from '../features/coursematerial'
import {deleteCoursematerialAssignmentItemStart, deleteCoursematerialAssignmentItemSuccess, deleteCoursematerialAssignmentItemFailure} from '../features/coursematerial'

import {fetchCoursematerialSubmissionListStart, fetchCoursematerialSubmissionListSuccess, fetchCoursematerialSubmissionListFailure} from '../features/coursematerial'
import {fetchCoursematerialSubmissionItemStart, fetchCoursematerialSubmissionItemSuccess, fetchCoursematerialSubmissionItemFailure} from '../features/coursematerial'
import {createCoursematerialSubmissionItemStart, createCoursematerialSubmissionItemSuccess, createCoursematerialSubmissionItemFailure} from '../features/coursematerial'
import {updateCoursematerialSubmissionItemStart, updateCoursematerialSubmissionItemSuccess, updateCoursematerialSubmissionItemFailure} from '../features/coursematerial'
import {deleteCoursematerialSubmissionItemStart, deleteCoursematerialSubmissionItemSuccess, deleteCoursematerialSubmissionItemFailure} from '../features/coursematerial'


// TOPIC ACTIONS
export const fetchCoursematerialTopicList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialTopicListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/topic/`, { params: info.params, headers: info.headers });
        dispatch(fetchCoursematerialTopicListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialTopicListFailure({key, error: error.message}));
    }
}
export const fetchCoursematerialTopicItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialTopicItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/topic/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCoursematerialTopicItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialTopicItemFailure({key, error: error.message}));
    }
}
export const createCoursematerialTopicItem = async (dispatch, info, key) => {
    try{
        dispatch(createCoursematerialTopicItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/coursematerial/topic/`, info.payload, { headers: info.headers });
        dispatch(createCoursematerialTopicItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCoursematerialTopicItemFailure({key, error: error.message}));
    }
}
export const updateCoursematerialTopicItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCoursematerialTopicItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/coursematerial/topic/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCoursematerialTopicItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCoursematerialTopicItemFailure({key, error: error.message}));
    }
}
export const deleteCoursematerialTopicItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCoursematerialTopicItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/coursematerial/topic/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCoursematerialTopicItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCoursematerialTopicItemFailure({key, error: error.message}));
    }
}


// SUBJECT ACTIONS
export const fetchCoursematerialSubjectList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialSubjectListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/subject/`, { params: info.params, headers: info.headers });
        dispatch(fetchCoursematerialSubjectListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialSubjectListFailure({key, error: error.message}));
    }
}
export const fetchCoursematerialSubjectItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialSubjectItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/subject/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCoursematerialSubjectItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialSubjectItemFailure({key, error: error.message}));
    }
}
export const createCoursematerialSubjectItem = async (dispatch, info, key) => {
    try{
        dispatch(createCoursematerialSubjectItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/coursematerial/subject/`, info.payload, { headers: info.headers });
        dispatch(createCoursematerialSubjectItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCoursematerialSubjectItemFailure({key, error: error.message}));
    }
}
export const updateCoursematerialSubjectItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCoursematerialSubjectItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/coursematerial/subject/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCoursematerialSubjectItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCoursematerialSubjectItemFailure({key, error: error.message}));
    }
}
export const deleteCoursematerialSubjectItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCoursematerialSubjectItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/coursematerial/subject/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCoursematerialSubjectItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCoursematerialSubjectItemFailure({key, error: error.message}));
    }
}


// COURSE ACTIONS
export const fetchCoursematerialCourseList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialCourseListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/course/`, { params: info.params, headers: info.headers });
        dispatch(fetchCoursematerialCourseListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialCourseListFailure({key, error: error.message}));
    }
}
export const fetchCoursematerialCourseItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialCourseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/course/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCoursematerialCourseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialCourseItemFailure({key, error: error.message}));
    }
}
export const createCoursematerialCourseItem = async (dispatch, info, key) => {
    try{
        dispatch(createCoursematerialCourseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/coursematerial/course/`, info.payload, { headers: info.headers });
        dispatch(createCoursematerialCourseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCoursematerialCourseItemFailure({key, error: error.message}));
    }
}
export const updateCoursematerialCourseItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCoursematerialCourseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/coursematerial/course/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCoursematerialCourseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCoursematerialCourseItemFailure({key, error: error.message}));
    }
}
export const deleteCoursematerialCourseItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCoursematerialCourseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/coursematerial/course/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCoursematerialCourseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCoursematerialCourseItemFailure({key, error: error.message}));
    }
}


// COURSEINTRO ACTIONS
export const fetchCoursematerialCourseintroList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialCourseintroListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/courseintro/`, { params: info.params, headers: info.headers });
        dispatch(fetchCoursematerialCourseintroListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialCourseintroListFailure({key, error: error.message}));
    }
}
export const fetchCoursematerialCourseintroItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialCourseintroItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/courseintro/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCoursematerialCourseintroItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialCourseintroItemFailure({key, error: error.message}));
    }
}
export const createCoursematerialCourseintroItem = async (dispatch, info, key) => {
    try{
        dispatch(createCoursematerialCourseintroItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/coursematerial/courseintro/`, info.payload, { headers: info.headers });
        dispatch(createCoursematerialCourseintroItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCoursematerialCourseintroItemFailure({key, error: error.message}));
    }
}
export const updateCoursematerialCourseintroItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCoursematerialCourseintroItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/coursematerial/courseintro/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCoursematerialCourseintroItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCoursematerialCourseintroItemFailure({key, error: error.message}));
    }
}
export const deleteCoursematerialCourseintroItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCoursematerialCourseintroItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/coursematerial/courseintro/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCoursematerialCourseintroItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCoursematerialCourseintroItemFailure({key, error: error.message}));
    }
}


// COURSELABEL ACTIONS
export const fetchCoursematerialCourselabelList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialCourselabelListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/courselabel/`, { params: info.params, headers: info.headers });
        dispatch(fetchCoursematerialCourselabelListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialCourselabelListFailure({key, error: error.message}));
    }
}
export const fetchCoursematerialCourselabelItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialCourselabelItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/courselabel/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCoursematerialCourselabelItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialCourselabelItemFailure({key, error: error.message}));
    }
}
export const createCoursematerialCourselabelItem = async (dispatch, info, key) => {
    try{
        dispatch(createCoursematerialCourselabelItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/coursematerial/courselabel/`, info.payload, { headers: info.headers });
        dispatch(createCoursematerialCourselabelItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCoursematerialCourselabelItemFailure({key, error: error.message}));
    }
}
export const updateCoursematerialCourselabelItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCoursematerialCourselabelItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/coursematerial/courselabel/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCoursematerialCourselabelItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCoursematerialCourselabelItemFailure({key, error: error.message}));
    }
}
export const deleteCoursematerialCourselabelItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCoursematerialCourselabelItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/coursematerial/courselabel/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCoursematerialCourselabelItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCoursematerialCourselabelItemFailure({key, error: error.message}));
    }
}


// MODULE ACTIONS
export const fetchCoursematerialModuleList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialModuleListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/module/`, { params: info.params, headers: info.headers });
        dispatch(fetchCoursematerialModuleListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialModuleListFailure({key, error: error.message}));
    }
}
export const fetchCoursematerialModuleItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialModuleItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/module/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCoursematerialModuleItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialModuleItemFailure({key, error: error.message}));
    }
}
export const createCoursematerialModuleItem = async (dispatch, info, key) => {
    try{
        dispatch(createCoursematerialModuleItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/coursematerial/module/`, info.payload, { headers: info.headers });
        dispatch(createCoursematerialModuleItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCoursematerialModuleItemFailure({key, error: error.message}));
    }
}
export const updateCoursematerialModuleItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCoursematerialModuleItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/coursematerial/module/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCoursematerialModuleItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCoursematerialModuleItemFailure({key, error: error.message}));
    }
}
export const deleteCoursematerialModuleItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCoursematerialModuleItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/coursematerial/module/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCoursematerialModuleItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCoursematerialModuleItemFailure({key, error: error.message}));
    }
}


// RESOURCE ACTIONS
export const fetchCoursematerialResourceList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialResourceListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/resource/`, { params: info.params, headers: info.headers });
        dispatch(fetchCoursematerialResourceListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialResourceListFailure({key, error: error.message}));
    }
}
export const fetchCoursematerialResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCoursematerialResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialResourceItemFailure({key, error: error.message}));
    }
}
export const createCoursematerialResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(createCoursematerialResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/coursematerial/resource/`, info.payload, { headers: info.headers });
        dispatch(createCoursematerialResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCoursematerialResourceItemFailure({key, error: error.message}));
    }
}
export const updateCoursematerialResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCoursematerialResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/coursematerial/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCoursematerialResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCoursematerialResourceItemFailure({key, error: error.message}));
    }
}
export const deleteCoursematerialResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCoursematerialResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/coursematerial/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCoursematerialResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCoursematerialResourceItemFailure({key, error: error.message}));
    }
}


// LESSON ACTIONS
export const fetchCoursematerialLessonList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialLessonListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/lesson/`, { params: info.params, headers: info.headers });
        dispatch(fetchCoursematerialLessonListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialLessonListFailure({key, error: error.message}));
    }
}
export const fetchCoursematerialLessonItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialLessonItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/lesson/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCoursematerialLessonItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialLessonItemFailure({key, error: error.message}));
    }
}
export const createCoursematerialLessonItem = async (dispatch, info, key) => {
    try{
        dispatch(createCoursematerialLessonItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/coursematerial/lesson/`, info.payload, { headers: info.headers });
        dispatch(createCoursematerialLessonItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCoursematerialLessonItemFailure({key, error: error.message}));
    }
}
export const updateCoursematerialLessonItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCoursematerialLessonItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/coursematerial/lesson/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCoursematerialLessonItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCoursematerialLessonItemFailure({key, error: error.message}));
    }
}
export const deleteCoursematerialLessonItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCoursematerialLessonItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/coursematerial/lesson/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCoursematerialLessonItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCoursematerialLessonItemFailure({key, error: error.message}));
    }
}


// ASSIGNMENT ACTIONS
export const fetchCoursematerialAssignmentList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialAssignmentListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/assignment/`, { params: info.params, headers: info.headers });
        dispatch(fetchCoursematerialAssignmentListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialAssignmentListFailure({key, error: error.message}));
    }
}
export const fetchCoursematerialAssignmentItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialAssignmentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/assignment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCoursematerialAssignmentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialAssignmentItemFailure({key, error: error.message}));
    }
}
export const createCoursematerialAssignmentItem = async (dispatch, info, key) => {
    try{
        dispatch(createCoursematerialAssignmentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/coursematerial/assignment/`, info.payload, { headers: info.headers });
        dispatch(createCoursematerialAssignmentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCoursematerialAssignmentItemFailure({key, error: error.message}));
    }
}
export const updateCoursematerialAssignmentItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCoursematerialAssignmentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/coursematerial/assignment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCoursematerialAssignmentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCoursematerialAssignmentItemFailure({key, error: error.message}));
    }
}
export const deleteCoursematerialAssignmentItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCoursematerialAssignmentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/coursematerial/assignment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCoursematerialAssignmentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCoursematerialAssignmentItemFailure({key, error: error.message}));
    }
}


// SUBMISSION ACTIONS
export const fetchCoursematerialSubmissionList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialSubmissionListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/submission/`, { params: info.params, headers: info.headers });
        dispatch(fetchCoursematerialSubmissionListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialSubmissionListFailure({key, error: error.message}));
    }
}
export const fetchCoursematerialSubmissionItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCoursematerialSubmissionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/coursematerial/submission/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCoursematerialSubmissionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCoursematerialSubmissionItemFailure({key, error: error.message}));
    }
}
export const createCoursematerialSubmissionItem = async (dispatch, info, key) => {
    try{
        dispatch(createCoursematerialSubmissionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/coursematerial/submission/`, info.payload, { headers: info.headers });
        dispatch(createCoursematerialSubmissionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCoursematerialSubmissionItemFailure({key, error: error.message}));
    }
}
export const updateCoursematerialSubmissionItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCoursematerialSubmissionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/coursematerial/submission/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCoursematerialSubmissionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCoursematerialSubmissionItemFailure({key, error: error.message}));
    }
}
export const deleteCoursematerialSubmissionItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCoursematerialSubmissionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/coursematerial/submission/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCoursematerialSubmissionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCoursematerialSubmissionItemFailure({key, error: error.message}));
    }
}




