import axios from '../utils/axios';

import {fetchEventEventListStart, fetchEventEventListSuccess, fetchEventEventListFailure} from '../features/event'
import {fetchEventEventItemStart, fetchEventEventItemSuccess, fetchEventEventItemFailure} from '../features/event'
import {createEventEventItemStart, createEventEventItemSuccess, createEventEventItemFailure} from '../features/event'
import {updateEventEventItemStart, updateEventEventItemSuccess, updateEventEventItemFailure} from '../features/event'
import {deleteEventEventItemStart, deleteEventEventItemSuccess, deleteEventEventItemFailure} from '../features/event'

import {fetchEventTicketListStart, fetchEventTicketListSuccess, fetchEventTicketListFailure} from '../features/event'
import {fetchEventTicketItemStart, fetchEventTicketItemSuccess, fetchEventTicketItemFailure} from '../features/event'
import {createEventTicketItemStart, createEventTicketItemSuccess, createEventTicketItemFailure} from '../features/event'
import {updateEventTicketItemStart, updateEventTicketItemSuccess, updateEventTicketItemFailure} from '../features/event'
import {deleteEventTicketItemStart, deleteEventTicketItemSuccess, deleteEventTicketItemFailure} from '../features/event'


// EVENT ACTIONS
export const fetchEventEventList = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventEventListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/event/`, { params: info.params, headers: info.headers });
        dispatch(fetchEventEventListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventEventListFailure({key, error: error.message}));
    }
}
export const fetchEventEventItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventEventItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/event/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchEventEventItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventEventItemFailure({key, error: error.message}));
    }
}
export const createEventEventItem = async (dispatch, info, key) => {
    try{
        dispatch(createEventEventItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/event/event/`, info.payload, { headers: info.headers });
        dispatch(createEventEventItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createEventEventItemFailure({key, error: error.message}));
    }
}
export const updateEventEventItem = async (dispatch, info, key) => {
    try{
        dispatch(updateEventEventItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/event/event/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateEventEventItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateEventEventItemFailure({key, error: error.message}));
    }
}
export const deleteEventEventItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteEventEventItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/event/event/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteEventEventItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteEventEventItemFailure({key, error: error.message}));
    }
}


// TICKET ACTIONS
export const fetchEventTicketList = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventTicketListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/ticket/`, { params: info.params, headers: info.headers });
        dispatch(fetchEventTicketListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventTicketListFailure({key, error: error.message}));
    }
}
export const fetchEventTicketItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchEventTicketItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/event/ticket/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchEventTicketItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchEventTicketItemFailure({key, error: error.message}));
    }
}
export const createEventTicketItem = async (dispatch, info, key) => {
    try{
        dispatch(createEventTicketItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/event/ticket/`, info.payload, { headers: info.headers });
        dispatch(createEventTicketItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createEventTicketItemFailure({key, error: error.message}));
    }
}
export const updateEventTicketItem = async (dispatch, info, key) => {
    try{
        dispatch(updateEventTicketItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/event/ticket/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateEventTicketItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateEventTicketItemFailure({key, error: error.message}));
    }
}
export const deleteEventTicketItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteEventTicketItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/event/ticket/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteEventTicketItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteEventTicketItemFailure({key, error: error.message}));
    }
}




