import { jwtDecode } from "jwt-decode";

export const setUserId=()=>{
  const userData = localStorage.getItem("tk");
  const decoded = jwtDecode(JSON.parse(userData).access);
  const userID = decoded.user_id;
  localStorage.setItem("user_id" , userID)
}

export const setUserData = (value) => {
  const accessToken = value.access;
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("tk", JSON.stringify(value));
    setUserId(accessToken)
  }
  return undefined;
};

export const clearUserData = () => {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem("tk");
    localStorage.removeItem("user_id");
    window.location.href = "/";
  }
  return undefined;
};

export const getUserData = () => {
  if (typeof localStorage !== "undefined") {
    const userData = localStorage.getItem("tk");
    if (userData) {
      return JSON.parse(userData);
    }
  }
  return undefined;
};
