import axios from '../utils/axios';

import {fetchOrganizationOrganizationListStart, fetchOrganizationOrganizationListSuccess, fetchOrganizationOrganizationListFailure} from '../features/organization'
import {fetchOrganizationOrganizationItemStart, fetchOrganizationOrganizationItemSuccess, fetchOrganizationOrganizationItemFailure} from '../features/organization'
import {createOrganizationOrganizationItemStart, createOrganizationOrganizationItemSuccess, createOrganizationOrganizationItemFailure} from '../features/organization'
import {updateOrganizationOrganizationItemStart, updateOrganizationOrganizationItemSuccess, updateOrganizationOrganizationItemFailure} from '../features/organization'
import {deleteOrganizationOrganizationItemStart, deleteOrganizationOrganizationItemSuccess, deleteOrganizationOrganizationItemFailure} from '../features/organization'


// ORGANIZATION ACTIONS
export const fetchOrganizationOrganizationList = async (dispatch, info, key) => {
    try{
        dispatch(fetchOrganizationOrganizationListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/organization/organization/`, { params: info.params, headers: info.headers });
        dispatch(fetchOrganizationOrganizationListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchOrganizationOrganizationListFailure({key, error: error.message}));
    }
}
export const fetchOrganizationOrganizationItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchOrganizationOrganizationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/organization/organization/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchOrganizationOrganizationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchOrganizationOrganizationItemFailure({key, error: error.message}));
    }
}
export const createOrganizationOrganizationItem = async (dispatch, info, key) => {
    try{
        dispatch(createOrganizationOrganizationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/organization/organization/`, info.payload, { headers: info.headers });
        dispatch(createOrganizationOrganizationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createOrganizationOrganizationItemFailure({key, error: error.message}));
    }
}
export const updateOrganizationOrganizationItem = async (dispatch, info, key) => {
    try{
        dispatch(updateOrganizationOrganizationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/organization/organization/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateOrganizationOrganizationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateOrganizationOrganizationItemFailure({key, error: error.message}));
    }
}
export const deleteOrganizationOrganizationItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteOrganizationOrganizationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/organization/organization/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteOrganizationOrganizationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteOrganizationOrganizationItemFailure({key, error: error.message}));
    }
}




