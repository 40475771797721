import axios from '../utils/axios';

import {fetchResearchandarticleCategoryListStart, fetchResearchandarticleCategoryListSuccess, fetchResearchandarticleCategoryListFailure} from '../features/researchandarticle'
import {fetchResearchandarticleCategoryItemStart, fetchResearchandarticleCategoryItemSuccess, fetchResearchandarticleCategoryItemFailure} from '../features/researchandarticle'
import {createResearchandarticleCategoryItemStart, createResearchandarticleCategoryItemSuccess, createResearchandarticleCategoryItemFailure} from '../features/researchandarticle'
import {updateResearchandarticleCategoryItemStart, updateResearchandarticleCategoryItemSuccess, updateResearchandarticleCategoryItemFailure} from '../features/researchandarticle'
import {deleteResearchandarticleCategoryItemStart, deleteResearchandarticleCategoryItemSuccess, deleteResearchandarticleCategoryItemFailure} from '../features/researchandarticle'

import {fetchResearchandarticleResearchpaperListStart, fetchResearchandarticleResearchpaperListSuccess, fetchResearchandarticleResearchpaperListFailure} from '../features/researchandarticle'
import {fetchResearchandarticleResearchpaperItemStart, fetchResearchandarticleResearchpaperItemSuccess, fetchResearchandarticleResearchpaperItemFailure} from '../features/researchandarticle'
import {createResearchandarticleResearchpaperItemStart, createResearchandarticleResearchpaperItemSuccess, createResearchandarticleResearchpaperItemFailure} from '../features/researchandarticle'
import {updateResearchandarticleResearchpaperItemStart, updateResearchandarticleResearchpaperItemSuccess, updateResearchandarticleResearchpaperItemFailure} from '../features/researchandarticle'
import {deleteResearchandarticleResearchpaperItemStart, deleteResearchandarticleResearchpaperItemSuccess, deleteResearchandarticleResearchpaperItemFailure} from '../features/researchandarticle'

import {fetchResearchandarticleArticleListStart, fetchResearchandarticleArticleListSuccess, fetchResearchandarticleArticleListFailure} from '../features/researchandarticle'
import {fetchResearchandarticleArticleItemStart, fetchResearchandarticleArticleItemSuccess, fetchResearchandarticleArticleItemFailure} from '../features/researchandarticle'
import {createResearchandarticleArticleItemStart, createResearchandarticleArticleItemSuccess, createResearchandarticleArticleItemFailure} from '../features/researchandarticle'
import {updateResearchandarticleArticleItemStart, updateResearchandarticleArticleItemSuccess, updateResearchandarticleArticleItemFailure} from '../features/researchandarticle'
import {deleteResearchandarticleArticleItemStart, deleteResearchandarticleArticleItemSuccess, deleteResearchandarticleArticleItemFailure} from '../features/researchandarticle'

import {fetchResearchandarticleCommentListStart, fetchResearchandarticleCommentListSuccess, fetchResearchandarticleCommentListFailure} from '../features/researchandarticle'
import {fetchResearchandarticleCommentItemStart, fetchResearchandarticleCommentItemSuccess, fetchResearchandarticleCommentItemFailure} from '../features/researchandarticle'
import {createResearchandarticleCommentItemStart, createResearchandarticleCommentItemSuccess, createResearchandarticleCommentItemFailure} from '../features/researchandarticle'
import {updateResearchandarticleCommentItemStart, updateResearchandarticleCommentItemSuccess, updateResearchandarticleCommentItemFailure} from '../features/researchandarticle'
import {deleteResearchandarticleCommentItemStart, deleteResearchandarticleCommentItemSuccess, deleteResearchandarticleCommentItemFailure} from '../features/researchandarticle'


// CATEGORY ACTIONS
export const fetchResearchandarticleCategoryList = async (dispatch, info, key) => {
    try{
        dispatch(fetchResearchandarticleCategoryListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/researchandarticle/category/`, { params: info.params, headers: info.headers });
        dispatch(fetchResearchandarticleCategoryListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchResearchandarticleCategoryListFailure({key, error: error.message}));
    }
}
export const fetchResearchandarticleCategoryItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchResearchandarticleCategoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/researchandarticle/category/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchResearchandarticleCategoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchResearchandarticleCategoryItemFailure({key, error: error.message}));
    }
}
export const createResearchandarticleCategoryItem = async (dispatch, info, key) => {
    try{
        dispatch(createResearchandarticleCategoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/researchandarticle/category/`, info.payload, { headers: info.headers });
        dispatch(createResearchandarticleCategoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createResearchandarticleCategoryItemFailure({key, error: error.message}));
    }
}
export const updateResearchandarticleCategoryItem = async (dispatch, info, key) => {
    try{
        dispatch(updateResearchandarticleCategoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/researchandarticle/category/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateResearchandarticleCategoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateResearchandarticleCategoryItemFailure({key, error: error.message}));
    }
}
export const deleteResearchandarticleCategoryItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteResearchandarticleCategoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/researchandarticle/category/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteResearchandarticleCategoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteResearchandarticleCategoryItemFailure({key, error: error.message}));
    }
}


// RESEARCHPAPER ACTIONS
export const fetchResearchandarticleResearchpaperList = async (dispatch, info, key) => {
    try{
        dispatch(fetchResearchandarticleResearchpaperListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/researchandarticle/researchpaper/`, { params: info.params, headers: info.headers });
        dispatch(fetchResearchandarticleResearchpaperListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchResearchandarticleResearchpaperListFailure({key, error: error.message}));
    }
}
export const fetchResearchandarticleResearchpaperItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchResearchandarticleResearchpaperItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/researchandarticle/researchpaper/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchResearchandarticleResearchpaperItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchResearchandarticleResearchpaperItemFailure({key, error: error.message}));
    }
}
export const createResearchandarticleResearchpaperItem = async (dispatch, info, key) => {
    try{
        dispatch(createResearchandarticleResearchpaperItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/researchandarticle/researchpaper/`, info.payload, { headers: info.headers });
        dispatch(createResearchandarticleResearchpaperItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createResearchandarticleResearchpaperItemFailure({key, error: error.message}));
    }
}
export const updateResearchandarticleResearchpaperItem = async (dispatch, info, key) => {
    try{
        dispatch(updateResearchandarticleResearchpaperItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/researchandarticle/researchpaper/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateResearchandarticleResearchpaperItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateResearchandarticleResearchpaperItemFailure({key, error: error.message}));
    }
}
export const deleteResearchandarticleResearchpaperItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteResearchandarticleResearchpaperItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/researchandarticle/researchpaper/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteResearchandarticleResearchpaperItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteResearchandarticleResearchpaperItemFailure({key, error: error.message}));
    }
}


// ARTICLE ACTIONS
export const fetchResearchandarticleArticleList = async (dispatch, info, key) => {
    try{
        dispatch(fetchResearchandarticleArticleListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/researchandarticle/article/`, { params: info.params, headers: info.headers });
        dispatch(fetchResearchandarticleArticleListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchResearchandarticleArticleListFailure({key, error: error.message}));
    }
}
export const fetchResearchandarticleArticleItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchResearchandarticleArticleItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/researchandarticle/article/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchResearchandarticleArticleItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchResearchandarticleArticleItemFailure({key, error: error.message}));
    }
}
export const createResearchandarticleArticleItem = async (dispatch, info, key) => {
    try{
        dispatch(createResearchandarticleArticleItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/researchandarticle/article/`, info.payload, { headers: info.headers });
        dispatch(createResearchandarticleArticleItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createResearchandarticleArticleItemFailure({key, error: error.message}));
    }
}
export const updateResearchandarticleArticleItem = async (dispatch, info, key) => {
    try{
        dispatch(updateResearchandarticleArticleItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/researchandarticle/article/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateResearchandarticleArticleItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateResearchandarticleArticleItemFailure({key, error: error.message}));
    }
}
export const deleteResearchandarticleArticleItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteResearchandarticleArticleItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/researchandarticle/article/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteResearchandarticleArticleItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteResearchandarticleArticleItemFailure({key, error: error.message}));
    }
}


// COMMENT ACTIONS
export const fetchResearchandarticleCommentList = async (dispatch, info, key) => {
    try{
        dispatch(fetchResearchandarticleCommentListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/researchandarticle/comment/`, { params: info.params, headers: info.headers });
        dispatch(fetchResearchandarticleCommentListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchResearchandarticleCommentListFailure({key, error: error.message}));
    }
}
export const fetchResearchandarticleCommentItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchResearchandarticleCommentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/researchandarticle/comment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchResearchandarticleCommentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchResearchandarticleCommentItemFailure({key, error: error.message}));
    }
}
export const createResearchandarticleCommentItem = async (dispatch, info, key) => {
    try{
        dispatch(createResearchandarticleCommentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/researchandarticle/comment/`, info.payload, { headers: info.headers });
        dispatch(createResearchandarticleCommentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createResearchandarticleCommentItemFailure({key, error: error.message}));
    }
}
export const updateResearchandarticleCommentItem = async (dispatch, info, key) => {
    try{
        dispatch(updateResearchandarticleCommentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/researchandarticle/comment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateResearchandarticleCommentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateResearchandarticleCommentItemFailure({key, error: error.message}));
    }
}
export const deleteResearchandarticleCommentItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteResearchandarticleCommentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/researchandarticle/comment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteResearchandarticleCommentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteResearchandarticleCommentItemFailure({key, error: error.message}));
    }
}




