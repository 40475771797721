import axios from '../utils/axios';

import {fetchForumPostListStart, fetchForumPostListSuccess, fetchForumPostListFailure} from '../features/forum'
import {fetchForumPostItemStart, fetchForumPostItemSuccess, fetchForumPostItemFailure} from '../features/forum'
import {createForumPostItemStart, createForumPostItemSuccess, createForumPostItemFailure} from '../features/forum'
import {updateForumPostItemStart, updateForumPostItemSuccess, updateForumPostItemFailure} from '../features/forum'
import {deleteForumPostItemStart, deleteForumPostItemSuccess, deleteForumPostItemFailure} from '../features/forum'


// POST ACTIONS
export const fetchForumPostList = async (dispatch, info, key) => {
    try{
        dispatch(fetchForumPostListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/forum/post/`, { params: info.params, headers: info.headers });
        dispatch(fetchForumPostListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchForumPostListFailure({key, error: error.message}));
    }
}
export const fetchForumPostItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchForumPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/forum/post/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchForumPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchForumPostItemFailure({key, error: error.message}));
    }
}
export const createForumPostItem = async (dispatch, info, key) => {
    try{
        dispatch(createForumPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/forum/post/`, info.payload, { headers: info.headers });
        dispatch(createForumPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createForumPostItemFailure({key, error: error.message}));
    }
}
export const updateForumPostItem = async (dispatch, info, key) => {
    try{
        dispatch(updateForumPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/forum/post/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateForumPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateForumPostItemFailure({key, error: error.message}));
    }
}
export const deleteForumPostItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteForumPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/forum/post/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteForumPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteForumPostItemFailure({key, error: error.message}));
    }
}




