import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'community',
  initialState: { loading: false },
  reducers: {
    
    // FOLLOW REDUCER FUNCTION

    // FOLLOW FETCH LIST
    fetchCommunityFollowListStart: stateLoadingStart,
    fetchCommunityFollowListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `follow_list`),
    fetchCommunityFollowListFailure: stateError,

    // FOLLOW FETCH ITEM
    fetchCommunityFollowItemStart: stateLoadingStart,
    fetchCommunityFollowItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `follow_item`),
    fetchCommunityFollowItemFailure: stateError,

    // FOLLOW CREATE ITEM
    createCommunityFollowItemStart: stateLoadingStart,
    createCommunityFollowItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `follow_item`),
    createCommunityFollowItemFailure: stateError,

    // FOLLOW UPDATE ITEM
    updateCommunityFollowItemStart: stateLoadingStart,
    updateCommunityFollowItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `follow_item`),
    updateCommunityFollowItemFailure: stateError,

    // FOLLOW DELETE ITEM
    deleteCommunityFollowItemStart: stateLoadingStart,
    deleteCommunityFollowItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_follow`),
    deleteCommunityFollowItemFailure: stateError,


    // GENERALPOST REDUCER FUNCTION

    // GENERALPOST FETCH LIST
    fetchCommunityGeneralpostListStart: stateLoadingStart,
    fetchCommunityGeneralpostListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `generalpost_list`),
    fetchCommunityGeneralpostListFailure: stateError,

    // GENERALPOST FETCH ITEM
    fetchCommunityGeneralpostItemStart: stateLoadingStart,
    fetchCommunityGeneralpostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `generalpost_item`),
    fetchCommunityGeneralpostItemFailure: stateError,

    // GENERALPOST CREATE ITEM
    createCommunityGeneralpostItemStart: stateLoadingStart,
    createCommunityGeneralpostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `generalpost_item`),
    createCommunityGeneralpostItemFailure: stateError,

    // GENERALPOST UPDATE ITEM
    updateCommunityGeneralpostItemStart: stateLoadingStart,
    updateCommunityGeneralpostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `generalpost_item`),
    updateCommunityGeneralpostItemFailure: stateError,

    // GENERALPOST DELETE ITEM
    deleteCommunityGeneralpostItemStart: stateLoadingStart,
    deleteCommunityGeneralpostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_generalpost`),
    deleteCommunityGeneralpostItemFailure: stateError,


    // POST REDUCER FUNCTION

    // POST FETCH LIST
    fetchCommunityPostListStart: stateLoadingStart,
    fetchCommunityPostListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_list`),
    fetchCommunityPostListFailure: stateError,

    // POST FETCH ITEM
    fetchCommunityPostItemStart: stateLoadingStart,
    fetchCommunityPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_item`),
    fetchCommunityPostItemFailure: stateError,

    // POST CREATE ITEM
    createCommunityPostItemStart: stateLoadingStart,
    createCommunityPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_item`),
    createCommunityPostItemFailure: stateError,

    // POST UPDATE ITEM
    updateCommunityPostItemStart: stateLoadingStart,
    updateCommunityPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_item`),
    updateCommunityPostItemFailure: stateError,

    // POST DELETE ITEM
    deleteCommunityPostItemStart: stateLoadingStart,
    deleteCommunityPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_post`),
    deleteCommunityPostItemFailure: stateError,


    // COMMENT REDUCER FUNCTION

    // COMMENT FETCH LIST
    fetchCommunityCommentListStart: stateLoadingStart,
    fetchCommunityCommentListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `comment_list`),
    fetchCommunityCommentListFailure: stateError,

    // COMMENT FETCH ITEM
    fetchCommunityCommentItemStart: stateLoadingStart,
    fetchCommunityCommentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `comment_item`),
    fetchCommunityCommentItemFailure: stateError,

    // COMMENT CREATE ITEM
    createCommunityCommentItemStart: stateLoadingStart,
    createCommunityCommentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `comment_item`),
    createCommunityCommentItemFailure: stateError,

    // COMMENT UPDATE ITEM
    updateCommunityCommentItemStart: stateLoadingStart,
    updateCommunityCommentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `comment_item`),
    updateCommunityCommentItemFailure: stateError,

    // COMMENT DELETE ITEM
    deleteCommunityCommentItemStart: stateLoadingStart,
    deleteCommunityCommentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_comment`),
    deleteCommunityCommentItemFailure: stateError,


    // THREAD REDUCER FUNCTION

    // THREAD FETCH LIST
    fetchCommunityThreadListStart: stateLoadingStart,
    fetchCommunityThreadListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `thread_list`),
    fetchCommunityThreadListFailure: stateError,

    // THREAD FETCH ITEM
    fetchCommunityThreadItemStart: stateLoadingStart,
    fetchCommunityThreadItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `thread_item`),
    fetchCommunityThreadItemFailure: stateError,

    // THREAD CREATE ITEM
    createCommunityThreadItemStart: stateLoadingStart,
    createCommunityThreadItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `thread_item`),
    createCommunityThreadItemFailure: stateError,

    // THREAD UPDATE ITEM
    updateCommunityThreadItemStart: stateLoadingStart,
    updateCommunityThreadItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `thread_item`),
    updateCommunityThreadItemFailure: stateError,

    // THREAD DELETE ITEM
    deleteCommunityThreadItemStart: stateLoadingStart,
    deleteCommunityThreadItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_thread`),
    deleteCommunityThreadItemFailure: stateError,


    // LIKE REDUCER FUNCTION

    // LIKE FETCH LIST
    fetchCommunityLikeListStart: stateLoadingStart,
    fetchCommunityLikeListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `like_list`),
    fetchCommunityLikeListFailure: stateError,

    // LIKE FETCH ITEM
    fetchCommunityLikeItemStart: stateLoadingStart,
    fetchCommunityLikeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `like_item`),
    fetchCommunityLikeItemFailure: stateError,

    // LIKE CREATE ITEM
    createCommunityLikeItemStart: stateLoadingStart,
    createCommunityLikeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `like_item`),
    createCommunityLikeItemFailure: stateError,

    // LIKE UPDATE ITEM
    updateCommunityLikeItemStart: stateLoadingStart,
    updateCommunityLikeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `like_item`),
    updateCommunityLikeItemFailure: stateError,

    // LIKE DELETE ITEM
    deleteCommunityLikeItemStart: stateLoadingStart,
    deleteCommunityLikeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_like`),
    deleteCommunityLikeItemFailure: stateError,


  },
});

export const {
  fetchCommunityFollowListStart, fetchCommunityGeneralpostListStart, fetchCommunityPostListStart, fetchCommunityCommentListStart, fetchCommunityThreadListStart, fetchCommunityLikeListStart,
  fetchCommunityFollowListSuccess, fetchCommunityGeneralpostListSuccess, fetchCommunityPostListSuccess, fetchCommunityCommentListSuccess, fetchCommunityThreadListSuccess, fetchCommunityLikeListSuccess,
  fetchCommunityFollowListFailure, fetchCommunityGeneralpostListFailure, fetchCommunityPostListFailure, fetchCommunityCommentListFailure, fetchCommunityThreadListFailure, fetchCommunityLikeListFailure,
  fetchCommunityFollowItemStart, fetchCommunityGeneralpostItemStart, fetchCommunityPostItemStart, fetchCommunityCommentItemStart, fetchCommunityThreadItemStart, fetchCommunityLikeItemStart,
  fetchCommunityFollowItemSuccess, fetchCommunityGeneralpostItemSuccess, fetchCommunityPostItemSuccess, fetchCommunityCommentItemSuccess, fetchCommunityThreadItemSuccess, fetchCommunityLikeItemSuccess,
  fetchCommunityFollowItemFailure, fetchCommunityGeneralpostItemFailure, fetchCommunityPostItemFailure, fetchCommunityCommentItemFailure, fetchCommunityThreadItemFailure, fetchCommunityLikeItemFailure,
  createCommunityFollowItemStart, createCommunityGeneralpostItemStart, createCommunityPostItemStart, createCommunityCommentItemStart, createCommunityThreadItemStart, createCommunityLikeItemStart,
  createCommunityFollowItemSuccess, createCommunityGeneralpostItemSuccess, createCommunityPostItemSuccess, createCommunityCommentItemSuccess, createCommunityThreadItemSuccess, createCommunityLikeItemSuccess,
  createCommunityFollowItemFailure, createCommunityGeneralpostItemFailure, createCommunityPostItemFailure, createCommunityCommentItemFailure, createCommunityThreadItemFailure, createCommunityLikeItemFailure,
  updateCommunityFollowItemStart, updateCommunityGeneralpostItemStart, updateCommunityPostItemStart, updateCommunityCommentItemStart, updateCommunityThreadItemStart, updateCommunityLikeItemStart,
  updateCommunityFollowItemSuccess, updateCommunityGeneralpostItemSuccess, updateCommunityPostItemSuccess, updateCommunityCommentItemSuccess, updateCommunityThreadItemSuccess, updateCommunityLikeItemSuccess,
  updateCommunityFollowItemFailure, updateCommunityGeneralpostItemFailure, updateCommunityPostItemFailure, updateCommunityCommentItemFailure, updateCommunityThreadItemFailure, updateCommunityLikeItemFailure,
  deleteCommunityFollowItemStart, deleteCommunityGeneralpostItemStart, deleteCommunityPostItemStart, deleteCommunityCommentItemStart, deleteCommunityThreadItemStart, deleteCommunityLikeItemStart,
  deleteCommunityFollowItemSuccess, deleteCommunityGeneralpostItemSuccess, deleteCommunityPostItemSuccess, deleteCommunityCommentItemSuccess, deleteCommunityThreadItemSuccess, deleteCommunityLikeItemSuccess,
  deleteCommunityFollowItemFailure, deleteCommunityGeneralpostItemFailure, deleteCommunityPostItemFailure, deleteCommunityCommentItemFailure, deleteCommunityThreadItemFailure, deleteCommunityLikeItemFailure
} = slice.actions;

export default slice.reducer;
