import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'blog',
  initialState: { loading: false },
  reducers: {
    
    // AVAILABLETAG REDUCER FUNCTION

    // AVAILABLETAG FETCH LIST
    fetchBlogAvailabletagListStart: stateLoadingStart,
    fetchBlogAvailabletagListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `availabletag_list`),
    fetchBlogAvailabletagListFailure: stateError,

    // AVAILABLETAG FETCH ITEM
    fetchBlogAvailabletagItemStart: stateLoadingStart,
    fetchBlogAvailabletagItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `availabletag_item`),
    fetchBlogAvailabletagItemFailure: stateError,

    // AVAILABLETAG CREATE ITEM
    createBlogAvailabletagItemStart: stateLoadingStart,
    createBlogAvailabletagItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `availabletag_item`),
    createBlogAvailabletagItemFailure: stateError,

    // AVAILABLETAG UPDATE ITEM
    updateBlogAvailabletagItemStart: stateLoadingStart,
    updateBlogAvailabletagItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `availabletag_item`),
    updateBlogAvailabletagItemFailure: stateError,

    // AVAILABLETAG DELETE ITEM
    deleteBlogAvailabletagItemStart: stateLoadingStart,
    deleteBlogAvailabletagItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_availabletag`),
    deleteBlogAvailabletagItemFailure: stateError,


    // POST REDUCER FUNCTION

    // POST FETCH LIST
    fetchBlogPostListStart: stateLoadingStart,
    fetchBlogPostListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_list`),
    fetchBlogPostListFailure: stateError,

    // POST FETCH ITEM
    fetchBlogPostItemStart: stateLoadingStart,
    fetchBlogPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_item`),
    fetchBlogPostItemFailure: stateError,

    // POST CREATE ITEM
    createBlogPostItemStart: stateLoadingStart,
    createBlogPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_item`),
    createBlogPostItemFailure: stateError,

    // POST UPDATE ITEM
    updateBlogPostItemStart: stateLoadingStart,
    updateBlogPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `post_item`),
    updateBlogPostItemFailure: stateError,

    // POST DELETE ITEM
    deleteBlogPostItemStart: stateLoadingStart,
    deleteBlogPostItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_post`),
    deleteBlogPostItemFailure: stateError,


  },
});

export const {
  fetchBlogAvailabletagListStart, fetchBlogPostListStart,
  fetchBlogAvailabletagListSuccess, fetchBlogPostListSuccess,
  fetchBlogAvailabletagListFailure, fetchBlogPostListFailure,
  fetchBlogAvailabletagItemStart, fetchBlogPostItemStart,
  fetchBlogAvailabletagItemSuccess, fetchBlogPostItemSuccess,
  fetchBlogAvailabletagItemFailure, fetchBlogPostItemFailure,
  createBlogAvailabletagItemStart, createBlogPostItemStart,
  createBlogAvailabletagItemSuccess, createBlogPostItemSuccess,
  createBlogAvailabletagItemFailure, createBlogPostItemFailure,
  updateBlogAvailabletagItemStart, updateBlogPostItemStart,
  updateBlogAvailabletagItemSuccess, updateBlogPostItemSuccess,
  updateBlogAvailabletagItemFailure, updateBlogPostItemFailure,
  deleteBlogAvailabletagItemStart, deleteBlogPostItemStart,
  deleteBlogAvailabletagItemSuccess, deleteBlogPostItemSuccess,
  deleteBlogAvailabletagItemFailure, deleteBlogPostItemFailure
} = slice.actions;

export default slice.reducer;
