import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'researchandarticle',
  initialState: { loading: false },
  reducers: {
    
    // CATEGORY REDUCER FUNCTION

    // CATEGORY FETCH LIST
    fetchResearchandarticleCategoryListStart: stateLoadingStart,
    fetchResearchandarticleCategoryListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_list`),
    fetchResearchandarticleCategoryListFailure: stateError,

    // CATEGORY FETCH ITEM
    fetchResearchandarticleCategoryItemStart: stateLoadingStart,
    fetchResearchandarticleCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    fetchResearchandarticleCategoryItemFailure: stateError,

    // CATEGORY CREATE ITEM
    createResearchandarticleCategoryItemStart: stateLoadingStart,
    createResearchandarticleCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    createResearchandarticleCategoryItemFailure: stateError,

    // CATEGORY UPDATE ITEM
    updateResearchandarticleCategoryItemStart: stateLoadingStart,
    updateResearchandarticleCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    updateResearchandarticleCategoryItemFailure: stateError,

    // CATEGORY DELETE ITEM
    deleteResearchandarticleCategoryItemStart: stateLoadingStart,
    deleteResearchandarticleCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_category`),
    deleteResearchandarticleCategoryItemFailure: stateError,


    // RESEARCHPAPER REDUCER FUNCTION

    // RESEARCHPAPER FETCH LIST
    fetchResearchandarticleResearchpaperListStart: stateLoadingStart,
    fetchResearchandarticleResearchpaperListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `researchpaper_list`),
    fetchResearchandarticleResearchpaperListFailure: stateError,

    // RESEARCHPAPER FETCH ITEM
    fetchResearchandarticleResearchpaperItemStart: stateLoadingStart,
    fetchResearchandarticleResearchpaperItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `researchpaper_item`),
    fetchResearchandarticleResearchpaperItemFailure: stateError,

    // RESEARCHPAPER CREATE ITEM
    createResearchandarticleResearchpaperItemStart: stateLoadingStart,
    createResearchandarticleResearchpaperItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `researchpaper_item`),
    createResearchandarticleResearchpaperItemFailure: stateError,

    // RESEARCHPAPER UPDATE ITEM
    updateResearchandarticleResearchpaperItemStart: stateLoadingStart,
    updateResearchandarticleResearchpaperItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `researchpaper_item`),
    updateResearchandarticleResearchpaperItemFailure: stateError,

    // RESEARCHPAPER DELETE ITEM
    deleteResearchandarticleResearchpaperItemStart: stateLoadingStart,
    deleteResearchandarticleResearchpaperItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_researchpaper`),
    deleteResearchandarticleResearchpaperItemFailure: stateError,


    // ARTICLE REDUCER FUNCTION

    // ARTICLE FETCH LIST
    fetchResearchandarticleArticleListStart: stateLoadingStart,
    fetchResearchandarticleArticleListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `article_list`),
    fetchResearchandarticleArticleListFailure: stateError,

    // ARTICLE FETCH ITEM
    fetchResearchandarticleArticleItemStart: stateLoadingStart,
    fetchResearchandarticleArticleItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `article_item`),
    fetchResearchandarticleArticleItemFailure: stateError,

    // ARTICLE CREATE ITEM
    createResearchandarticleArticleItemStart: stateLoadingStart,
    createResearchandarticleArticleItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `article_item`),
    createResearchandarticleArticleItemFailure: stateError,

    // ARTICLE UPDATE ITEM
    updateResearchandarticleArticleItemStart: stateLoadingStart,
    updateResearchandarticleArticleItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `article_item`),
    updateResearchandarticleArticleItemFailure: stateError,

    // ARTICLE DELETE ITEM
    deleteResearchandarticleArticleItemStart: stateLoadingStart,
    deleteResearchandarticleArticleItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_article`),
    deleteResearchandarticleArticleItemFailure: stateError,


    // COMMENT REDUCER FUNCTION

    // COMMENT FETCH LIST
    fetchResearchandarticleCommentListStart: stateLoadingStart,
    fetchResearchandarticleCommentListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `comment_list`),
    fetchResearchandarticleCommentListFailure: stateError,

    // COMMENT FETCH ITEM
    fetchResearchandarticleCommentItemStart: stateLoadingStart,
    fetchResearchandarticleCommentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `comment_item`),
    fetchResearchandarticleCommentItemFailure: stateError,

    // COMMENT CREATE ITEM
    createResearchandarticleCommentItemStart: stateLoadingStart,
    createResearchandarticleCommentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `comment_item`),
    createResearchandarticleCommentItemFailure: stateError,

    // COMMENT UPDATE ITEM
    updateResearchandarticleCommentItemStart: stateLoadingStart,
    updateResearchandarticleCommentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `comment_item`),
    updateResearchandarticleCommentItemFailure: stateError,

    // COMMENT DELETE ITEM
    deleteResearchandarticleCommentItemStart: stateLoadingStart,
    deleteResearchandarticleCommentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_comment`),
    deleteResearchandarticleCommentItemFailure: stateError,


  },
});

export const {
  fetchResearchandarticleCategoryListStart, fetchResearchandarticleResearchpaperListStart, fetchResearchandarticleArticleListStart, fetchResearchandarticleCommentListStart,
  fetchResearchandarticleCategoryListSuccess, fetchResearchandarticleResearchpaperListSuccess, fetchResearchandarticleArticleListSuccess, fetchResearchandarticleCommentListSuccess,
  fetchResearchandarticleCategoryListFailure, fetchResearchandarticleResearchpaperListFailure, fetchResearchandarticleArticleListFailure, fetchResearchandarticleCommentListFailure,
  fetchResearchandarticleCategoryItemStart, fetchResearchandarticleResearchpaperItemStart, fetchResearchandarticleArticleItemStart, fetchResearchandarticleCommentItemStart,
  fetchResearchandarticleCategoryItemSuccess, fetchResearchandarticleResearchpaperItemSuccess, fetchResearchandarticleArticleItemSuccess, fetchResearchandarticleCommentItemSuccess,
  fetchResearchandarticleCategoryItemFailure, fetchResearchandarticleResearchpaperItemFailure, fetchResearchandarticleArticleItemFailure, fetchResearchandarticleCommentItemFailure,
  createResearchandarticleCategoryItemStart, createResearchandarticleResearchpaperItemStart, createResearchandarticleArticleItemStart, createResearchandarticleCommentItemStart,
  createResearchandarticleCategoryItemSuccess, createResearchandarticleResearchpaperItemSuccess, createResearchandarticleArticleItemSuccess, createResearchandarticleCommentItemSuccess,
  createResearchandarticleCategoryItemFailure, createResearchandarticleResearchpaperItemFailure, createResearchandarticleArticleItemFailure, createResearchandarticleCommentItemFailure,
  updateResearchandarticleCategoryItemStart, updateResearchandarticleResearchpaperItemStart, updateResearchandarticleArticleItemStart, updateResearchandarticleCommentItemStart,
  updateResearchandarticleCategoryItemSuccess, updateResearchandarticleResearchpaperItemSuccess, updateResearchandarticleArticleItemSuccess, updateResearchandarticleCommentItemSuccess,
  updateResearchandarticleCategoryItemFailure, updateResearchandarticleResearchpaperItemFailure, updateResearchandarticleArticleItemFailure, updateResearchandarticleCommentItemFailure,
  deleteResearchandarticleCategoryItemStart, deleteResearchandarticleResearchpaperItemStart, deleteResearchandarticleArticleItemStart, deleteResearchandarticleCommentItemStart,
  deleteResearchandarticleCategoryItemSuccess, deleteResearchandarticleResearchpaperItemSuccess, deleteResearchandarticleArticleItemSuccess, deleteResearchandarticleCommentItemSuccess,
  deleteResearchandarticleCategoryItemFailure, deleteResearchandarticleResearchpaperItemFailure, deleteResearchandarticleArticleItemFailure, deleteResearchandarticleCommentItemFailure
} = slice.actions;

export default slice.reducer;
