import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'event',
  initialState: { loading: false },
  reducers: {
    
    // EVENT REDUCER FUNCTION

    // EVENT FETCH LIST
    fetchEventEventListStart: stateLoadingStart,
    fetchEventEventListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `event_list`),
    fetchEventEventListFailure: stateError,

    // EVENT FETCH ITEM
    fetchEventEventItemStart: stateLoadingStart,
    fetchEventEventItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `event_item`),
    fetchEventEventItemFailure: stateError,

    // EVENT CREATE ITEM
    createEventEventItemStart: stateLoadingStart,
    createEventEventItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `event_item`),
    createEventEventItemFailure: stateError,

    // EVENT UPDATE ITEM
    updateEventEventItemStart: stateLoadingStart,
    updateEventEventItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `event_item`),
    updateEventEventItemFailure: stateError,

    // EVENT DELETE ITEM
    deleteEventEventItemStart: stateLoadingStart,
    deleteEventEventItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_event`),
    deleteEventEventItemFailure: stateError,


    // TICKET REDUCER FUNCTION

    // TICKET FETCH LIST
    fetchEventTicketListStart: stateLoadingStart,
    fetchEventTicketListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ticket_list`),
    fetchEventTicketListFailure: stateError,

    // TICKET FETCH ITEM
    fetchEventTicketItemStart: stateLoadingStart,
    fetchEventTicketItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ticket_item`),
    fetchEventTicketItemFailure: stateError,

    // TICKET CREATE ITEM
    createEventTicketItemStart: stateLoadingStart,
    createEventTicketItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ticket_item`),
    createEventTicketItemFailure: stateError,

    // TICKET UPDATE ITEM
    updateEventTicketItemStart: stateLoadingStart,
    updateEventTicketItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ticket_item`),
    updateEventTicketItemFailure: stateError,

    // TICKET DELETE ITEM
    deleteEventTicketItemStart: stateLoadingStart,
    deleteEventTicketItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_ticket`),
    deleteEventTicketItemFailure: stateError,


  },
});

export const {
  fetchEventEventListStart, fetchEventTicketListStart,
  fetchEventEventListSuccess, fetchEventTicketListSuccess,
  fetchEventEventListFailure, fetchEventTicketListFailure,
  fetchEventEventItemStart, fetchEventTicketItemStart,
  fetchEventEventItemSuccess, fetchEventTicketItemSuccess,
  fetchEventEventItemFailure, fetchEventTicketItemFailure,
  createEventEventItemStart, createEventTicketItemStart,
  createEventEventItemSuccess, createEventTicketItemSuccess,
  createEventEventItemFailure, createEventTicketItemFailure,
  updateEventEventItemStart, updateEventTicketItemStart,
  updateEventEventItemSuccess, updateEventTicketItemSuccess,
  updateEventEventItemFailure, updateEventTicketItemFailure,
  deleteEventEventItemStart, deleteEventTicketItemStart,
  deleteEventEventItemSuccess, deleteEventTicketItemSuccess,
  deleteEventEventItemFailure, deleteEventTicketItemFailure
} = slice.actions;

export default slice.reducer;
