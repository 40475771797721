import React, { useState, useEffect } from 'react';
import { Dimmer, Loader, Modal, Button } from 'semantic-ui-react';
import axios from 'axios';
import {setUserId} from 'modules/wd-user-auth/utils/storage';

function HandleTransitCode({baseURL}) {
  // const baseURL = "http://localhost:8000/"
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const axiosInstance = axios.create({
    baseURL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  useEffect(() => {
    const getTransitCodeFromURL = () => {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get('tc');
    };
    const transitCode = getTransitCodeFromURL();
    if (!transitCode) {
      setLoading(false);
      return;
    }
    axiosInstance.get(`/transit/transit-data/${transitCode}/`)
      .then((response) => {
        localStorage.setItem('tk', JSON.stringify(response.data))
        setUserId()
        window.location.href = window.location.origin + window.location.pathname;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <Modal open={loading} basic dimmer="inverted">
      <Dimmer active={loading} inverted>
        <Loader/>
      </Dimmer>
      <Modal.Content>
        {error ? (
          <p>Error: {error.message}</p>
        ) : (
          <p>Loading...</p>
        )}
      </Modal.Content>
    </Modal>
  );
}

export default HandleTransitCode;
