import axios from '../utils/axios';

import {fetchMeetConferenceListStart, fetchMeetConferenceListSuccess, fetchMeetConferenceListFailure} from '../features/meet'
import {fetchMeetConferenceItemStart, fetchMeetConferenceItemSuccess, fetchMeetConferenceItemFailure} from '../features/meet'
import {createMeetConferenceItemStart, createMeetConferenceItemSuccess, createMeetConferenceItemFailure} from '../features/meet'
import {updateMeetConferenceItemStart, updateMeetConferenceItemSuccess, updateMeetConferenceItemFailure} from '../features/meet'
import {deleteMeetConferenceItemStart, deleteMeetConferenceItemSuccess, deleteMeetConferenceItemFailure} from '../features/meet'

import {fetchMeetRoomListStart, fetchMeetRoomListSuccess, fetchMeetRoomListFailure} from '../features/meet'
import {fetchMeetRoomItemStart, fetchMeetRoomItemSuccess, fetchMeetRoomItemFailure} from '../features/meet'
import {createMeetRoomItemStart, createMeetRoomItemSuccess, createMeetRoomItemFailure} from '../features/meet'
import {updateMeetRoomItemStart, updateMeetRoomItemSuccess, updateMeetRoomItemFailure} from '../features/meet'
import {deleteMeetRoomItemStart, deleteMeetRoomItemSuccess, deleteMeetRoomItemFailure} from '../features/meet'

import {fetchMeetConferenceuserListStart, fetchMeetConferenceuserListSuccess, fetchMeetConferenceuserListFailure} from '../features/meet'
import {fetchMeetConferenceuserItemStart, fetchMeetConferenceuserItemSuccess, fetchMeetConferenceuserItemFailure} from '../features/meet'
import {createMeetConferenceuserItemStart, createMeetConferenceuserItemSuccess, createMeetConferenceuserItemFailure} from '../features/meet'
import {updateMeetConferenceuserItemStart, updateMeetConferenceuserItemSuccess, updateMeetConferenceuserItemFailure} from '../features/meet'
import {deleteMeetConferenceuserItemStart, deleteMeetConferenceuserItemSuccess, deleteMeetConferenceuserItemFailure} from '../features/meet'


// CONFERENCE ACTIONS
export const fetchMeetConferenceList = async (dispatch, info, key) => {
    try{
        dispatch(fetchMeetConferenceListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/meet/conference/`, { params: info.params, headers: info.headers });
        dispatch(fetchMeetConferenceListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMeetConferenceListFailure({key, error: error.message}));
    }
}
export const fetchMeetConferenceItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchMeetConferenceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/meet/conference/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchMeetConferenceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMeetConferenceItemFailure({key, error: error.message}));
    }
}
export const createMeetConferenceItem = async (dispatch, info, key) => {
    try{
        dispatch(createMeetConferenceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/meet/conference/`, info.payload, { headers: info.headers });
        dispatch(createMeetConferenceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createMeetConferenceItemFailure({key, error: error.message}));
    }
}
export const updateMeetConferenceItem = async (dispatch, info, key) => {
    try{
        dispatch(updateMeetConferenceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/meet/conference/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateMeetConferenceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateMeetConferenceItemFailure({key, error: error.message}));
    }
}
export const deleteMeetConferenceItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteMeetConferenceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/meet/conference/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteMeetConferenceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteMeetConferenceItemFailure({key, error: error.message}));
    }
}


// ROOM ACTIONS
export const fetchMeetRoomList = async (dispatch, info, key) => {
    try{
        dispatch(fetchMeetRoomListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/meet/room/`, { params: info.params, headers: info.headers });
        dispatch(fetchMeetRoomListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMeetRoomListFailure({key, error: error.message}));
    }
}
export const fetchMeetRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchMeetRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/meet/room/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchMeetRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMeetRoomItemFailure({key, error: error.message}));
    }
}
export const createMeetRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(createMeetRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/meet/room/`, info.payload, { headers: info.headers });
        dispatch(createMeetRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createMeetRoomItemFailure({key, error: error.message}));
    }
}
export const updateMeetRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(updateMeetRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/meet/room/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateMeetRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateMeetRoomItemFailure({key, error: error.message}));
    }
}
export const deleteMeetRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteMeetRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/meet/room/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteMeetRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteMeetRoomItemFailure({key, error: error.message}));
    }
}


// CONFERENCEUSER ACTIONS
export const fetchMeetConferenceuserList = async (dispatch, info, key) => {
    try{
        dispatch(fetchMeetConferenceuserListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/meet/conferenceuser/`, { params: info.params, headers: info.headers });
        dispatch(fetchMeetConferenceuserListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMeetConferenceuserListFailure({key, error: error.message}));
    }
}
export const fetchMeetConferenceuserItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchMeetConferenceuserItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/meet/conferenceuser/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchMeetConferenceuserItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMeetConferenceuserItemFailure({key, error: error.message}));
    }
}
export const createMeetConferenceuserItem = async (dispatch, info, key) => {
    try{
        dispatch(createMeetConferenceuserItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/meet/conferenceuser/`, info.payload, { headers: info.headers });
        dispatch(createMeetConferenceuserItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createMeetConferenceuserItemFailure({key, error: error.message}));
    }
}
export const updateMeetConferenceuserItem = async (dispatch, info, key) => {
    try{
        dispatch(updateMeetConferenceuserItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/meet/conferenceuser/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateMeetConferenceuserItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateMeetConferenceuserItemFailure({key, error: error.message}));
    }
}
export const deleteMeetConferenceuserItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteMeetConferenceuserItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/meet/conferenceuser/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteMeetConferenceuserItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteMeetConferenceuserItemFailure({key, error: error.message}));
    }
}




