import axios from '../utils/axios';

import {fetchShowcaseResourceListStart, fetchShowcaseResourceListSuccess, fetchShowcaseResourceListFailure} from '../features/showcase'
import {fetchShowcaseResourceItemStart, fetchShowcaseResourceItemSuccess, fetchShowcaseResourceItemFailure} from '../features/showcase'
import {createShowcaseResourceItemStart, createShowcaseResourceItemSuccess, createShowcaseResourceItemFailure} from '../features/showcase'
import {updateShowcaseResourceItemStart, updateShowcaseResourceItemSuccess, updateShowcaseResourceItemFailure} from '../features/showcase'
import {deleteShowcaseResourceItemStart, deleteShowcaseResourceItemSuccess, deleteShowcaseResourceItemFailure} from '../features/showcase'


// RESOURCE ACTIONS
export const fetchShowcaseResourceList = async (dispatch, info, key) => {
    try{
        dispatch(fetchShowcaseResourceListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/showcase/resource/`, { params: info.params, headers: info.headers });
        dispatch(fetchShowcaseResourceListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchShowcaseResourceListFailure({key, error: error.message}));
    }
}
export const fetchShowcaseResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchShowcaseResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/showcase/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchShowcaseResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchShowcaseResourceItemFailure({key, error: error.message}));
    }
}
export const createShowcaseResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(createShowcaseResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/showcase/resource/`, info.payload, { headers: info.headers });
        dispatch(createShowcaseResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createShowcaseResourceItemFailure({key, error: error.message}));
    }
}
export const updateShowcaseResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(updateShowcaseResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/showcase/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateShowcaseResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateShowcaseResourceItemFailure({key, error: error.message}));
    }
}
export const deleteShowcaseResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteShowcaseResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/showcase/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteShowcaseResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteShowcaseResourceItemFailure({key, error: error.message}));
    }
}




