import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'note',
  initialState: { loading: false },
  reducers: {
    
    // NOTE REDUCER FUNCTION

    // NOTE FETCH LIST
    fetchNoteNoteListStart: stateLoadingStart,
    fetchNoteNoteListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `note_list`),
    fetchNoteNoteListFailure: stateError,

    // NOTE FETCH ITEM
    fetchNoteNoteItemStart: stateLoadingStart,
    fetchNoteNoteItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `note_item`),
    fetchNoteNoteItemFailure: stateError,

    // NOTE CREATE ITEM
    createNoteNoteItemStart: stateLoadingStart,
    createNoteNoteItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `note_item`),
    createNoteNoteItemFailure: stateError,

    // NOTE UPDATE ITEM
    updateNoteNoteItemStart: stateLoadingStart,
    updateNoteNoteItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `note_item`),
    updateNoteNoteItemFailure: stateError,

    // NOTE DELETE ITEM
    deleteNoteNoteItemStart: stateLoadingStart,
    deleteNoteNoteItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_note`),
    deleteNoteNoteItemFailure: stateError,


  },
});

export const {
  fetchNoteNoteListStart,
  fetchNoteNoteListSuccess,
  fetchNoteNoteListFailure,
  fetchNoteNoteItemStart,
  fetchNoteNoteItemSuccess,
  fetchNoteNoteItemFailure,
  createNoteNoteItemStart,
  createNoteNoteItemSuccess,
  createNoteNoteItemFailure,
  updateNoteNoteItemStart,
  updateNoteNoteItemSuccess,
  updateNoteNoteItemFailure,
  deleteNoteNoteItemStart,
  deleteNoteNoteItemSuccess,
  deleteNoteNoteItemFailure
} = slice.actions;

export default slice.reducer;
