import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'series',
  initialState: { loading: false },
  reducers: {
    
    // CHANNEL REDUCER FUNCTION

    // CHANNEL FETCH LIST
    fetchSeriesChannelListStart: stateLoadingStart,
    fetchSeriesChannelListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `channel_list`),
    fetchSeriesChannelListFailure: stateError,

    // CHANNEL FETCH ITEM
    fetchSeriesChannelItemStart: stateLoadingStart,
    fetchSeriesChannelItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `channel_item`),
    fetchSeriesChannelItemFailure: stateError,

    // CHANNEL CREATE ITEM
    createSeriesChannelItemStart: stateLoadingStart,
    createSeriesChannelItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `channel_item`),
    createSeriesChannelItemFailure: stateError,

    // CHANNEL UPDATE ITEM
    updateSeriesChannelItemStart: stateLoadingStart,
    updateSeriesChannelItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `channel_item`),
    updateSeriesChannelItemFailure: stateError,

    // CHANNEL DELETE ITEM
    deleteSeriesChannelItemStart: stateLoadingStart,
    deleteSeriesChannelItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_channel`),
    deleteSeriesChannelItemFailure: stateError,


    // CONTENT REDUCER FUNCTION

    // CONTENT FETCH LIST
    fetchSeriesContentListStart: stateLoadingStart,
    fetchSeriesContentListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `content_list`),
    fetchSeriesContentListFailure: stateError,

    // CONTENT FETCH ITEM
    fetchSeriesContentItemStart: stateLoadingStart,
    fetchSeriesContentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `content_item`),
    fetchSeriesContentItemFailure: stateError,

    // CONTENT CREATE ITEM
    createSeriesContentItemStart: stateLoadingStart,
    createSeriesContentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `content_item`),
    createSeriesContentItemFailure: stateError,

    // CONTENT UPDATE ITEM
    updateSeriesContentItemStart: stateLoadingStart,
    updateSeriesContentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `content_item`),
    updateSeriesContentItemFailure: stateError,

    // CONTENT DELETE ITEM
    deleteSeriesContentItemStart: stateLoadingStart,
    deleteSeriesContentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_content`),
    deleteSeriesContentItemFailure: stateError,


  },
});

export const {
  fetchSeriesChannelListStart, fetchSeriesContentListStart,
  fetchSeriesChannelListSuccess, fetchSeriesContentListSuccess,
  fetchSeriesChannelListFailure, fetchSeriesContentListFailure,
  fetchSeriesChannelItemStart, fetchSeriesContentItemStart,
  fetchSeriesChannelItemSuccess, fetchSeriesContentItemSuccess,
  fetchSeriesChannelItemFailure, fetchSeriesContentItemFailure,
  createSeriesChannelItemStart, createSeriesContentItemStart,
  createSeriesChannelItemSuccess, createSeriesContentItemSuccess,
  createSeriesChannelItemFailure, createSeriesContentItemFailure,
  updateSeriesChannelItemStart, updateSeriesContentItemStart,
  updateSeriesChannelItemSuccess, updateSeriesContentItemSuccess,
  updateSeriesChannelItemFailure, updateSeriesContentItemFailure,
  deleteSeriesChannelItemStart, deleteSeriesContentItemStart,
  deleteSeriesChannelItemSuccess, deleteSeriesContentItemSuccess,
  deleteSeriesChannelItemFailure, deleteSeriesContentItemFailure
} = slice.actions;

export default slice.reducer;
