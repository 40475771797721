import axios from '../utils/axios';

import {fetchDriveAlloweduserspaceListStart, fetchDriveAlloweduserspaceListSuccess, fetchDriveAlloweduserspaceListFailure} from '../features/drive'
import {fetchDriveAlloweduserspaceItemStart, fetchDriveAlloweduserspaceItemSuccess, fetchDriveAlloweduserspaceItemFailure} from '../features/drive'
import {createDriveAlloweduserspaceItemStart, createDriveAlloweduserspaceItemSuccess, createDriveAlloweduserspaceItemFailure} from '../features/drive'
import {updateDriveAlloweduserspaceItemStart, updateDriveAlloweduserspaceItemSuccess, updateDriveAlloweduserspaceItemFailure} from '../features/drive'
import {deleteDriveAlloweduserspaceItemStart, deleteDriveAlloweduserspaceItemSuccess, deleteDriveAlloweduserspaceItemFailure} from '../features/drive'

import {fetchDriveFolderListStart, fetchDriveFolderListSuccess, fetchDriveFolderListFailure} from '../features/drive'
import {fetchDriveFolderItemStart, fetchDriveFolderItemSuccess, fetchDriveFolderItemFailure} from '../features/drive'
import {createDriveFolderItemStart, createDriveFolderItemSuccess, createDriveFolderItemFailure} from '../features/drive'
import {updateDriveFolderItemStart, updateDriveFolderItemSuccess, updateDriveFolderItemFailure} from '../features/drive'
import {deleteDriveFolderItemStart, deleteDriveFolderItemSuccess, deleteDriveFolderItemFailure} from '../features/drive'

import {fetchDriveFileListStart, fetchDriveFileListSuccess, fetchDriveFileListFailure} from '../features/drive'
import {fetchDriveFileItemStart, fetchDriveFileItemSuccess, fetchDriveFileItemFailure} from '../features/drive'
import {createDriveFileItemStart, createDriveFileItemSuccess, createDriveFileItemFailure} from '../features/drive'
import {updateDriveFileItemStart, updateDriveFileItemSuccess, updateDriveFileItemFailure} from '../features/drive'
import {deleteDriveFileItemStart, deleteDriveFileItemSuccess, deleteDriveFileItemFailure} from '../features/drive'

import {fetchDriveSharelinkListStart, fetchDriveSharelinkListSuccess, fetchDriveSharelinkListFailure} from '../features/drive'
import {fetchDriveSharelinkItemStart, fetchDriveSharelinkItemSuccess, fetchDriveSharelinkItemFailure} from '../features/drive'
import {createDriveSharelinkItemStart, createDriveSharelinkItemSuccess, createDriveSharelinkItemFailure} from '../features/drive'
import {updateDriveSharelinkItemStart, updateDriveSharelinkItemSuccess, updateDriveSharelinkItemFailure} from '../features/drive'
import {deleteDriveSharelinkItemStart, deleteDriveSharelinkItemSuccess, deleteDriveSharelinkItemFailure} from '../features/drive'

import {fetchDriveMessageListStart, fetchDriveMessageListSuccess, fetchDriveMessageListFailure} from '../features/drive'
import {fetchDriveMessageItemStart, fetchDriveMessageItemSuccess, fetchDriveMessageItemFailure} from '../features/drive'
import {createDriveMessageItemStart, createDriveMessageItemSuccess, createDriveMessageItemFailure} from '../features/drive'
import {updateDriveMessageItemStart, updateDriveMessageItemSuccess, updateDriveMessageItemFailure} from '../features/drive'
import {deleteDriveMessageItemStart, deleteDriveMessageItemSuccess, deleteDriveMessageItemFailure} from '../features/drive'


// ALLOWEDUSERSPACE ACTIONS
export const fetchDriveAlloweduserspaceList = async (dispatch, info, key) => {
    try{
        dispatch(fetchDriveAlloweduserspaceListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/drive/alloweduserspace/`, { params: info.params, headers: info.headers });
        dispatch(fetchDriveAlloweduserspaceListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDriveAlloweduserspaceListFailure({key, error: error.message}));
    }
}
export const fetchDriveAlloweduserspaceItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchDriveAlloweduserspaceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/drive/alloweduserspace/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchDriveAlloweduserspaceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDriveAlloweduserspaceItemFailure({key, error: error.message}));
    }
}
export const createDriveAlloweduserspaceItem = async (dispatch, info, key) => {
    try{
        dispatch(createDriveAlloweduserspaceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/drive/alloweduserspace/`, info.payload, { headers: info.headers });
        dispatch(createDriveAlloweduserspaceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createDriveAlloweduserspaceItemFailure({key, error: error.message}));
    }
}
export const updateDriveAlloweduserspaceItem = async (dispatch, info, key) => {
    try{
        dispatch(updateDriveAlloweduserspaceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/drive/alloweduserspace/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateDriveAlloweduserspaceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateDriveAlloweduserspaceItemFailure({key, error: error.message}));
    }
}
export const deleteDriveAlloweduserspaceItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteDriveAlloweduserspaceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/drive/alloweduserspace/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteDriveAlloweduserspaceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteDriveAlloweduserspaceItemFailure({key, error: error.message}));
    }
}


// FOLDER ACTIONS
export const fetchDriveFolderList = async (dispatch, info, key) => {
    try{
        dispatch(fetchDriveFolderListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/drive/folder/`, { params: info.params, headers: info.headers });
        dispatch(fetchDriveFolderListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDriveFolderListFailure({key, error: error.message}));
    }
}
export const fetchDriveFolderItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchDriveFolderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/drive/folder/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchDriveFolderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDriveFolderItemFailure({key, error: error.message}));
    }
}
export const createDriveFolderItem = async (dispatch, info, key) => {
    try{
        dispatch(createDriveFolderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/drive/folder/`, info.payload, { headers: info.headers });
        dispatch(createDriveFolderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createDriveFolderItemFailure({key, error: error.message}));
    }
}
export const updateDriveFolderItem = async (dispatch, info, key) => {
    try{
        dispatch(updateDriveFolderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/drive/folder/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateDriveFolderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateDriveFolderItemFailure({key, error: error.message}));
    }
}
export const deleteDriveFolderItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteDriveFolderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/drive/folder/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteDriveFolderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteDriveFolderItemFailure({key, error: error.message}));
    }
}


// FILE ACTIONS
export const fetchDriveFileList = async (dispatch, info, key) => {
    try{
        dispatch(fetchDriveFileListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/drive/file/`, { params: info.params, headers: info.headers });
        dispatch(fetchDriveFileListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDriveFileListFailure({key, error: error.message}));
    }
}
export const fetchDriveFileItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchDriveFileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/drive/file/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchDriveFileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDriveFileItemFailure({key, error: error.message}));
    }
}
export const createDriveFileItem = async (dispatch, info, key) => {
    try{
        dispatch(createDriveFileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/drive/file/`, info.payload, { headers: info.headers });
        dispatch(createDriveFileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createDriveFileItemFailure({key, error: error.message}));
    }
}
export const updateDriveFileItem = async (dispatch, info, key) => {
    try{
        dispatch(updateDriveFileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/drive/file/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateDriveFileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateDriveFileItemFailure({key, error: error.message}));
    }
}
export const deleteDriveFileItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteDriveFileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/drive/file/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteDriveFileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteDriveFileItemFailure({key, error: error.message}));
    }
}


// SHARELINK ACTIONS
export const fetchDriveSharelinkList = async (dispatch, info, key) => {
    try{
        dispatch(fetchDriveSharelinkListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/drive/sharelink/`, { params: info.params, headers: info.headers });
        dispatch(fetchDriveSharelinkListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDriveSharelinkListFailure({key, error: error.message}));
    }
}
export const fetchDriveSharelinkItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchDriveSharelinkItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/drive/sharelink/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchDriveSharelinkItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDriveSharelinkItemFailure({key, error: error.message}));
    }
}
export const createDriveSharelinkItem = async (dispatch, info, key) => {
    try{
        dispatch(createDriveSharelinkItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/drive/sharelink/`, info.payload, { headers: info.headers });
        dispatch(createDriveSharelinkItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createDriveSharelinkItemFailure({key, error: error.message}));
    }
}
export const updateDriveSharelinkItem = async (dispatch, info, key) => {
    try{
        dispatch(updateDriveSharelinkItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/drive/sharelink/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateDriveSharelinkItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateDriveSharelinkItemFailure({key, error: error.message}));
    }
}
export const deleteDriveSharelinkItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteDriveSharelinkItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/drive/sharelink/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteDriveSharelinkItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteDriveSharelinkItemFailure({key, error: error.message}));
    }
}


// MESSAGE ACTIONS
export const fetchDriveMessageList = async (dispatch, info, key) => {
    try{
        dispatch(fetchDriveMessageListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/drive/message/`, { params: info.params, headers: info.headers });
        dispatch(fetchDriveMessageListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDriveMessageListFailure({key, error: error.message}));
    }
}
export const fetchDriveMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchDriveMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/drive/message/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchDriveMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDriveMessageItemFailure({key, error: error.message}));
    }
}
export const createDriveMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(createDriveMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/drive/message/`, info.payload, { headers: info.headers });
        dispatch(createDriveMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createDriveMessageItemFailure({key, error: error.message}));
    }
}
export const updateDriveMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(updateDriveMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/drive/message/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateDriveMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateDriveMessageItemFailure({key, error: error.message}));
    }
}
export const deleteDriveMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteDriveMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/drive/message/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteDriveMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteDriveMessageItemFailure({key, error: error.message}));
    }
}




