import axios from '../utils/axios';

import {fetchNotificationNotificationListStart, fetchNotificationNotificationListSuccess, fetchNotificationNotificationListFailure} from '../features/notification'
import {fetchNotificationNotificationItemStart, fetchNotificationNotificationItemSuccess, fetchNotificationNotificationItemFailure} from '../features/notification'
import {createNotificationNotificationItemStart, createNotificationNotificationItemSuccess, createNotificationNotificationItemFailure} from '../features/notification'
import {updateNotificationNotificationItemStart, updateNotificationNotificationItemSuccess, updateNotificationNotificationItemFailure} from '../features/notification'
import {deleteNotificationNotificationItemStart, deleteNotificationNotificationItemSuccess, deleteNotificationNotificationItemFailure} from '../features/notification'


// NOTIFICATION ACTIONS
export const fetchNotificationNotificationList = async (dispatch, info, key) => {
    try{
        dispatch(fetchNotificationNotificationListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/notification/notification/`, { params: info.params, headers: info.headers });
        dispatch(fetchNotificationNotificationListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNotificationNotificationListFailure({key, error: error.message}));
    }
}
export const fetchNotificationNotificationItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchNotificationNotificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/notification/notification/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchNotificationNotificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNotificationNotificationItemFailure({key, error: error.message}));
    }
}
export const createNotificationNotificationItem = async (dispatch, info, key) => {
    try{
        dispatch(createNotificationNotificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/notification/notification/`, info.payload, { headers: info.headers });
        dispatch(createNotificationNotificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createNotificationNotificationItemFailure({key, error: error.message}));
    }
}
export const updateNotificationNotificationItem = async (dispatch, info, key) => {
    try{
        dispatch(updateNotificationNotificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/notification/notification/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateNotificationNotificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateNotificationNotificationItemFailure({key, error: error.message}));
    }
}
export const deleteNotificationNotificationItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteNotificationNotificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/notification/notification/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteNotificationNotificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteNotificationNotificationItemFailure({key, error: error.message}));
    }
}




