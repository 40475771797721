import axios from '../utils/axios';

import {fetchExtraExclusiveaccesscontactsListStart, fetchExtraExclusiveaccesscontactsListSuccess, fetchExtraExclusiveaccesscontactsListFailure} from '../features/extra'
import {fetchExtraExclusiveaccesscontactsItemStart, fetchExtraExclusiveaccesscontactsItemSuccess, fetchExtraExclusiveaccesscontactsItemFailure} from '../features/extra'
import {createExtraExclusiveaccesscontactsItemStart, createExtraExclusiveaccesscontactsItemSuccess, createExtraExclusiveaccesscontactsItemFailure} from '../features/extra'
import {updateExtraExclusiveaccesscontactsItemStart, updateExtraExclusiveaccesscontactsItemSuccess, updateExtraExclusiveaccesscontactsItemFailure} from '../features/extra'
import {deleteExtraExclusiveaccesscontactsItemStart, deleteExtraExclusiveaccesscontactsItemSuccess, deleteExtraExclusiveaccesscontactsItemFailure} from '../features/extra'


// EXCLUSIVEACCESSCONTACTS ACTIONS
export const fetchExtraExclusiveaccesscontactsList = async (dispatch, info, key) => {
    try{
        dispatch(fetchExtraExclusiveaccesscontactsListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/extra/exclusiveaccesscontacts/`, { params: info.params, headers: info.headers });
        dispatch(fetchExtraExclusiveaccesscontactsListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchExtraExclusiveaccesscontactsListFailure({key, error: error.message}));
    }
}
export const fetchExtraExclusiveaccesscontactsItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchExtraExclusiveaccesscontactsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/extra/exclusiveaccesscontacts/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchExtraExclusiveaccesscontactsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchExtraExclusiveaccesscontactsItemFailure({key, error: error.message}));
    }
}
export const createExtraExclusiveaccesscontactsItem = async (dispatch, info, key) => {
    try{
        dispatch(createExtraExclusiveaccesscontactsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/extra/exclusiveaccesscontacts/`, info.payload, { headers: info.headers });
        dispatch(createExtraExclusiveaccesscontactsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createExtraExclusiveaccesscontactsItemFailure({key, error: error.message}));
    }
}
export const updateExtraExclusiveaccesscontactsItem = async (dispatch, info, key) => {
    try{
        dispatch(updateExtraExclusiveaccesscontactsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/extra/exclusiveaccesscontacts/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateExtraExclusiveaccesscontactsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateExtraExclusiveaccesscontactsItemFailure({key, error: error.message}));
    }
}
export const deleteExtraExclusiveaccesscontactsItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteExtraExclusiveaccesscontactsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/extra/exclusiveaccesscontacts/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteExtraExclusiveaccesscontactsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteExtraExclusiveaccesscontactsItemFailure({key, error: error.message}));
    }
}




