import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'websiteinfo',
  initialState: { loading: false },
  reducers: {
    
    // SITE REDUCER FUNCTION

    // SITE FETCH LIST
    fetchWebsiteinfoSiteListStart: stateLoadingStart,
    fetchWebsiteinfoSiteListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `site_list`),
    fetchWebsiteinfoSiteListFailure: stateError,

    // SITE FETCH ITEM
    fetchWebsiteinfoSiteItemStart: stateLoadingStart,
    fetchWebsiteinfoSiteItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `site_item`),
    fetchWebsiteinfoSiteItemFailure: stateError,

    // SITE CREATE ITEM
    createWebsiteinfoSiteItemStart: stateLoadingStart,
    createWebsiteinfoSiteItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `site_item`),
    createWebsiteinfoSiteItemFailure: stateError,

    // SITE UPDATE ITEM
    updateWebsiteinfoSiteItemStart: stateLoadingStart,
    updateWebsiteinfoSiteItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `site_item`),
    updateWebsiteinfoSiteItemFailure: stateError,

    // SITE DELETE ITEM
    deleteWebsiteinfoSiteItemStart: stateLoadingStart,
    deleteWebsiteinfoSiteItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_site`),
    deleteWebsiteinfoSiteItemFailure: stateError,


    // SITECONTENT REDUCER FUNCTION

    // SITECONTENT FETCH LIST
    fetchWebsiteinfoSitecontentListStart: stateLoadingStart,
    fetchWebsiteinfoSitecontentListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `sitecontent_list`),
    fetchWebsiteinfoSitecontentListFailure: stateError,

    // SITECONTENT FETCH ITEM
    fetchWebsiteinfoSitecontentItemStart: stateLoadingStart,
    fetchWebsiteinfoSitecontentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `sitecontent_item`),
    fetchWebsiteinfoSitecontentItemFailure: stateError,

    // SITECONTENT CREATE ITEM
    createWebsiteinfoSitecontentItemStart: stateLoadingStart,
    createWebsiteinfoSitecontentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `sitecontent_item`),
    createWebsiteinfoSitecontentItemFailure: stateError,

    // SITECONTENT UPDATE ITEM
    updateWebsiteinfoSitecontentItemStart: stateLoadingStart,
    updateWebsiteinfoSitecontentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `sitecontent_item`),
    updateWebsiteinfoSitecontentItemFailure: stateError,

    // SITECONTENT DELETE ITEM
    deleteWebsiteinfoSitecontentItemStart: stateLoadingStart,
    deleteWebsiteinfoSitecontentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_sitecontent`),
    deleteWebsiteinfoSitecontentItemFailure: stateError,


  },
});

export const {
  fetchWebsiteinfoSiteListStart, fetchWebsiteinfoSitecontentListStart,
  fetchWebsiteinfoSiteListSuccess, fetchWebsiteinfoSitecontentListSuccess,
  fetchWebsiteinfoSiteListFailure, fetchWebsiteinfoSitecontentListFailure,
  fetchWebsiteinfoSiteItemStart, fetchWebsiteinfoSitecontentItemStart,
  fetchWebsiteinfoSiteItemSuccess, fetchWebsiteinfoSitecontentItemSuccess,
  fetchWebsiteinfoSiteItemFailure, fetchWebsiteinfoSitecontentItemFailure,
  createWebsiteinfoSiteItemStart, createWebsiteinfoSitecontentItemStart,
  createWebsiteinfoSiteItemSuccess, createWebsiteinfoSitecontentItemSuccess,
  createWebsiteinfoSiteItemFailure, createWebsiteinfoSitecontentItemFailure,
  updateWebsiteinfoSiteItemStart, updateWebsiteinfoSitecontentItemStart,
  updateWebsiteinfoSiteItemSuccess, updateWebsiteinfoSitecontentItemSuccess,
  updateWebsiteinfoSiteItemFailure, updateWebsiteinfoSitecontentItemFailure,
  deleteWebsiteinfoSiteItemStart, deleteWebsiteinfoSitecontentItemStart,
  deleteWebsiteinfoSiteItemSuccess, deleteWebsiteinfoSitecontentItemSuccess,
  deleteWebsiteinfoSiteItemFailure, deleteWebsiteinfoSitecontentItemFailure
} = slice.actions;

export default slice.reducer;
