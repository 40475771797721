import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'extra',
  initialState: { loading: false },
  reducers: {
    
    // EXCLUSIVEACCESSCONTACTS REDUCER FUNCTION

    // EXCLUSIVEACCESSCONTACTS FETCH LIST
    fetchExtraExclusiveaccesscontactsListStart: stateLoadingStart,
    fetchExtraExclusiveaccesscontactsListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `exclusiveaccesscontacts_list`),
    fetchExtraExclusiveaccesscontactsListFailure: stateError,

    // EXCLUSIVEACCESSCONTACTS FETCH ITEM
    fetchExtraExclusiveaccesscontactsItemStart: stateLoadingStart,
    fetchExtraExclusiveaccesscontactsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `exclusiveaccesscontacts_item`),
    fetchExtraExclusiveaccesscontactsItemFailure: stateError,

    // EXCLUSIVEACCESSCONTACTS CREATE ITEM
    createExtraExclusiveaccesscontactsItemStart: stateLoadingStart,
    createExtraExclusiveaccesscontactsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `exclusiveaccesscontacts_item`),
    createExtraExclusiveaccesscontactsItemFailure: stateError,

    // EXCLUSIVEACCESSCONTACTS UPDATE ITEM
    updateExtraExclusiveaccesscontactsItemStart: stateLoadingStart,
    updateExtraExclusiveaccesscontactsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `exclusiveaccesscontacts_item`),
    updateExtraExclusiveaccesscontactsItemFailure: stateError,

    // EXCLUSIVEACCESSCONTACTS DELETE ITEM
    deleteExtraExclusiveaccesscontactsItemStart: stateLoadingStart,
    deleteExtraExclusiveaccesscontactsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_exclusiveaccesscontacts`),
    deleteExtraExclusiveaccesscontactsItemFailure: stateError,


  },
});

export const {
  fetchExtraExclusiveaccesscontactsListStart,
  fetchExtraExclusiveaccesscontactsListSuccess,
  fetchExtraExclusiveaccesscontactsListFailure,
  fetchExtraExclusiveaccesscontactsItemStart,
  fetchExtraExclusiveaccesscontactsItemSuccess,
  fetchExtraExclusiveaccesscontactsItemFailure,
  createExtraExclusiveaccesscontactsItemStart,
  createExtraExclusiveaccesscontactsItemSuccess,
  createExtraExclusiveaccesscontactsItemFailure,
  updateExtraExclusiveaccesscontactsItemStart,
  updateExtraExclusiveaccesscontactsItemSuccess,
  updateExtraExclusiveaccesscontactsItemFailure,
  deleteExtraExclusiveaccesscontactsItemStart,
  deleteExtraExclusiveaccesscontactsItemSuccess,
  deleteExtraExclusiveaccesscontactsItemFailure
} = slice.actions;

export default slice.reducer;
