import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'drive',
  initialState: { loading: false },
  reducers: {
    
    // ALLOWEDUSERSPACE REDUCER FUNCTION

    // ALLOWEDUSERSPACE FETCH LIST
    fetchDriveAlloweduserspaceListStart: stateLoadingStart,
    fetchDriveAlloweduserspaceListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `alloweduserspace_list`),
    fetchDriveAlloweduserspaceListFailure: stateError,

    // ALLOWEDUSERSPACE FETCH ITEM
    fetchDriveAlloweduserspaceItemStart: stateLoadingStart,
    fetchDriveAlloweduserspaceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `alloweduserspace_item`),
    fetchDriveAlloweduserspaceItemFailure: stateError,

    // ALLOWEDUSERSPACE CREATE ITEM
    createDriveAlloweduserspaceItemStart: stateLoadingStart,
    createDriveAlloweduserspaceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `alloweduserspace_item`),
    createDriveAlloweduserspaceItemFailure: stateError,

    // ALLOWEDUSERSPACE UPDATE ITEM
    updateDriveAlloweduserspaceItemStart: stateLoadingStart,
    updateDriveAlloweduserspaceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `alloweduserspace_item`),
    updateDriveAlloweduserspaceItemFailure: stateError,

    // ALLOWEDUSERSPACE DELETE ITEM
    deleteDriveAlloweduserspaceItemStart: stateLoadingStart,
    deleteDriveAlloweduserspaceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_alloweduserspace`),
    deleteDriveAlloweduserspaceItemFailure: stateError,


    // FOLDER REDUCER FUNCTION

    // FOLDER FETCH LIST
    fetchDriveFolderListStart: stateLoadingStart,
    fetchDriveFolderListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `folder_list`),
    fetchDriveFolderListFailure: stateError,

    // FOLDER FETCH ITEM
    fetchDriveFolderItemStart: stateLoadingStart,
    fetchDriveFolderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `folder_item`),
    fetchDriveFolderItemFailure: stateError,

    // FOLDER CREATE ITEM
    createDriveFolderItemStart: stateLoadingStart,
    createDriveFolderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `folder_item`),
    createDriveFolderItemFailure: stateError,

    // FOLDER UPDATE ITEM
    updateDriveFolderItemStart: stateLoadingStart,
    updateDriveFolderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `folder_item`),
    updateDriveFolderItemFailure: stateError,

    // FOLDER DELETE ITEM
    deleteDriveFolderItemStart: stateLoadingStart,
    deleteDriveFolderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_folder`),
    deleteDriveFolderItemFailure: stateError,


    // FILE REDUCER FUNCTION

    // FILE FETCH LIST
    fetchDriveFileListStart: stateLoadingStart,
    fetchDriveFileListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `file_list`),
    fetchDriveFileListFailure: stateError,

    // FILE FETCH ITEM
    fetchDriveFileItemStart: stateLoadingStart,
    fetchDriveFileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `file_item`),
    fetchDriveFileItemFailure: stateError,

    // FILE CREATE ITEM
    createDriveFileItemStart: stateLoadingStart,
    createDriveFileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `file_item`),
    createDriveFileItemFailure: stateError,

    // FILE UPDATE ITEM
    updateDriveFileItemStart: stateLoadingStart,
    updateDriveFileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `file_item`),
    updateDriveFileItemFailure: stateError,

    // FILE DELETE ITEM
    deleteDriveFileItemStart: stateLoadingStart,
    deleteDriveFileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_file`),
    deleteDriveFileItemFailure: stateError,


    // SHARELINK REDUCER FUNCTION

    // SHARELINK FETCH LIST
    fetchDriveSharelinkListStart: stateLoadingStart,
    fetchDriveSharelinkListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `sharelink_list`),
    fetchDriveSharelinkListFailure: stateError,

    // SHARELINK FETCH ITEM
    fetchDriveSharelinkItemStart: stateLoadingStart,
    fetchDriveSharelinkItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `sharelink_item`),
    fetchDriveSharelinkItemFailure: stateError,

    // SHARELINK CREATE ITEM
    createDriveSharelinkItemStart: stateLoadingStart,
    createDriveSharelinkItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `sharelink_item`),
    createDriveSharelinkItemFailure: stateError,

    // SHARELINK UPDATE ITEM
    updateDriveSharelinkItemStart: stateLoadingStart,
    updateDriveSharelinkItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `sharelink_item`),
    updateDriveSharelinkItemFailure: stateError,

    // SHARELINK DELETE ITEM
    deleteDriveSharelinkItemStart: stateLoadingStart,
    deleteDriveSharelinkItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_sharelink`),
    deleteDriveSharelinkItemFailure: stateError,


    // MESSAGE REDUCER FUNCTION

    // MESSAGE FETCH LIST
    fetchDriveMessageListStart: stateLoadingStart,
    fetchDriveMessageListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_list`),
    fetchDriveMessageListFailure: stateError,

    // MESSAGE FETCH ITEM
    fetchDriveMessageItemStart: stateLoadingStart,
    fetchDriveMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_item`),
    fetchDriveMessageItemFailure: stateError,

    // MESSAGE CREATE ITEM
    createDriveMessageItemStart: stateLoadingStart,
    createDriveMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_item`),
    createDriveMessageItemFailure: stateError,

    // MESSAGE UPDATE ITEM
    updateDriveMessageItemStart: stateLoadingStart,
    updateDriveMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_item`),
    updateDriveMessageItemFailure: stateError,

    // MESSAGE DELETE ITEM
    deleteDriveMessageItemStart: stateLoadingStart,
    deleteDriveMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_message`),
    deleteDriveMessageItemFailure: stateError,


  },
});

export const {
  fetchDriveAlloweduserspaceListStart, fetchDriveFolderListStart, fetchDriveFileListStart, fetchDriveSharelinkListStart, fetchDriveMessageListStart,
  fetchDriveAlloweduserspaceListSuccess, fetchDriveFolderListSuccess, fetchDriveFileListSuccess, fetchDriveSharelinkListSuccess, fetchDriveMessageListSuccess,
  fetchDriveAlloweduserspaceListFailure, fetchDriveFolderListFailure, fetchDriveFileListFailure, fetchDriveSharelinkListFailure, fetchDriveMessageListFailure,
  fetchDriveAlloweduserspaceItemStart, fetchDriveFolderItemStart, fetchDriveFileItemStart, fetchDriveSharelinkItemStart, fetchDriveMessageItemStart,
  fetchDriveAlloweduserspaceItemSuccess, fetchDriveFolderItemSuccess, fetchDriveFileItemSuccess, fetchDriveSharelinkItemSuccess, fetchDriveMessageItemSuccess,
  fetchDriveAlloweduserspaceItemFailure, fetchDriveFolderItemFailure, fetchDriveFileItemFailure, fetchDriveSharelinkItemFailure, fetchDriveMessageItemFailure,
  createDriveAlloweduserspaceItemStart, createDriveFolderItemStart, createDriveFileItemStart, createDriveSharelinkItemStart, createDriveMessageItemStart,
  createDriveAlloweduserspaceItemSuccess, createDriveFolderItemSuccess, createDriveFileItemSuccess, createDriveSharelinkItemSuccess, createDriveMessageItemSuccess,
  createDriveAlloweduserspaceItemFailure, createDriveFolderItemFailure, createDriveFileItemFailure, createDriveSharelinkItemFailure, createDriveMessageItemFailure,
  updateDriveAlloweduserspaceItemStart, updateDriveFolderItemStart, updateDriveFileItemStart, updateDriveSharelinkItemStart, updateDriveMessageItemStart,
  updateDriveAlloweduserspaceItemSuccess, updateDriveFolderItemSuccess, updateDriveFileItemSuccess, updateDriveSharelinkItemSuccess, updateDriveMessageItemSuccess,
  updateDriveAlloweduserspaceItemFailure, updateDriveFolderItemFailure, updateDriveFileItemFailure, updateDriveSharelinkItemFailure, updateDriveMessageItemFailure,
  deleteDriveAlloweduserspaceItemStart, deleteDriveFolderItemStart, deleteDriveFileItemStart, deleteDriveSharelinkItemStart, deleteDriveMessageItemStart,
  deleteDriveAlloweduserspaceItemSuccess, deleteDriveFolderItemSuccess, deleteDriveFileItemSuccess, deleteDriveSharelinkItemSuccess, deleteDriveMessageItemSuccess,
  deleteDriveAlloweduserspaceItemFailure, deleteDriveFolderItemFailure, deleteDriveFileItemFailure, deleteDriveSharelinkItemFailure, deleteDriveMessageItemFailure
} = slice.actions;

export default slice.reducer;
