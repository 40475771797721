import axios from '../utils/axios';

import {fetchQuizQuizsetListStart, fetchQuizQuizsetListSuccess, fetchQuizQuizsetListFailure} from '../features/quiz'
import {fetchQuizQuizsetItemStart, fetchQuizQuizsetItemSuccess, fetchQuizQuizsetItemFailure} from '../features/quiz'
import {createQuizQuizsetItemStart, createQuizQuizsetItemSuccess, createQuizQuizsetItemFailure} from '../features/quiz'
import {updateQuizQuizsetItemStart, updateQuizQuizsetItemSuccess, updateQuizQuizsetItemFailure} from '../features/quiz'
import {deleteQuizQuizsetItemStart, deleteQuizQuizsetItemSuccess, deleteQuizQuizsetItemFailure} from '../features/quiz'

import {fetchQuizQuizitemListStart, fetchQuizQuizitemListSuccess, fetchQuizQuizitemListFailure} from '../features/quiz'
import {fetchQuizQuizitemItemStart, fetchQuizQuizitemItemSuccess, fetchQuizQuizitemItemFailure} from '../features/quiz'
import {createQuizQuizitemItemStart, createQuizQuizitemItemSuccess, createQuizQuizitemItemFailure} from '../features/quiz'
import {updateQuizQuizitemItemStart, updateQuizQuizitemItemSuccess, updateQuizQuizitemItemFailure} from '../features/quiz'
import {deleteQuizQuizitemItemStart, deleteQuizQuizitemItemSuccess, deleteQuizQuizitemItemFailure} from '../features/quiz'

import {fetchQuizQuizresponseListStart, fetchQuizQuizresponseListSuccess, fetchQuizQuizresponseListFailure} from '../features/quiz'
import {fetchQuizQuizresponseItemStart, fetchQuizQuizresponseItemSuccess, fetchQuizQuizresponseItemFailure} from '../features/quiz'
import {createQuizQuizresponseItemStart, createQuizQuizresponseItemSuccess, createQuizQuizresponseItemFailure} from '../features/quiz'
import {updateQuizQuizresponseItemStart, updateQuizQuizresponseItemSuccess, updateQuizQuizresponseItemFailure} from '../features/quiz'
import {deleteQuizQuizresponseItemStart, deleteQuizQuizresponseItemSuccess, deleteQuizQuizresponseItemFailure} from '../features/quiz'

import {fetchQuizQuizsubmissionListStart, fetchQuizQuizsubmissionListSuccess, fetchQuizQuizsubmissionListFailure} from '../features/quiz'
import {fetchQuizQuizsubmissionItemStart, fetchQuizQuizsubmissionItemSuccess, fetchQuizQuizsubmissionItemFailure} from '../features/quiz'
import {createQuizQuizsubmissionItemStart, createQuizQuizsubmissionItemSuccess, createQuizQuizsubmissionItemFailure} from '../features/quiz'
import {updateQuizQuizsubmissionItemStart, updateQuizQuizsubmissionItemSuccess, updateQuizQuizsubmissionItemFailure} from '../features/quiz'
import {deleteQuizQuizsubmissionItemStart, deleteQuizQuizsubmissionItemSuccess, deleteQuizQuizsubmissionItemFailure} from '../features/quiz'


// QUIZSET ACTIONS
export const fetchQuizQuizsetList = async (dispatch, info, key) => {
    try{
        dispatch(fetchQuizQuizsetListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/quiz/quizset/`, { params: info.params, headers: info.headers });
        dispatch(fetchQuizQuizsetListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchQuizQuizsetListFailure({key, error: error.message}));
    }
}
export const fetchQuizQuizsetItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchQuizQuizsetItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/quiz/quizset/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchQuizQuizsetItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchQuizQuizsetItemFailure({key, error: error.message}));
    }
}
export const createQuizQuizsetItem = async (dispatch, info, key) => {
    try{
        dispatch(createQuizQuizsetItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/quiz/quizset/`, info.payload, { headers: info.headers });
        dispatch(createQuizQuizsetItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createQuizQuizsetItemFailure({key, error: error.message}));
    }
}
export const updateQuizQuizsetItem = async (dispatch, info, key) => {
    try{
        dispatch(updateQuizQuizsetItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/quiz/quizset/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateQuizQuizsetItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateQuizQuizsetItemFailure({key, error: error.message}));
    }
}
export const deleteQuizQuizsetItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteQuizQuizsetItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/quiz/quizset/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteQuizQuizsetItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteQuizQuizsetItemFailure({key, error: error.message}));
    }
}


// QUIZITEM ACTIONS
export const fetchQuizQuizitemList = async (dispatch, info, key) => {
    try{
        dispatch(fetchQuizQuizitemListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/quiz/quizitem/`, { params: info.params, headers: info.headers });
        dispatch(fetchQuizQuizitemListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchQuizQuizitemListFailure({key, error: error.message}));
    }
}
export const fetchQuizQuizitemItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchQuizQuizitemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/quiz/quizitem/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchQuizQuizitemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchQuizQuizitemItemFailure({key, error: error.message}));
    }
}
export const createQuizQuizitemItem = async (dispatch, info, key) => {
    try{
        dispatch(createQuizQuizitemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/quiz/quizitem/`, info.payload, { headers: info.headers });
        dispatch(createQuizQuizitemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createQuizQuizitemItemFailure({key, error: error.message}));
    }
}
export const updateQuizQuizitemItem = async (dispatch, info, key) => {
    try{
        dispatch(updateQuizQuizitemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/quiz/quizitem/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateQuizQuizitemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateQuizQuizitemItemFailure({key, error: error.message}));
    }
}
export const deleteQuizQuizitemItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteQuizQuizitemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/quiz/quizitem/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteQuizQuizitemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteQuizQuizitemItemFailure({key, error: error.message}));
    }
}


// QUIZRESPONSE ACTIONS
export const fetchQuizQuizresponseList = async (dispatch, info, key) => {
    try{
        dispatch(fetchQuizQuizresponseListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/quiz/quizresponse/`, { params: info.params, headers: info.headers });
        dispatch(fetchQuizQuizresponseListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchQuizQuizresponseListFailure({key, error: error.message}));
    }
}
export const fetchQuizQuizresponseItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchQuizQuizresponseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/quiz/quizresponse/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchQuizQuizresponseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchQuizQuizresponseItemFailure({key, error: error.message}));
    }
}
export const createQuizQuizresponseItem = async (dispatch, info, key) => {
    try{
        dispatch(createQuizQuizresponseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/quiz/quizresponse/`, info.payload, { headers: info.headers });
        dispatch(createQuizQuizresponseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createQuizQuizresponseItemFailure({key, error: error.message}));
    }
}
export const updateQuizQuizresponseItem = async (dispatch, info, key) => {
    try{
        dispatch(updateQuizQuizresponseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/quiz/quizresponse/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateQuizQuizresponseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateQuizQuizresponseItemFailure({key, error: error.message}));
    }
}
export const deleteQuizQuizresponseItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteQuizQuizresponseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/quiz/quizresponse/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteQuizQuizresponseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteQuizQuizresponseItemFailure({key, error: error.message}));
    }
}


// QUIZSUBMISSION ACTIONS
export const fetchQuizQuizsubmissionList = async (dispatch, info, key) => {
    try{
        dispatch(fetchQuizQuizsubmissionListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/quiz/quizsubmission/`, { params: info.params, headers: info.headers });
        dispatch(fetchQuizQuizsubmissionListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchQuizQuizsubmissionListFailure({key, error: error.message}));
    }
}
export const fetchQuizQuizsubmissionItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchQuizQuizsubmissionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/quiz/quizsubmission/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchQuizQuizsubmissionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchQuizQuizsubmissionItemFailure({key, error: error.message}));
    }
}
export const createQuizQuizsubmissionItem = async (dispatch, info, key) => {
    try{
        dispatch(createQuizQuizsubmissionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/quiz/quizsubmission/`, info.payload, { headers: info.headers });
        dispatch(createQuizQuizsubmissionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createQuizQuizsubmissionItemFailure({key, error: error.message}));
    }
}
export const updateQuizQuizsubmissionItem = async (dispatch, info, key) => {
    try{
        dispatch(updateQuizQuizsubmissionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/quiz/quizsubmission/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateQuizQuizsubmissionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateQuizQuizsubmissionItemFailure({key, error: error.message}));
    }
}
export const deleteQuizQuizsubmissionItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteQuizQuizsubmissionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/quiz/quizsubmission/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteQuizQuizsubmissionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteQuizQuizsubmissionItemFailure({key, error: error.message}));
    }
}




