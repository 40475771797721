import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'monetization',
  initialState: { loading: false },
  reducers: {
    
    // SUBSCRIPTIONPLAN REDUCER FUNCTION

    // SUBSCRIPTIONPLAN FETCH LIST
    fetchMonetizationSubscriptionplanListStart: stateLoadingStart,
    fetchMonetizationSubscriptionplanListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `subscriptionplan_list`),
    fetchMonetizationSubscriptionplanListFailure: stateError,

    // SUBSCRIPTIONPLAN FETCH ITEM
    fetchMonetizationSubscriptionplanItemStart: stateLoadingStart,
    fetchMonetizationSubscriptionplanItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `subscriptionplan_item`),
    fetchMonetizationSubscriptionplanItemFailure: stateError,

    // SUBSCRIPTIONPLAN CREATE ITEM
    createMonetizationSubscriptionplanItemStart: stateLoadingStart,
    createMonetizationSubscriptionplanItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `subscriptionplan_item`),
    createMonetizationSubscriptionplanItemFailure: stateError,

    // SUBSCRIPTIONPLAN UPDATE ITEM
    updateMonetizationSubscriptionplanItemStart: stateLoadingStart,
    updateMonetizationSubscriptionplanItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `subscriptionplan_item`),
    updateMonetizationSubscriptionplanItemFailure: stateError,

    // SUBSCRIPTIONPLAN DELETE ITEM
    deleteMonetizationSubscriptionplanItemStart: stateLoadingStart,
    deleteMonetizationSubscriptionplanItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_subscriptionplan`),
    deleteMonetizationSubscriptionplanItemFailure: stateError,


    // GROUPMEMBERSHIP REDUCER FUNCTION

    // GROUPMEMBERSHIP FETCH LIST
    fetchMonetizationGroupmembershipListStart: stateLoadingStart,
    fetchMonetizationGroupmembershipListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `groupmembership_list`),
    fetchMonetizationGroupmembershipListFailure: stateError,

    // GROUPMEMBERSHIP FETCH ITEM
    fetchMonetizationGroupmembershipItemStart: stateLoadingStart,
    fetchMonetizationGroupmembershipItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `groupmembership_item`),
    fetchMonetizationGroupmembershipItemFailure: stateError,

    // GROUPMEMBERSHIP CREATE ITEM
    createMonetizationGroupmembershipItemStart: stateLoadingStart,
    createMonetizationGroupmembershipItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `groupmembership_item`),
    createMonetizationGroupmembershipItemFailure: stateError,

    // GROUPMEMBERSHIP UPDATE ITEM
    updateMonetizationGroupmembershipItemStart: stateLoadingStart,
    updateMonetizationGroupmembershipItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `groupmembership_item`),
    updateMonetizationGroupmembershipItemFailure: stateError,

    // GROUPMEMBERSHIP DELETE ITEM
    deleteMonetizationGroupmembershipItemStart: stateLoadingStart,
    deleteMonetizationGroupmembershipItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_groupmembership`),
    deleteMonetizationGroupmembershipItemFailure: stateError,


    // PURCHASEDCOURSE REDUCER FUNCTION

    // PURCHASEDCOURSE FETCH LIST
    fetchMonetizationPurchasedcourseListStart: stateLoadingStart,
    fetchMonetizationPurchasedcourseListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `purchasedcourse_list`),
    fetchMonetizationPurchasedcourseListFailure: stateError,

    // PURCHASEDCOURSE FETCH ITEM
    fetchMonetizationPurchasedcourseItemStart: stateLoadingStart,
    fetchMonetizationPurchasedcourseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `purchasedcourse_item`),
    fetchMonetizationPurchasedcourseItemFailure: stateError,

    // PURCHASEDCOURSE CREATE ITEM
    createMonetizationPurchasedcourseItemStart: stateLoadingStart,
    createMonetizationPurchasedcourseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `purchasedcourse_item`),
    createMonetizationPurchasedcourseItemFailure: stateError,

    // PURCHASEDCOURSE UPDATE ITEM
    updateMonetizationPurchasedcourseItemStart: stateLoadingStart,
    updateMonetizationPurchasedcourseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `purchasedcourse_item`),
    updateMonetizationPurchasedcourseItemFailure: stateError,

    // PURCHASEDCOURSE DELETE ITEM
    deleteMonetizationPurchasedcourseItemStart: stateLoadingStart,
    deleteMonetizationPurchasedcourseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_purchasedcourse`),
    deleteMonetizationPurchasedcourseItemFailure: stateError,


    // NEWSLETTERSUBSCRIPTION REDUCER FUNCTION

    // NEWSLETTERSUBSCRIPTION FETCH LIST
    fetchMonetizationNewslettersubscriptionListStart: stateLoadingStart,
    fetchMonetizationNewslettersubscriptionListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `newslettersubscription_list`),
    fetchMonetizationNewslettersubscriptionListFailure: stateError,

    // NEWSLETTERSUBSCRIPTION FETCH ITEM
    fetchMonetizationNewslettersubscriptionItemStart: stateLoadingStart,
    fetchMonetizationNewslettersubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `newslettersubscription_item`),
    fetchMonetizationNewslettersubscriptionItemFailure: stateError,

    // NEWSLETTERSUBSCRIPTION CREATE ITEM
    createMonetizationNewslettersubscriptionItemStart: stateLoadingStart,
    createMonetizationNewslettersubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `newslettersubscription_item`),
    createMonetizationNewslettersubscriptionItemFailure: stateError,

    // NEWSLETTERSUBSCRIPTION UPDATE ITEM
    updateMonetizationNewslettersubscriptionItemStart: stateLoadingStart,
    updateMonetizationNewslettersubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `newslettersubscription_item`),
    updateMonetizationNewslettersubscriptionItemFailure: stateError,

    // NEWSLETTERSUBSCRIPTION DELETE ITEM
    deleteMonetizationNewslettersubscriptionItemStart: stateLoadingStart,
    deleteMonetizationNewslettersubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_newslettersubscription`),
    deleteMonetizationNewslettersubscriptionItemFailure: stateError,


    // SERIESSUBSCRIPTION REDUCER FUNCTION

    // SERIESSUBSCRIPTION FETCH LIST
    fetchMonetizationSeriessubscriptionListStart: stateLoadingStart,
    fetchMonetizationSeriessubscriptionListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `seriessubscription_list`),
    fetchMonetizationSeriessubscriptionListFailure: stateError,

    // SERIESSUBSCRIPTION FETCH ITEM
    fetchMonetizationSeriessubscriptionItemStart: stateLoadingStart,
    fetchMonetizationSeriessubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `seriessubscription_item`),
    fetchMonetizationSeriessubscriptionItemFailure: stateError,

    // SERIESSUBSCRIPTION CREATE ITEM
    createMonetizationSeriessubscriptionItemStart: stateLoadingStart,
    createMonetizationSeriessubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `seriessubscription_item`),
    createMonetizationSeriessubscriptionItemFailure: stateError,

    // SERIESSUBSCRIPTION UPDATE ITEM
    updateMonetizationSeriessubscriptionItemStart: stateLoadingStart,
    updateMonetizationSeriessubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `seriessubscription_item`),
    updateMonetizationSeriessubscriptionItemFailure: stateError,

    // SERIESSUBSCRIPTION DELETE ITEM
    deleteMonetizationSeriessubscriptionItemStart: stateLoadingStart,
    deleteMonetizationSeriessubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_seriessubscription`),
    deleteMonetizationSeriessubscriptionItemFailure: stateError,


    // FORUMSUBSCRIPTION REDUCER FUNCTION

    // FORUMSUBSCRIPTION FETCH LIST
    fetchMonetizationForumsubscriptionListStart: stateLoadingStart,
    fetchMonetizationForumsubscriptionListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `forumsubscription_list`),
    fetchMonetizationForumsubscriptionListFailure: stateError,

    // FORUMSUBSCRIPTION FETCH ITEM
    fetchMonetizationForumsubscriptionItemStart: stateLoadingStart,
    fetchMonetizationForumsubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `forumsubscription_item`),
    fetchMonetizationForumsubscriptionItemFailure: stateError,

    // FORUMSUBSCRIPTION CREATE ITEM
    createMonetizationForumsubscriptionItemStart: stateLoadingStart,
    createMonetizationForumsubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `forumsubscription_item`),
    createMonetizationForumsubscriptionItemFailure: stateError,

    // FORUMSUBSCRIPTION UPDATE ITEM
    updateMonetizationForumsubscriptionItemStart: stateLoadingStart,
    updateMonetizationForumsubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `forumsubscription_item`),
    updateMonetizationForumsubscriptionItemFailure: stateError,

    // FORUMSUBSCRIPTION DELETE ITEM
    deleteMonetizationForumsubscriptionItemStart: stateLoadingStart,
    deleteMonetizationForumsubscriptionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_forumsubscription`),
    deleteMonetizationForumsubscriptionItemFailure: stateError,


    // EVENTBOOKING REDUCER FUNCTION

    // EVENTBOOKING FETCH LIST
    fetchMonetizationEventbookingListStart: stateLoadingStart,
    fetchMonetizationEventbookingListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `eventbooking_list`),
    fetchMonetizationEventbookingListFailure: stateError,

    // EVENTBOOKING FETCH ITEM
    fetchMonetizationEventbookingItemStart: stateLoadingStart,
    fetchMonetizationEventbookingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `eventbooking_item`),
    fetchMonetizationEventbookingItemFailure: stateError,

    // EVENTBOOKING CREATE ITEM
    createMonetizationEventbookingItemStart: stateLoadingStart,
    createMonetizationEventbookingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `eventbooking_item`),
    createMonetizationEventbookingItemFailure: stateError,

    // EVENTBOOKING UPDATE ITEM
    updateMonetizationEventbookingItemStart: stateLoadingStart,
    updateMonetizationEventbookingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `eventbooking_item`),
    updateMonetizationEventbookingItemFailure: stateError,

    // EVENTBOOKING DELETE ITEM
    deleteMonetizationEventbookingItemStart: stateLoadingStart,
    deleteMonetizationEventbookingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_eventbooking`),
    deleteMonetizationEventbookingItemFailure: stateError,


  },
});

export const {
  fetchMonetizationSubscriptionplanListStart, fetchMonetizationGroupmembershipListStart, fetchMonetizationPurchasedcourseListStart, fetchMonetizationNewslettersubscriptionListStart, fetchMonetizationSeriessubscriptionListStart, fetchMonetizationForumsubscriptionListStart, fetchMonetizationEventbookingListStart,
  fetchMonetizationSubscriptionplanListSuccess, fetchMonetizationGroupmembershipListSuccess, fetchMonetizationPurchasedcourseListSuccess, fetchMonetizationNewslettersubscriptionListSuccess, fetchMonetizationSeriessubscriptionListSuccess, fetchMonetizationForumsubscriptionListSuccess, fetchMonetizationEventbookingListSuccess,
  fetchMonetizationSubscriptionplanListFailure, fetchMonetizationGroupmembershipListFailure, fetchMonetizationPurchasedcourseListFailure, fetchMonetizationNewslettersubscriptionListFailure, fetchMonetizationSeriessubscriptionListFailure, fetchMonetizationForumsubscriptionListFailure, fetchMonetizationEventbookingListFailure,
  fetchMonetizationSubscriptionplanItemStart, fetchMonetizationGroupmembershipItemStart, fetchMonetizationPurchasedcourseItemStart, fetchMonetizationNewslettersubscriptionItemStart, fetchMonetizationSeriessubscriptionItemStart, fetchMonetizationForumsubscriptionItemStart, fetchMonetizationEventbookingItemStart,
  fetchMonetizationSubscriptionplanItemSuccess, fetchMonetizationGroupmembershipItemSuccess, fetchMonetizationPurchasedcourseItemSuccess, fetchMonetizationNewslettersubscriptionItemSuccess, fetchMonetizationSeriessubscriptionItemSuccess, fetchMonetizationForumsubscriptionItemSuccess, fetchMonetizationEventbookingItemSuccess,
  fetchMonetizationSubscriptionplanItemFailure, fetchMonetizationGroupmembershipItemFailure, fetchMonetizationPurchasedcourseItemFailure, fetchMonetizationNewslettersubscriptionItemFailure, fetchMonetizationSeriessubscriptionItemFailure, fetchMonetizationForumsubscriptionItemFailure, fetchMonetizationEventbookingItemFailure,
  createMonetizationSubscriptionplanItemStart, createMonetizationGroupmembershipItemStart, createMonetizationPurchasedcourseItemStart, createMonetizationNewslettersubscriptionItemStart, createMonetizationSeriessubscriptionItemStart, createMonetizationForumsubscriptionItemStart, createMonetizationEventbookingItemStart,
  createMonetizationSubscriptionplanItemSuccess, createMonetizationGroupmembershipItemSuccess, createMonetizationPurchasedcourseItemSuccess, createMonetizationNewslettersubscriptionItemSuccess, createMonetizationSeriessubscriptionItemSuccess, createMonetizationForumsubscriptionItemSuccess, createMonetizationEventbookingItemSuccess,
  createMonetizationSubscriptionplanItemFailure, createMonetizationGroupmembershipItemFailure, createMonetizationPurchasedcourseItemFailure, createMonetizationNewslettersubscriptionItemFailure, createMonetizationSeriessubscriptionItemFailure, createMonetizationForumsubscriptionItemFailure, createMonetizationEventbookingItemFailure,
  updateMonetizationSubscriptionplanItemStart, updateMonetizationGroupmembershipItemStart, updateMonetizationPurchasedcourseItemStart, updateMonetizationNewslettersubscriptionItemStart, updateMonetizationSeriessubscriptionItemStart, updateMonetizationForumsubscriptionItemStart, updateMonetizationEventbookingItemStart,
  updateMonetizationSubscriptionplanItemSuccess, updateMonetizationGroupmembershipItemSuccess, updateMonetizationPurchasedcourseItemSuccess, updateMonetizationNewslettersubscriptionItemSuccess, updateMonetizationSeriessubscriptionItemSuccess, updateMonetizationForumsubscriptionItemSuccess, updateMonetizationEventbookingItemSuccess,
  updateMonetizationSubscriptionplanItemFailure, updateMonetizationGroupmembershipItemFailure, updateMonetizationPurchasedcourseItemFailure, updateMonetizationNewslettersubscriptionItemFailure, updateMonetizationSeriessubscriptionItemFailure, updateMonetizationForumsubscriptionItemFailure, updateMonetizationEventbookingItemFailure,
  deleteMonetizationSubscriptionplanItemStart, deleteMonetizationGroupmembershipItemStart, deleteMonetizationPurchasedcourseItemStart, deleteMonetizationNewslettersubscriptionItemStart, deleteMonetizationSeriessubscriptionItemStart, deleteMonetizationForumsubscriptionItemStart, deleteMonetizationEventbookingItemStart,
  deleteMonetizationSubscriptionplanItemSuccess, deleteMonetizationGroupmembershipItemSuccess, deleteMonetizationPurchasedcourseItemSuccess, deleteMonetizationNewslettersubscriptionItemSuccess, deleteMonetizationSeriessubscriptionItemSuccess, deleteMonetizationForumsubscriptionItemSuccess, deleteMonetizationEventbookingItemSuccess,
  deleteMonetizationSubscriptionplanItemFailure, deleteMonetizationGroupmembershipItemFailure, deleteMonetizationPurchasedcourseItemFailure, deleteMonetizationNewslettersubscriptionItemFailure, deleteMonetizationSeriessubscriptionItemFailure, deleteMonetizationForumsubscriptionItemFailure, deleteMonetizationEventbookingItemFailure
} = slice.actions;

export default slice.reducer;
