import axios from '../utils/axios';

import {fetchGameResourceListStart, fetchGameResourceListSuccess, fetchGameResourceListFailure} from '../features/game'
import {fetchGameResourceItemStart, fetchGameResourceItemSuccess, fetchGameResourceItemFailure} from '../features/game'
import {createGameResourceItemStart, createGameResourceItemSuccess, createGameResourceItemFailure} from '../features/game'
import {updateGameResourceItemStart, updateGameResourceItemSuccess, updateGameResourceItemFailure} from '../features/game'
import {deleteGameResourceItemStart, deleteGameResourceItemSuccess, deleteGameResourceItemFailure} from '../features/game'


// RESOURCE ACTIONS
export const fetchGameResourceList = async (dispatch, info, key) => {
    try{
        dispatch(fetchGameResourceListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/game/resource/`, { params: info.params, headers: info.headers });
        dispatch(fetchGameResourceListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchGameResourceListFailure({key, error: error.message}));
    }
}
export const fetchGameResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchGameResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/game/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchGameResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchGameResourceItemFailure({key, error: error.message}));
    }
}
export const createGameResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(createGameResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/game/resource/`, info.payload, { headers: info.headers });
        dispatch(createGameResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createGameResourceItemFailure({key, error: error.message}));
    }
}
export const updateGameResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(updateGameResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/game/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateGameResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateGameResourceItemFailure({key, error: error.message}));
    }
}
export const deleteGameResourceItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteGameResourceItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/game/resource/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteGameResourceItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteGameResourceItemFailure({key, error: error.message}));
    }
}




