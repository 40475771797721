import axios from '../utils/axios';

import {fetchNewsletterVisitorListStart, fetchNewsletterVisitorListSuccess, fetchNewsletterVisitorListFailure} from '../features/newsletter'
import {fetchNewsletterVisitorItemStart, fetchNewsletterVisitorItemSuccess, fetchNewsletterVisitorItemFailure} from '../features/newsletter'
import {createNewsletterVisitorItemStart, createNewsletterVisitorItemSuccess, createNewsletterVisitorItemFailure} from '../features/newsletter'
import {updateNewsletterVisitorItemStart, updateNewsletterVisitorItemSuccess, updateNewsletterVisitorItemFailure} from '../features/newsletter'
import {deleteNewsletterVisitorItemStart, deleteNewsletterVisitorItemSuccess, deleteNewsletterVisitorItemFailure} from '../features/newsletter'

import {fetchNewsletterMailListStart, fetchNewsletterMailListSuccess, fetchNewsletterMailListFailure} from '../features/newsletter'
import {fetchNewsletterMailItemStart, fetchNewsletterMailItemSuccess, fetchNewsletterMailItemFailure} from '../features/newsletter'
import {createNewsletterMailItemStart, createNewsletterMailItemSuccess, createNewsletterMailItemFailure} from '../features/newsletter'
import {updateNewsletterMailItemStart, updateNewsletterMailItemSuccess, updateNewsletterMailItemFailure} from '../features/newsletter'
import {deleteNewsletterMailItemStart, deleteNewsletterMailItemSuccess, deleteNewsletterMailItemFailure} from '../features/newsletter'


// VISITOR ACTIONS
export const fetchNewsletterVisitorList = async (dispatch, info, key) => {
    try{
        dispatch(fetchNewsletterVisitorListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/newsletter/visitor/`, { params: info.params, headers: info.headers });
        dispatch(fetchNewsletterVisitorListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNewsletterVisitorListFailure({key, error: error.message}));
    }
}
export const fetchNewsletterVisitorItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchNewsletterVisitorItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/newsletter/visitor/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchNewsletterVisitorItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNewsletterVisitorItemFailure({key, error: error.message}));
    }
}
export const createNewsletterVisitorItem = async (dispatch, info, key) => {
    try{
        dispatch(createNewsletterVisitorItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/newsletter/visitor/`, info.payload, { headers: info.headers });
        dispatch(createNewsletterVisitorItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createNewsletterVisitorItemFailure({key, error: error.message}));
    }
}
export const updateNewsletterVisitorItem = async (dispatch, info, key) => {
    try{
        dispatch(updateNewsletterVisitorItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/newsletter/visitor/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateNewsletterVisitorItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateNewsletterVisitorItemFailure({key, error: error.message}));
    }
}
export const deleteNewsletterVisitorItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteNewsletterVisitorItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/newsletter/visitor/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteNewsletterVisitorItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteNewsletterVisitorItemFailure({key, error: error.message}));
    }
}


// MAIL ACTIONS
export const fetchNewsletterMailList = async (dispatch, info, key) => {
    try{
        dispatch(fetchNewsletterMailListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/newsletter/mail/`, { params: info.params, headers: info.headers });
        dispatch(fetchNewsletterMailListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNewsletterMailListFailure({key, error: error.message}));
    }
}
export const fetchNewsletterMailItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchNewsletterMailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/newsletter/mail/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchNewsletterMailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNewsletterMailItemFailure({key, error: error.message}));
    }
}
export const createNewsletterMailItem = async (dispatch, info, key) => {
    try{
        dispatch(createNewsletterMailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/newsletter/mail/`, info.payload, { headers: info.headers });
        dispatch(createNewsletterMailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createNewsletterMailItemFailure({key, error: error.message}));
    }
}
export const updateNewsletterMailItem = async (dispatch, info, key) => {
    try{
        dispatch(updateNewsletterMailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/newsletter/mail/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateNewsletterMailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateNewsletterMailItemFailure({key, error: error.message}));
    }
}
export const deleteNewsletterMailItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteNewsletterMailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/newsletter/mail/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteNewsletterMailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteNewsletterMailItemFailure({key, error: error.message}));
    }
}




