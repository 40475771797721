import axios from '../utils/axios';

import {fetchAccessAccesscoursematerialListStart, fetchAccessAccesscoursematerialListSuccess, fetchAccessAccesscoursematerialListFailure} from '../features/access'
import {fetchAccessAccesscoursematerialItemStart, fetchAccessAccesscoursematerialItemSuccess, fetchAccessAccesscoursematerialItemFailure} from '../features/access'
import {createAccessAccesscoursematerialItemStart, createAccessAccesscoursematerialItemSuccess, createAccessAccesscoursematerialItemFailure} from '../features/access'
import {updateAccessAccesscoursematerialItemStart, updateAccessAccesscoursematerialItemSuccess, updateAccessAccesscoursematerialItemFailure} from '../features/access'
import {deleteAccessAccesscoursematerialItemStart, deleteAccessAccesscoursematerialItemSuccess, deleteAccessAccesscoursematerialItemFailure} from '../features/access'

import {fetchAccessAccessnewsletterListStart, fetchAccessAccessnewsletterListSuccess, fetchAccessAccessnewsletterListFailure} from '../features/access'
import {fetchAccessAccessnewsletterItemStart, fetchAccessAccessnewsletterItemSuccess, fetchAccessAccessnewsletterItemFailure} from '../features/access'
import {createAccessAccessnewsletterItemStart, createAccessAccessnewsletterItemSuccess, createAccessAccessnewsletterItemFailure} from '../features/access'
import {updateAccessAccessnewsletterItemStart, updateAccessAccessnewsletterItemSuccess, updateAccessAccessnewsletterItemFailure} from '../features/access'
import {deleteAccessAccessnewsletterItemStart, deleteAccessAccessnewsletterItemSuccess, deleteAccessAccessnewsletterItemFailure} from '../features/access'

import {fetchAccessAccesspostorblogListStart, fetchAccessAccesspostorblogListSuccess, fetchAccessAccesspostorblogListFailure} from '../features/access'
import {fetchAccessAccesspostorblogItemStart, fetchAccessAccesspostorblogItemSuccess, fetchAccessAccesspostorblogItemFailure} from '../features/access'
import {createAccessAccesspostorblogItemStart, createAccessAccesspostorblogItemSuccess, createAccessAccesspostorblogItemFailure} from '../features/access'
import {updateAccessAccesspostorblogItemStart, updateAccessAccesspostorblogItemSuccess, updateAccessAccesspostorblogItemFailure} from '../features/access'
import {deleteAccessAccesspostorblogItemStart, deleteAccessAccesspostorblogItemSuccess, deleteAccessAccesspostorblogItemFailure} from '../features/access'

import {fetchAccessAccessquizListStart, fetchAccessAccessquizListSuccess, fetchAccessAccessquizListFailure} from '../features/access'
import {fetchAccessAccessquizItemStart, fetchAccessAccessquizItemSuccess, fetchAccessAccessquizItemFailure} from '../features/access'
import {createAccessAccessquizItemStart, createAccessAccessquizItemSuccess, createAccessAccessquizItemFailure} from '../features/access'
import {updateAccessAccessquizItemStart, updateAccessAccessquizItemSuccess, updateAccessAccessquizItemFailure} from '../features/access'
import {deleteAccessAccessquizItemStart, deleteAccessAccessquizItemSuccess, deleteAccessAccessquizItemFailure} from '../features/access'

import {fetchAccessAccessseriesListStart, fetchAccessAccessseriesListSuccess, fetchAccessAccessseriesListFailure} from '../features/access'
import {fetchAccessAccessseriesItemStart, fetchAccessAccessseriesItemSuccess, fetchAccessAccessseriesItemFailure} from '../features/access'
import {createAccessAccessseriesItemStart, createAccessAccessseriesItemSuccess, createAccessAccessseriesItemFailure} from '../features/access'
import {updateAccessAccessseriesItemStart, updateAccessAccessseriesItemSuccess, updateAccessAccessseriesItemFailure} from '../features/access'
import {deleteAccessAccessseriesItemStart, deleteAccessAccessseriesItemSuccess, deleteAccessAccessseriesItemFailure} from '../features/access'


// ACCESSCOURSEMATERIAL ACTIONS
export const fetchAccessAccesscoursematerialList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessAccesscoursematerialListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/accesscoursematerial/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessAccesscoursematerialListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessAccesscoursematerialListFailure({key, error: error.message}));
    }
}
export const fetchAccessAccesscoursematerialItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessAccesscoursematerialItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/accesscoursematerial/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessAccesscoursematerialItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessAccesscoursematerialItemFailure({key, error: error.message}));
    }
}
export const createAccessAccesscoursematerialItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessAccesscoursematerialItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/accesscoursematerial/`, info.payload, { headers: info.headers });
        dispatch(createAccessAccesscoursematerialItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessAccesscoursematerialItemFailure({key, error: error.message}));
    }
}
export const updateAccessAccesscoursematerialItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessAccesscoursematerialItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/accesscoursematerial/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessAccesscoursematerialItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessAccesscoursematerialItemFailure({key, error: error.message}));
    }
}
export const deleteAccessAccesscoursematerialItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessAccesscoursematerialItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/accesscoursematerial/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessAccesscoursematerialItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessAccesscoursematerialItemFailure({key, error: error.message}));
    }
}


// ACCESSNEWSLETTER ACTIONS
export const fetchAccessAccessnewsletterList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessAccessnewsletterListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/accessnewsletter/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessAccessnewsletterListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessAccessnewsletterListFailure({key, error: error.message}));
    }
}
export const fetchAccessAccessnewsletterItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessAccessnewsletterItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/accessnewsletter/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessAccessnewsletterItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessAccessnewsletterItemFailure({key, error: error.message}));
    }
}
export const createAccessAccessnewsletterItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessAccessnewsletterItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/accessnewsletter/`, info.payload, { headers: info.headers });
        dispatch(createAccessAccessnewsletterItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessAccessnewsletterItemFailure({key, error: error.message}));
    }
}
export const updateAccessAccessnewsletterItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessAccessnewsletterItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/accessnewsletter/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessAccessnewsletterItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessAccessnewsletterItemFailure({key, error: error.message}));
    }
}
export const deleteAccessAccessnewsletterItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessAccessnewsletterItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/accessnewsletter/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessAccessnewsletterItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessAccessnewsletterItemFailure({key, error: error.message}));
    }
}


// ACCESSPOSTORBLOG ACTIONS
export const fetchAccessAccesspostorblogList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessAccesspostorblogListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/accesspostorblog/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessAccesspostorblogListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessAccesspostorblogListFailure({key, error: error.message}));
    }
}
export const fetchAccessAccesspostorblogItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessAccesspostorblogItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/accesspostorblog/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessAccesspostorblogItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessAccesspostorblogItemFailure({key, error: error.message}));
    }
}
export const createAccessAccesspostorblogItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessAccesspostorblogItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/accesspostorblog/`, info.payload, { headers: info.headers });
        dispatch(createAccessAccesspostorblogItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessAccesspostorblogItemFailure({key, error: error.message}));
    }
}
export const updateAccessAccesspostorblogItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessAccesspostorblogItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/accesspostorblog/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessAccesspostorblogItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessAccesspostorblogItemFailure({key, error: error.message}));
    }
}
export const deleteAccessAccesspostorblogItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessAccesspostorblogItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/accesspostorblog/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessAccesspostorblogItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessAccesspostorblogItemFailure({key, error: error.message}));
    }
}


// ACCESSQUIZ ACTIONS
export const fetchAccessAccessquizList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessAccessquizListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/accessquiz/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessAccessquizListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessAccessquizListFailure({key, error: error.message}));
    }
}
export const fetchAccessAccessquizItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessAccessquizItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/accessquiz/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessAccessquizItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessAccessquizItemFailure({key, error: error.message}));
    }
}
export const createAccessAccessquizItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessAccessquizItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/accessquiz/`, info.payload, { headers: info.headers });
        dispatch(createAccessAccessquizItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessAccessquizItemFailure({key, error: error.message}));
    }
}
export const updateAccessAccessquizItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessAccessquizItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/accessquiz/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessAccessquizItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessAccessquizItemFailure({key, error: error.message}));
    }
}
export const deleteAccessAccessquizItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessAccessquizItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/accessquiz/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessAccessquizItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessAccessquizItemFailure({key, error: error.message}));
    }
}


// ACCESSSERIES ACTIONS
export const fetchAccessAccessseriesList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessAccessseriesListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/accessseries/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccessAccessseriesListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessAccessseriesListFailure({key, error: error.message}));
    }
}
export const fetchAccessAccessseriesItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccessAccessseriesItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/access/accessseries/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccessAccessseriesItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccessAccessseriesItemFailure({key, error: error.message}));
    }
}
export const createAccessAccessseriesItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccessAccessseriesItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/access/accessseries/`, info.payload, { headers: info.headers });
        dispatch(createAccessAccessseriesItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccessAccessseriesItemFailure({key, error: error.message}));
    }
}
export const updateAccessAccessseriesItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccessAccessseriesItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/access/accessseries/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccessAccessseriesItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccessAccessseriesItemFailure({key, error: error.message}));
    }
}
export const deleteAccessAccessseriesItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccessAccessseriesItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/access/accessseries/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccessAccessseriesItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccessAccessseriesItemFailure({key, error: error.message}));
    }
}




