import axios from '../utils/axios';

import {fetchBlogAvailabletagListStart, fetchBlogAvailabletagListSuccess, fetchBlogAvailabletagListFailure} from '../features/blog'
import {fetchBlogAvailabletagItemStart, fetchBlogAvailabletagItemSuccess, fetchBlogAvailabletagItemFailure} from '../features/blog'
import {createBlogAvailabletagItemStart, createBlogAvailabletagItemSuccess, createBlogAvailabletagItemFailure} from '../features/blog'
import {updateBlogAvailabletagItemStart, updateBlogAvailabletagItemSuccess, updateBlogAvailabletagItemFailure} from '../features/blog'
import {deleteBlogAvailabletagItemStart, deleteBlogAvailabletagItemSuccess, deleteBlogAvailabletagItemFailure} from '../features/blog'

import {fetchBlogPostListStart, fetchBlogPostListSuccess, fetchBlogPostListFailure} from '../features/blog'
import {fetchBlogPostItemStart, fetchBlogPostItemSuccess, fetchBlogPostItemFailure} from '../features/blog'
import {createBlogPostItemStart, createBlogPostItemSuccess, createBlogPostItemFailure} from '../features/blog'
import {updateBlogPostItemStart, updateBlogPostItemSuccess, updateBlogPostItemFailure} from '../features/blog'
import {deleteBlogPostItemStart, deleteBlogPostItemSuccess, deleteBlogPostItemFailure} from '../features/blog'


// AVAILABLETAG ACTIONS
export const fetchBlogAvailabletagList = async (dispatch, info, key) => {
    try{
        dispatch(fetchBlogAvailabletagListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/blog/availabletag/`, { params: info.params, headers: info.headers });
        dispatch(fetchBlogAvailabletagListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchBlogAvailabletagListFailure({key, error: error.message}));
    }
}
export const fetchBlogAvailabletagItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchBlogAvailabletagItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/blog/availabletag/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchBlogAvailabletagItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchBlogAvailabletagItemFailure({key, error: error.message}));
    }
}
export const createBlogAvailabletagItem = async (dispatch, info, key) => {
    try{
        dispatch(createBlogAvailabletagItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/blog/availabletag/`, info.payload, { headers: info.headers });
        dispatch(createBlogAvailabletagItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createBlogAvailabletagItemFailure({key, error: error.message}));
    }
}
export const updateBlogAvailabletagItem = async (dispatch, info, key) => {
    try{
        dispatch(updateBlogAvailabletagItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/blog/availabletag/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateBlogAvailabletagItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateBlogAvailabletagItemFailure({key, error: error.message}));
    }
}
export const deleteBlogAvailabletagItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteBlogAvailabletagItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/blog/availabletag/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteBlogAvailabletagItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteBlogAvailabletagItemFailure({key, error: error.message}));
    }
}


// POST ACTIONS
export const fetchBlogPostList = async (dispatch, info, key) => {
    try{
        dispatch(fetchBlogPostListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/blog/post/`, { params: info.params, headers: info.headers });
        dispatch(fetchBlogPostListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchBlogPostListFailure({key, error: error.message}));
    }
}
export const fetchBlogPostItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchBlogPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/blog/post/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchBlogPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchBlogPostItemFailure({key, error: error.message}));
    }
}
export const createBlogPostItem = async (dispatch, info, key) => {
    try{
        dispatch(createBlogPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/blog/post/`, info.payload, { headers: info.headers });
        dispatch(createBlogPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createBlogPostItemFailure({key, error: error.message}));
    }
}
export const updateBlogPostItem = async (dispatch, info, key) => {
    try{
        dispatch(updateBlogPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/blog/post/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateBlogPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateBlogPostItemFailure({key, error: error.message}));
    }
}
export const deleteBlogPostItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteBlogPostItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/blog/post/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteBlogPostItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteBlogPostItemFailure({key, error: error.message}));
    }
}




