import axios from '../utils/axios';

import {fetchWebsiteinfoSiteListStart, fetchWebsiteinfoSiteListSuccess, fetchWebsiteinfoSiteListFailure} from '../features/websiteinfo'
import {fetchWebsiteinfoSiteItemStart, fetchWebsiteinfoSiteItemSuccess, fetchWebsiteinfoSiteItemFailure} from '../features/websiteinfo'
import {createWebsiteinfoSiteItemStart, createWebsiteinfoSiteItemSuccess, createWebsiteinfoSiteItemFailure} from '../features/websiteinfo'
import {updateWebsiteinfoSiteItemStart, updateWebsiteinfoSiteItemSuccess, updateWebsiteinfoSiteItemFailure} from '../features/websiteinfo'
import {deleteWebsiteinfoSiteItemStart, deleteWebsiteinfoSiteItemSuccess, deleteWebsiteinfoSiteItemFailure} from '../features/websiteinfo'

import {fetchWebsiteinfoSitecontentListStart, fetchWebsiteinfoSitecontentListSuccess, fetchWebsiteinfoSitecontentListFailure} from '../features/websiteinfo'
import {fetchWebsiteinfoSitecontentItemStart, fetchWebsiteinfoSitecontentItemSuccess, fetchWebsiteinfoSitecontentItemFailure} from '../features/websiteinfo'
import {createWebsiteinfoSitecontentItemStart, createWebsiteinfoSitecontentItemSuccess, createWebsiteinfoSitecontentItemFailure} from '../features/websiteinfo'
import {updateWebsiteinfoSitecontentItemStart, updateWebsiteinfoSitecontentItemSuccess, updateWebsiteinfoSitecontentItemFailure} from '../features/websiteinfo'
import {deleteWebsiteinfoSitecontentItemStart, deleteWebsiteinfoSitecontentItemSuccess, deleteWebsiteinfoSitecontentItemFailure} from '../features/websiteinfo'


// SITE ACTIONS
export const fetchWebsiteinfoSiteList = async (dispatch, info, key) => {
    try{
        dispatch(fetchWebsiteinfoSiteListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/websiteinfo/site/`, { params: info.params, headers: info.headers });
        dispatch(fetchWebsiteinfoSiteListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchWebsiteinfoSiteListFailure({key, error: error.message}));
    }
}
export const fetchWebsiteinfoSiteItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchWebsiteinfoSiteItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/websiteinfo/site/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchWebsiteinfoSiteItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchWebsiteinfoSiteItemFailure({key, error: error.message}));
    }
}
export const createWebsiteinfoSiteItem = async (dispatch, info, key) => {
    try{
        dispatch(createWebsiteinfoSiteItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/websiteinfo/site/`, info.payload, { headers: info.headers });
        dispatch(createWebsiteinfoSiteItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createWebsiteinfoSiteItemFailure({key, error: error.message}));
    }
}
export const updateWebsiteinfoSiteItem = async (dispatch, info, key) => {
    try{
        dispatch(updateWebsiteinfoSiteItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/websiteinfo/site/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateWebsiteinfoSiteItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateWebsiteinfoSiteItemFailure({key, error: error.message}));
    }
}
export const deleteWebsiteinfoSiteItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteWebsiteinfoSiteItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/websiteinfo/site/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteWebsiteinfoSiteItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteWebsiteinfoSiteItemFailure({key, error: error.message}));
    }
}


// SITECONTENT ACTIONS
export const fetchWebsiteinfoSitecontentList = async (dispatch, info, key) => {
    try{
        dispatch(fetchWebsiteinfoSitecontentListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/websiteinfo/sitecontent/`, { params: info.params, headers: info.headers });
        dispatch(fetchWebsiteinfoSitecontentListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchWebsiteinfoSitecontentListFailure({key, error: error.message}));
    }
}
export const fetchWebsiteinfoSitecontentItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchWebsiteinfoSitecontentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/websiteinfo/sitecontent/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchWebsiteinfoSitecontentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchWebsiteinfoSitecontentItemFailure({key, error: error.message}));
    }
}
export const createWebsiteinfoSitecontentItem = async (dispatch, info, key) => {
    try{
        dispatch(createWebsiteinfoSitecontentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/websiteinfo/sitecontent/`, info.payload, { headers: info.headers });
        dispatch(createWebsiteinfoSitecontentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createWebsiteinfoSitecontentItemFailure({key, error: error.message}));
    }
}
export const updateWebsiteinfoSitecontentItem = async (dispatch, info, key) => {
    try{
        dispatch(updateWebsiteinfoSitecontentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/websiteinfo/sitecontent/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateWebsiteinfoSitecontentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateWebsiteinfoSitecontentItemFailure({key, error: error.message}));
    }
}
export const deleteWebsiteinfoSitecontentItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteWebsiteinfoSitecontentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/websiteinfo/sitecontent/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteWebsiteinfoSitecontentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteWebsiteinfoSitecontentItemFailure({key, error: error.message}));
    }
}




