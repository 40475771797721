import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'notification',
  initialState: { loading: false },
  reducers: {
    
    // NOTIFICATION REDUCER FUNCTION

    // NOTIFICATION FETCH LIST
    fetchNotificationNotificationListStart: stateLoadingStart,
    fetchNotificationNotificationListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notification_list`),
    fetchNotificationNotificationListFailure: stateError,

    // NOTIFICATION FETCH ITEM
    fetchNotificationNotificationItemStart: stateLoadingStart,
    fetchNotificationNotificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notification_item`),
    fetchNotificationNotificationItemFailure: stateError,

    // NOTIFICATION CREATE ITEM
    createNotificationNotificationItemStart: stateLoadingStart,
    createNotificationNotificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notification_item`),
    createNotificationNotificationItemFailure: stateError,

    // NOTIFICATION UPDATE ITEM
    updateNotificationNotificationItemStart: stateLoadingStart,
    updateNotificationNotificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notification_item`),
    updateNotificationNotificationItemFailure: stateError,

    // NOTIFICATION DELETE ITEM
    deleteNotificationNotificationItemStart: stateLoadingStart,
    deleteNotificationNotificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_notification`),
    deleteNotificationNotificationItemFailure: stateError,


  },
});

export const {
  fetchNotificationNotificationListStart,
  fetchNotificationNotificationListSuccess,
  fetchNotificationNotificationListFailure,
  fetchNotificationNotificationItemStart,
  fetchNotificationNotificationItemSuccess,
  fetchNotificationNotificationItemFailure,
  createNotificationNotificationItemStart,
  createNotificationNotificationItemSuccess,
  createNotificationNotificationItemFailure,
  updateNotificationNotificationItemStart,
  updateNotificationNotificationItemSuccess,
  updateNotificationNotificationItemFailure,
  deleteNotificationNotificationItemStart,
  deleteNotificationNotificationItemSuccess,
  deleteNotificationNotificationItemFailure
} = slice.actions;

export default slice.reducer;
