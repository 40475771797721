import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'showcase',
  initialState: { loading: false },
  reducers: {
    
    // RESOURCE REDUCER FUNCTION

    // RESOURCE FETCH LIST
    fetchShowcaseResourceListStart: stateLoadingStart,
    fetchShowcaseResourceListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_list`),
    fetchShowcaseResourceListFailure: stateError,

    // RESOURCE FETCH ITEM
    fetchShowcaseResourceItemStart: stateLoadingStart,
    fetchShowcaseResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    fetchShowcaseResourceItemFailure: stateError,

    // RESOURCE CREATE ITEM
    createShowcaseResourceItemStart: stateLoadingStart,
    createShowcaseResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    createShowcaseResourceItemFailure: stateError,

    // RESOURCE UPDATE ITEM
    updateShowcaseResourceItemStart: stateLoadingStart,
    updateShowcaseResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `resource_item`),
    updateShowcaseResourceItemFailure: stateError,

    // RESOURCE DELETE ITEM
    deleteShowcaseResourceItemStart: stateLoadingStart,
    deleteShowcaseResourceItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_resource`),
    deleteShowcaseResourceItemFailure: stateError,


  },
});

export const {
  fetchShowcaseResourceListStart,
  fetchShowcaseResourceListSuccess,
  fetchShowcaseResourceListFailure,
  fetchShowcaseResourceItemStart,
  fetchShowcaseResourceItemSuccess,
  fetchShowcaseResourceItemFailure,
  createShowcaseResourceItemStart,
  createShowcaseResourceItemSuccess,
  createShowcaseResourceItemFailure,
  updateShowcaseResourceItemStart,
  updateShowcaseResourceItemSuccess,
  updateShowcaseResourceItemFailure,
  deleteShowcaseResourceItemStart,
  deleteShowcaseResourceItemSuccess,
  deleteShowcaseResourceItemFailure
} = slice.actions;

export default slice.reducer;
