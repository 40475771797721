import axios from '../utils/axios';

import {fetchMonetizationSubscriptionplanListStart, fetchMonetizationSubscriptionplanListSuccess, fetchMonetizationSubscriptionplanListFailure} from '../features/monetization'
import {fetchMonetizationSubscriptionplanItemStart, fetchMonetizationSubscriptionplanItemSuccess, fetchMonetizationSubscriptionplanItemFailure} from '../features/monetization'
import {createMonetizationSubscriptionplanItemStart, createMonetizationSubscriptionplanItemSuccess, createMonetizationSubscriptionplanItemFailure} from '../features/monetization'
import {updateMonetizationSubscriptionplanItemStart, updateMonetizationSubscriptionplanItemSuccess, updateMonetizationSubscriptionplanItemFailure} from '../features/monetization'
import {deleteMonetizationSubscriptionplanItemStart, deleteMonetizationSubscriptionplanItemSuccess, deleteMonetizationSubscriptionplanItemFailure} from '../features/monetization'

import {fetchMonetizationGroupmembershipListStart, fetchMonetizationGroupmembershipListSuccess, fetchMonetizationGroupmembershipListFailure} from '../features/monetization'
import {fetchMonetizationGroupmembershipItemStart, fetchMonetizationGroupmembershipItemSuccess, fetchMonetizationGroupmembershipItemFailure} from '../features/monetization'
import {createMonetizationGroupmembershipItemStart, createMonetizationGroupmembershipItemSuccess, createMonetizationGroupmembershipItemFailure} from '../features/monetization'
import {updateMonetizationGroupmembershipItemStart, updateMonetizationGroupmembershipItemSuccess, updateMonetizationGroupmembershipItemFailure} from '../features/monetization'
import {deleteMonetizationGroupmembershipItemStart, deleteMonetizationGroupmembershipItemSuccess, deleteMonetizationGroupmembershipItemFailure} from '../features/monetization'

import {fetchMonetizationPurchasedcourseListStart, fetchMonetizationPurchasedcourseListSuccess, fetchMonetizationPurchasedcourseListFailure} from '../features/monetization'
import {fetchMonetizationPurchasedcourseItemStart, fetchMonetizationPurchasedcourseItemSuccess, fetchMonetizationPurchasedcourseItemFailure} from '../features/monetization'
import {createMonetizationPurchasedcourseItemStart, createMonetizationPurchasedcourseItemSuccess, createMonetizationPurchasedcourseItemFailure} from '../features/monetization'
import {updateMonetizationPurchasedcourseItemStart, updateMonetizationPurchasedcourseItemSuccess, updateMonetizationPurchasedcourseItemFailure} from '../features/monetization'
import {deleteMonetizationPurchasedcourseItemStart, deleteMonetizationPurchasedcourseItemSuccess, deleteMonetizationPurchasedcourseItemFailure} from '../features/monetization'

import {fetchMonetizationNewslettersubscriptionListStart, fetchMonetizationNewslettersubscriptionListSuccess, fetchMonetizationNewslettersubscriptionListFailure} from '../features/monetization'
import {fetchMonetizationNewslettersubscriptionItemStart, fetchMonetizationNewslettersubscriptionItemSuccess, fetchMonetizationNewslettersubscriptionItemFailure} from '../features/monetization'
import {createMonetizationNewslettersubscriptionItemStart, createMonetizationNewslettersubscriptionItemSuccess, createMonetizationNewslettersubscriptionItemFailure} from '../features/monetization'
import {updateMonetizationNewslettersubscriptionItemStart, updateMonetizationNewslettersubscriptionItemSuccess, updateMonetizationNewslettersubscriptionItemFailure} from '../features/monetization'
import {deleteMonetizationNewslettersubscriptionItemStart, deleteMonetizationNewslettersubscriptionItemSuccess, deleteMonetizationNewslettersubscriptionItemFailure} from '../features/monetization'

import {fetchMonetizationSeriessubscriptionListStart, fetchMonetizationSeriessubscriptionListSuccess, fetchMonetizationSeriessubscriptionListFailure} from '../features/monetization'
import {fetchMonetizationSeriessubscriptionItemStart, fetchMonetizationSeriessubscriptionItemSuccess, fetchMonetizationSeriessubscriptionItemFailure} from '../features/monetization'
import {createMonetizationSeriessubscriptionItemStart, createMonetizationSeriessubscriptionItemSuccess, createMonetizationSeriessubscriptionItemFailure} from '../features/monetization'
import {updateMonetizationSeriessubscriptionItemStart, updateMonetizationSeriessubscriptionItemSuccess, updateMonetizationSeriessubscriptionItemFailure} from '../features/monetization'
import {deleteMonetizationSeriessubscriptionItemStart, deleteMonetizationSeriessubscriptionItemSuccess, deleteMonetizationSeriessubscriptionItemFailure} from '../features/monetization'

import {fetchMonetizationForumsubscriptionListStart, fetchMonetizationForumsubscriptionListSuccess, fetchMonetizationForumsubscriptionListFailure} from '../features/monetization'
import {fetchMonetizationForumsubscriptionItemStart, fetchMonetizationForumsubscriptionItemSuccess, fetchMonetizationForumsubscriptionItemFailure} from '../features/monetization'
import {createMonetizationForumsubscriptionItemStart, createMonetizationForumsubscriptionItemSuccess, createMonetizationForumsubscriptionItemFailure} from '../features/monetization'
import {updateMonetizationForumsubscriptionItemStart, updateMonetizationForumsubscriptionItemSuccess, updateMonetizationForumsubscriptionItemFailure} from '../features/monetization'
import {deleteMonetizationForumsubscriptionItemStart, deleteMonetizationForumsubscriptionItemSuccess, deleteMonetizationForumsubscriptionItemFailure} from '../features/monetization'

import {fetchMonetizationEventbookingListStart, fetchMonetizationEventbookingListSuccess, fetchMonetizationEventbookingListFailure} from '../features/monetization'
import {fetchMonetizationEventbookingItemStart, fetchMonetizationEventbookingItemSuccess, fetchMonetizationEventbookingItemFailure} from '../features/monetization'
import {createMonetizationEventbookingItemStart, createMonetizationEventbookingItemSuccess, createMonetizationEventbookingItemFailure} from '../features/monetization'
import {updateMonetizationEventbookingItemStart, updateMonetizationEventbookingItemSuccess, updateMonetizationEventbookingItemFailure} from '../features/monetization'
import {deleteMonetizationEventbookingItemStart, deleteMonetizationEventbookingItemSuccess, deleteMonetizationEventbookingItemFailure} from '../features/monetization'


// SUBSCRIPTIONPLAN ACTIONS
export const fetchMonetizationSubscriptionplanList = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationSubscriptionplanListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/subscriptionplan/`, { params: info.params, headers: info.headers });
        dispatch(fetchMonetizationSubscriptionplanListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationSubscriptionplanListFailure({key, error: error.message}));
    }
}
export const fetchMonetizationSubscriptionplanItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationSubscriptionplanItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/subscriptionplan/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchMonetizationSubscriptionplanItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationSubscriptionplanItemFailure({key, error: error.message}));
    }
}
export const createMonetizationSubscriptionplanItem = async (dispatch, info, key) => {
    try{
        dispatch(createMonetizationSubscriptionplanItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/monetization/subscriptionplan/`, info.payload, { headers: info.headers });
        dispatch(createMonetizationSubscriptionplanItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createMonetizationSubscriptionplanItemFailure({key, error: error.message}));
    }
}
export const updateMonetizationSubscriptionplanItem = async (dispatch, info, key) => {
    try{
        dispatch(updateMonetizationSubscriptionplanItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/monetization/subscriptionplan/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateMonetizationSubscriptionplanItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateMonetizationSubscriptionplanItemFailure({key, error: error.message}));
    }
}
export const deleteMonetizationSubscriptionplanItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteMonetizationSubscriptionplanItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/monetization/subscriptionplan/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteMonetizationSubscriptionplanItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteMonetizationSubscriptionplanItemFailure({key, error: error.message}));
    }
}


// GROUPMEMBERSHIP ACTIONS
export const fetchMonetizationGroupmembershipList = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationGroupmembershipListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/groupmembership/`, { params: info.params, headers: info.headers });
        dispatch(fetchMonetizationGroupmembershipListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationGroupmembershipListFailure({key, error: error.message}));
    }
}
export const fetchMonetizationGroupmembershipItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationGroupmembershipItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/groupmembership/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchMonetizationGroupmembershipItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationGroupmembershipItemFailure({key, error: error.message}));
    }
}
export const createMonetizationGroupmembershipItem = async (dispatch, info, key) => {
    try{
        dispatch(createMonetizationGroupmembershipItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/monetization/groupmembership/`, info.payload, { headers: info.headers });
        dispatch(createMonetizationGroupmembershipItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createMonetizationGroupmembershipItemFailure({key, error: error.message}));
    }
}
export const updateMonetizationGroupmembershipItem = async (dispatch, info, key) => {
    try{
        dispatch(updateMonetizationGroupmembershipItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/monetization/groupmembership/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateMonetizationGroupmembershipItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateMonetizationGroupmembershipItemFailure({key, error: error.message}));
    }
}
export const deleteMonetizationGroupmembershipItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteMonetizationGroupmembershipItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/monetization/groupmembership/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteMonetizationGroupmembershipItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteMonetizationGroupmembershipItemFailure({key, error: error.message}));
    }
}


// PURCHASEDCOURSE ACTIONS
export const fetchMonetizationPurchasedcourseList = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationPurchasedcourseListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/purchasedcourse/`, { params: info.params, headers: info.headers });
        dispatch(fetchMonetizationPurchasedcourseListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationPurchasedcourseListFailure({key, error: error.message}));
    }
}
export const fetchMonetizationPurchasedcourseItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationPurchasedcourseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/purchasedcourse/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchMonetizationPurchasedcourseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationPurchasedcourseItemFailure({key, error: error.message}));
    }
}
export const createMonetizationPurchasedcourseItem = async (dispatch, info, key) => {
    try{
        dispatch(createMonetizationPurchasedcourseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/monetization/purchasedcourse/`, info.payload, { headers: info.headers });
        dispatch(createMonetizationPurchasedcourseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createMonetizationPurchasedcourseItemFailure({key, error: error.message}));
    }
}
export const updateMonetizationPurchasedcourseItem = async (dispatch, info, key) => {
    try{
        dispatch(updateMonetizationPurchasedcourseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/monetization/purchasedcourse/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateMonetizationPurchasedcourseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateMonetizationPurchasedcourseItemFailure({key, error: error.message}));
    }
}
export const deleteMonetizationPurchasedcourseItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteMonetizationPurchasedcourseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/monetization/purchasedcourse/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteMonetizationPurchasedcourseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteMonetizationPurchasedcourseItemFailure({key, error: error.message}));
    }
}


// NEWSLETTERSUBSCRIPTION ACTIONS
export const fetchMonetizationNewslettersubscriptionList = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationNewslettersubscriptionListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/newslettersubscription/`, { params: info.params, headers: info.headers });
        dispatch(fetchMonetizationNewslettersubscriptionListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationNewslettersubscriptionListFailure({key, error: error.message}));
    }
}
export const fetchMonetizationNewslettersubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationNewslettersubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/newslettersubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchMonetizationNewslettersubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationNewslettersubscriptionItemFailure({key, error: error.message}));
    }
}
export const createMonetizationNewslettersubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(createMonetizationNewslettersubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/monetization/newslettersubscription/`, info.payload, { headers: info.headers });
        dispatch(createMonetizationNewslettersubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createMonetizationNewslettersubscriptionItemFailure({key, error: error.message}));
    }
}
export const updateMonetizationNewslettersubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(updateMonetizationNewslettersubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/monetization/newslettersubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateMonetizationNewslettersubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateMonetizationNewslettersubscriptionItemFailure({key, error: error.message}));
    }
}
export const deleteMonetizationNewslettersubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteMonetizationNewslettersubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/monetization/newslettersubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteMonetizationNewslettersubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteMonetizationNewslettersubscriptionItemFailure({key, error: error.message}));
    }
}


// SERIESSUBSCRIPTION ACTIONS
export const fetchMonetizationSeriessubscriptionList = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationSeriessubscriptionListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/seriessubscription/`, { params: info.params, headers: info.headers });
        dispatch(fetchMonetizationSeriessubscriptionListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationSeriessubscriptionListFailure({key, error: error.message}));
    }
}
export const fetchMonetizationSeriessubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationSeriessubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/seriessubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchMonetizationSeriessubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationSeriessubscriptionItemFailure({key, error: error.message}));
    }
}
export const createMonetizationSeriessubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(createMonetizationSeriessubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/monetization/seriessubscription/`, info.payload, { headers: info.headers });
        dispatch(createMonetizationSeriessubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createMonetizationSeriessubscriptionItemFailure({key, error: error.message}));
    }
}
export const updateMonetizationSeriessubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(updateMonetizationSeriessubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/monetization/seriessubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateMonetizationSeriessubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateMonetizationSeriessubscriptionItemFailure({key, error: error.message}));
    }
}
export const deleteMonetizationSeriessubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteMonetizationSeriessubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/monetization/seriessubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteMonetizationSeriessubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteMonetizationSeriessubscriptionItemFailure({key, error: error.message}));
    }
}


// FORUMSUBSCRIPTION ACTIONS
export const fetchMonetizationForumsubscriptionList = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationForumsubscriptionListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/forumsubscription/`, { params: info.params, headers: info.headers });
        dispatch(fetchMonetizationForumsubscriptionListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationForumsubscriptionListFailure({key, error: error.message}));
    }
}
export const fetchMonetizationForumsubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationForumsubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/forumsubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchMonetizationForumsubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationForumsubscriptionItemFailure({key, error: error.message}));
    }
}
export const createMonetizationForumsubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(createMonetizationForumsubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/monetization/forumsubscription/`, info.payload, { headers: info.headers });
        dispatch(createMonetizationForumsubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createMonetizationForumsubscriptionItemFailure({key, error: error.message}));
    }
}
export const updateMonetizationForumsubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(updateMonetizationForumsubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/monetization/forumsubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateMonetizationForumsubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateMonetizationForumsubscriptionItemFailure({key, error: error.message}));
    }
}
export const deleteMonetizationForumsubscriptionItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteMonetizationForumsubscriptionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/monetization/forumsubscription/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteMonetizationForumsubscriptionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteMonetizationForumsubscriptionItemFailure({key, error: error.message}));
    }
}


// EVENTBOOKING ACTIONS
export const fetchMonetizationEventbookingList = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationEventbookingListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/eventbooking/`, { params: info.params, headers: info.headers });
        dispatch(fetchMonetizationEventbookingListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationEventbookingListFailure({key, error: error.message}));
    }
}
export const fetchMonetizationEventbookingItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchMonetizationEventbookingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/monetization/eventbooking/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchMonetizationEventbookingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchMonetizationEventbookingItemFailure({key, error: error.message}));
    }
}
export const createMonetizationEventbookingItem = async (dispatch, info, key) => {
    try{
        dispatch(createMonetizationEventbookingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/monetization/eventbooking/`, info.payload, { headers: info.headers });
        dispatch(createMonetizationEventbookingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createMonetizationEventbookingItemFailure({key, error: error.message}));
    }
}
export const updateMonetizationEventbookingItem = async (dispatch, info, key) => {
    try{
        dispatch(updateMonetizationEventbookingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/monetization/eventbooking/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateMonetizationEventbookingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateMonetizationEventbookingItemFailure({key, error: error.message}));
    }
}
export const deleteMonetizationEventbookingItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteMonetizationEventbookingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/monetization/eventbooking/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteMonetizationEventbookingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteMonetizationEventbookingItemFailure({key, error: error.message}));
    }
}




