import axios from 'axios';

const requestQueue = {};
const instance = (base_url) => {
  const api = axios.create({
    baseURL: base_url,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return api;
};

export default instance;
